import React, { useContext, useState } from 'react'
import { Box, Stack, useMediaQuery } from '@mui/material'
import { useProducts } from '../../../core/hooks/useProducts'
import DataTable from '../../../components/Tables/DataTable'
import { TProductStatus } from '../../../core/types/Product'
import ProductCard from './productCard'
import TablePaginationDefault from '../../../components/Tables/TablePaginationDefault'
import DataTableHeaderWeb from '../../../components/Tables/DataTableHeaderWeb'
import DataTableHeaderMobile from '../../../components/Tables/DataTableHeaderMobile'
import { ProductSituationEnum } from '../../../core/types/Situation'
import { AuthContext } from '../../../core/context/AuthContext'
import { TUserType } from '../../../core/types/Auth'
import AlertDefault from '../../../components/Alerts'
import myProductsColumns from './MyProductsColumns'
import UserSetting from '../../Admin/UserSettings/UserSetting'
import { TTenantSettings } from '../../../core/types/Tenant'
import SuccessSnackbar from '../../../components/Snackbar/SuccessSnackbar'
import ErrorSnackbar from '../../../components/Snackbar/ErrorSnackbar'
import LoadingBackdrop from '../../../components/Loading/LoadingBackdrop'
import { TenantController } from '../../../core/controllers/TenantController'

const tenantSettingsDefault = {
  id: '',
  tenantId: '',
  immediateWithdrawal: 0.0,
  plataformFee: 0.0,
}

export default function MyProducts({ tenantId }: { tenantId?: string }) {
  const { user } = useContext(AuthContext)
  const isAdmin = user?.UserType === TUserType.SystemAdmin

  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const [search, setSearch] = useState('')
  const [status, setStatus] = React.useState<'active' | 'inactive' | 'deleted'>(
    'active'
  )
  const [situationStatus, setSituationStatus] =
    React.useState<ProductSituationEnum | null>(
      isAdmin ? ProductSituationEnum.All : null
    )
  const [tenantSettings, setTenantSettings] = useState<TTenantSettings>({
    ...tenantSettingsDefault,
  })
  const [sending, setSending] = useState(false)
  const [success, setSuccess] = React.useState(false)
  const [errorSending, setErrorSending] = React.useState('')
  const [message, setMessage] = React.useState('')

  const isSmallScreen = tenantId
    ? true
    : useMediaQuery((theme: any) => theme.breakpoints.down('md'))

  const oldSearch = React.useRef('')

  const { loading, data, error, execute, clear } = useProducts({
    tenantId,
    page,
    rows: rowsPerPage,
    name: search,
    active: status !== 'deleted',
    status:
      status === 'active' ? TProductStatus.Active : TProductStatus.Inactive,
    productSituation: situationStatus,
  })

  const columns = myProductsColumns({ isAdmin, status, setTenantSettings })

  const handleChangePage = (newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (rows: number) => {
    if (rows > 0) {
      setRowsPerPage(rows)
    }
    setPage(0)
  }

  const handleSaveUserSetting = async () => {
    if (!tenantSettings) return

    setSending(true)
    try {
      const response = await TenantController.updateSettings({
        data: tenantSettings,
      })
      if (!response.success) {
        setErrorSending(response.error)
        return
      }
      setSuccess(true)
      setTenantSettings({ ...tenantSettingsDefault })
      execute()
    } finally {
      setSending(false)
    }
  }

  React.useEffect(() => {
    if (
      data.rowsPerPage &&
      data.rowsPerPage > 0 &&
      data.rowsPerPage !== rowsPerPage
    ) {
      setRowsPerPage(data.rowsPerPage)
    }

    if (oldSearch.current !== search) {
      oldSearch.current = search
      setPage(0)
    }
  }, [data])

  return (
    <Box>
      {error !== '' && (
        <Box sx={{ mb: 2 }}>
          <AlertDefault onClose={clear} severity="error" sx={{ width: '100%' }}>
            {error}
          </AlertDefault>
        </Box>
      )}

      {isSmallScreen ? (
        <Box>
          <DataTableHeaderMobile
            totalItems={data.total}
            setSearch={setSearch}
            executeSearch={execute}
            status={status}
            setStatus={setStatus}
            rowsPerPage={rowsPerPage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />
          <Stack spacing={1}>
            {data.items.map((product) => (
              <ProductCard
                key={product.id}
                product={product}
                productImage={
                  product.imageProductUrl
                    ? product.imageProductUrl
                    : '/assets/logo/logoCeletus.svg'
                }
              />
            ))}
          </Stack>
          <TablePaginationDefault
            count={data.total}
            rowsPerPage={rowsPerPage}
            page={page}
            hasNewCustomHeader
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      ) : (
        <DataTable
          columns={columns}
          data={data}
          page={page}
          rowsPerPage={rowsPerPage}
          headerComponent={
            <DataTableHeaderWeb
              labelItems="produtos"
              totalItems={data.total}
              setSearch={setSearch}
              executeSearch={execute}
              status={status}
              setStatus={setStatus}
              rowsPerPage={rowsPerPage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              situationStatus={situationStatus}
              setSituationStatus={setSituationStatus}
            />
          }
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          hasNewCustomHeader
        />
      )}

      {tenantSettings.id !== '' && (
        <UserSetting
          open={tenantSettings.id !== ''}
          sending={false}
          tenantSettings={tenantSettings}
          setOpen={() => {}}
          setTenantSettings={setTenantSettings}
          handleClose={() => setTenantSettings({ ...tenantSettingsDefault })}
          handleSave={handleSaveUserSetting}
          setSending={setSending}
          setSuccess={setSuccess}
          setError={setErrorSending}
          setMessage={setMessage}
          executeUserSettings={execute}
        />
      )}

      <LoadingBackdrop open={loading || sending} />

      <SuccessSnackbar open={message !== ''} onClose={() => setMessage('')}>
        {message}
      </SuccessSnackbar>

      <ErrorSnackbar
        open={errorSending !== ''}
        onClose={() => setErrorSending('')}
      >
        {errorSending}
      </ErrorSnackbar>

      <SuccessSnackbar open={success} onClose={() => setSuccess(false)}>
        Alterado com sucesso
      </SuccessSnackbar>
    </Box>
  )
}
