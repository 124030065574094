import { InputAdornment } from '@mui/material'
import InfoTooltip from '../Tooltip/InfoTooltip'

interface IInfoInputAdornmentProps {
  title: string | React.ReactNode
}

const InfoInputAdornment = ({ title }: IInfoInputAdornmentProps) => {
  return (
    <InputAdornment position="end" sx={{ cursor: 'default' }}>
      <InfoTooltip title={title} />
    </InputAdornment>
  )
}

export default InfoInputAdornment
