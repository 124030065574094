import { Button, Chip, Grid, Stack, Typography } from '@mui/material'
import { ProductPriceDetailOrderBumpsProps } from './ProductPriceTypes'
import { useContext } from 'react'
import { AuthContext } from '../../../../../core/context/AuthContext'

const ProductPriceDetailOrderBumps = ({
  price,
  setPrice,
  onOrderBump,
}: ProductPriceDetailOrderBumpsProps) => {
  const { isUserSysAdmin } = useContext(AuthContext)

  return (
    <>
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography
          color="#343948"
          fontSize="16px"
          lineHeight="20px"
          fontWeight={700}
        >
          Order Bumps
        </Typography>

        {!isUserSysAdmin() && (
          <Button
            variant="outlined"
            size="large"
            sx={{ textTransform: 'none' }}
            onClick={onOrderBump}
          >
            <Typography fontWeight={700} fontSize="14px" lineHeight="24px">
              + Adicionar Order Bump
            </Typography>
          </Button>
        )}
      </Stack>

      {price.orderBumps && price.orderBumps.length > 0 && (
        <Grid
          container={true}
          direction={'row'}
          spacing={1}
          style={{ overflowY: 'hidden' }}
        >
          {price.orderBumps
            ?.sort((a, b) => (a.order || 0) - (b.order || 0))
            .map((orderBump, orderBumpIndex) => (
              <Grid item={true} key={`productOrderBump${orderBumpIndex}`}>
                <Chip
                  color="primary"
                  disabled={isUserSysAdmin()}
                  label={`${orderBump.order} - ${
                    orderBump.productPrice?.product?.name || ''
                  } - ${'R$'}${(orderBump.productPrice?.value || 0).toFixed(2)}`}
                  onDelete={() => {
                    price.orderBumps = price.orderBumps?.filter(
                      (ob) => ob.orderBumpId !== orderBump.orderBumpId
                    )
                    setPrice({ ...price })
                  }}
                  sx={{
                    backgroundColor: 'rgba(216, 217, 255, 1)',
                    color: 'rgba(28, 82, 189, 1)',
                    fontFamily: 'Montserrat',
                    fontWeight: 500,
                    fontSize: '12px',
                    lineHeight: '16px',
                  }}
                />
              </Grid>
            ))}
        </Grid>
      )}
    </>
  )
}

export default ProductPriceDetailOrderBumps
