import { Box, Skeleton, Stack } from '@mui/material'
import {
  TCheckoutOrder,
  TCheckoutOrderDetails,
} from '../../../../../core/types/CheckoutOrder'
import ListItemDetail from '../../../../../components/ListItem/ListItemDetail'
import { maskCpfCnpjNumber, maskPhoneNumber } from '../../../../../masks/masks'
import ListItemDefault from '../../../../../components/ListItem/ListItemDefault'

export interface IOrderDetailCustomerProp {
  checkoutOrder?: TCheckoutOrder
  details?: TCheckoutOrderDetails
  loading: boolean
}

const OrderDetailCustomer = ({
  checkoutOrder,
  details,
  loading,
}: IOrderDetailCustomerProp) => {
  if (loading) {
    return (
      <Stack spacing={2} sx={{ p: 2 }}>
        <Skeleton variant="rounded" width="100%" height="45px" />
        <Skeleton variant="rounded" width="100%" height="45px" />
        <Skeleton variant="rounded" width="100%" height="45px" />
        <Skeleton variant="rounded" width="100%" height="45px" />
      </Stack>
    )
  }
  return (
    <Box>
      <ListItemDefault title="Nome" value={checkoutOrder?.name} />
      <ListItemDefault title="E-mail" value={checkoutOrder?.email} />
      <ListItemDefault
        title="Celular"
        value={maskPhoneNumber(details?.customerPhone)}
      />
      <ListItemDefault
        title="CPF"
        value={maskCpfCnpjNumber(details?.customerDocument)}
      />
      {details?.customFields?.map((field) => (
        <ListItemDefault
          key={field.identity}
          title={field.name}
          value={field.value}
        />
      ))}
    </Box>
  )
}

export default OrderDetailCustomer
