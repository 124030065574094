import IndicatedController from '../../../../core/controllers/IndicatedController'
import { TenantController } from '../../../../core/controllers/TenantController'
import { IErrorResponse } from '../../../../core/types/ErrorResponse'
import { TIndicatedTenantItem } from '../../../../core/types/IndicatedTenant'
import {
  EnableOrDisableIndicatedProps,
  GetIndicatedListProps,
  SetenableRegisterProductsProps,
  SetSalesEnabledProps,
  SetWithdrawlsEnabledProps,
} from './UserSettingTypes'

const getIndicatedList = async ({
  id,
  setLoading,
  setError,
  setIndicateds,
}: GetIndicatedListProps) => {
  setLoading(true)
  try {
    let response = await IndicatedController.listIndicatedById({ id })
    const responseError = response as IErrorResponse
    const responseData = response as TIndicatedTenantItem[]

    if (responseError.code) {
      setError(responseError.error)
      setIndicateds([])
    } else {
      setIndicateds(responseData)
    }
  } finally {
    setLoading(false)
  }
}

const enableOrDisableIndicated = async ({
  data,
  setSending,
  setError,
  setSuccess,
  setLoading,
  setIndicateds,
}: EnableOrDisableIndicatedProps) => {
  setSending(true)
  try {
    let response = await IndicatedController.enableOrDisableIndicated({
      data,
    })
    if (!response.success) {
      setError(response.error)
    } else {
      setSuccess(true)
      getIndicatedList({
        id: data.tenantId,
        setError,
        setLoading,
        setIndicateds,
      })
    }
  } finally {
    setSending(false)
  }
}

const setSalesEnabled = async ({
  salesEnabled,
  tenantSettings,
  setSending,
  setError,
  setSuccess,
  setTenantSettings,
  executeUserSettings,
}: SetSalesEnabledProps) => {
  setSending(true)
  try {
    let response = await TenantController.salesEnabled({
      tenantId: tenantSettings.tenantId,
      enabled: salesEnabled,
    })
    if (!response.success) {
      setError(response.error)
    } else {
      setSuccess(true)
      setTenantSettings({
        ...tenantSettings,
        salesEnabled,
      })
      executeUserSettings()
    }
  } finally {
    setSending(false)
  }
}

const setWithdrawlsEnabled = async ({
  withdrawlsEnabled,
  tenantSettings,
  setSending,
  setError,
  setSuccess,
  setTenantSettings,
  executeUserSettings,
}: SetWithdrawlsEnabledProps) => {
  setSending(true)
  try {
    let response = await TenantController.withdrawlsEnabled({
      tenantId: tenantSettings.tenantId,
      enabled: withdrawlsEnabled,
    })
    if (!response.success) {
      setError(response.error)
    } else {
      setSuccess(true)
      setTenantSettings({
        ...tenantSettings,
        withdrawlsEnabled,
      })
      executeUserSettings()
    }
  } finally {
    setSending(false)
  }
}

const enableRegisterProducts = async ({
  enabled,
  tenantSettings,
  setSending,
  setError,
  setSuccess,
  setTenantSettings,
  executeUserSettings,
}: SetenableRegisterProductsProps) => {
  setSending(true)
  try {
    let response = await TenantController.enableRegisterProducts({
      tenantId: tenantSettings.tenantId,
      enabled,
    })
    if (!response.success) {
      setError(response.error)
    } else {
      setSuccess(true)
      setTenantSettings({
        ...tenantSettings,
        enableRegisterProducts: enabled,
      })
      executeUserSettings()
    }
  } finally {
    setSending(false)
  }
}

const UserSettingFunctions = {
  getIndicatedList,
  enableOrDisableIndicated,
  setSalesEnabled,
  setWithdrawlsEnabled,
  enableRegisterProducts,
}

export default UserSettingFunctions
