import {
  Box,
  Fade,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import SwipeableDrawerRightDialog from '../../../../../components/Dialogs/SwipeableDrawerRightDialog'
import Convertions from '../../../../../core/functions/convertions'
import SwitchRound from '../../../../../components/Switch/SwitchRound'
import { HtmlTooltip } from '../../../../../components/Tooltip/HtmlTooltip'
import {
  TProductFrequency,
  TProductPrice,
  TProductTypePayment,
} from '../../../../../core/types/Product'
import NumberFunctions from '../../../../../core/functions/NumberFunctions'
import { payUrl } from '../../../../../core/functions/hosts'
import { useContext, useEffect, useState } from 'react'
import { IMultiSelectedValueItem } from '../../../../../components/Dialogs/MultiSelectedValueDialog'
import { AuthContext } from '../../../../../core/context/AuthContext'
import PriceInput from '../../../../../components/Inputs/PriceInput'
import { ProductPriceDetailProps } from './ProductPriceTypes'
import ProductPriceDetailOrderBumps from './ProductPriceDetailOrderBumps'
import ProductPriceDetailPayments from './ProductPriceDetailPayments'
import { productPriceCustomFields } from '../../../../../core/data/productPriceCustomFields'
import BpCheckbox from '../../../../../components/Checkout/BpCheckbox'

const ProductPriceDetail = ({
  open,
  sending,
  price,
  typePaymentValue,
  offerName,
  buttonPositiveTitle,
  methodTypes,
  options,
  setOpen,
  handleClose,
  setPrice,
  isTenantBank,
  onSave,
  onCopyURL,
  onPayments,
  onOrderBump,
  setCopied,
  setMethodTypes,
  onSavePayments,
}: ProductPriceDetailProps) => {
  const [errorPrice, setErrorPrice] = useState('')
  const [errorPayments, setErrorPayments] = useState('')
  const [errorFrequency, setErrorFrequency] = useState('')
  const [errorFirstPurchase, setErrorFirstPurchase] = useState('')
  const [errorOfferName, setErrorOfferName] = useState('')
  const [errorCycleNumbers, setErrorCycleNumbers] = useState('')

  const { isUserSysAdmin } = useContext(AuthContext)

  const customFields = price?.customFields ?? productPriceCustomFields

  const clearErrors = () => {
    if (errorPrice !== '') setErrorPrice('')

    if (errorPayments !== '') setErrorPayments('')

    if (errorFrequency !== '') setErrorFrequency('')

    if (errorFirstPurchase !== '') setErrorFirstPurchase('')

    if (errorOfferName !== '') setErrorOfferName('')

    if (errorCycleNumbers !== '') setErrorCycleNumbers('')
  }

  const validated = () => {
    var isError = false

    if (!price?.value) {
      setErrorPrice('Valor obrigatório R$ 5,00 ou mais')
      isError = true
    } else if (price.value < 5) {
      setErrorPrice('Valor obrigatório R$ 5,00 ou mais')
      isError = true
    }

    if ((price?.paymentMethods.length ?? 0) <= 0) {
      setErrorPayments('Selecione no mínimo um método de pagamento')
      isError = true
    }

    if (typePaymentValue === TProductTypePayment.RecurringSubscription) {
      if (!price?.frequency) {
        setErrorFrequency('Campo obrigatório')
        isError = true
      }

      if ((price?.firstPurchasePrice ?? 5) < 5) {
        setErrorFirstPurchase('Valor obrigatório R$ 5,00 ou mais')
        isError = true
      }

      if ((price?.cycleNumbers ?? '') === '') {
        setErrorCycleNumbers('Nº de Ciclos é um campo obrigatório')
        isError = true
      }
    }
    if (!price?.offerName) {
      setErrorOfferName('Campo obrigatório')
      isError = true
    }

    return !isError
  }

  const handleSave = () => {
    clearErrors()
    if (validated()) {
      onSave()
      setErrorPrice('')
      setErrorPayments('')
      setErrorOfferName('')
    }
  }

  const handleChangeMethodTypes = (
    data: IMultiSelectedValueItem[],
    productPrice: TProductPrice
  ) => {
    onSavePayments(data, productPrice)
    setMethodTypes(data)
  }

  useEffect(() => {
    if (!open) clearErrors()
  }, [open])

  if (price === null) {
    return <></>
  }

  return (
    <SwipeableDrawerRightDialog
      open={open}
      setOpen={setOpen}
      onClose={handleClose}
      title="Oferta do produto"
      subtitle="Defina as configurações da oferta"
      buttons={
        isUserSysAdmin()
          ? []
          : [
              {
                title: 'Cancelar',
                onClick: () => setOpen(false),
                type: 'negative',
              },
              {
                title: buttonPositiveTitle,
                onClick: handleSave,
                type: 'positive',
              },
            ]
      }
      buttonsDisabled={sending}
    >
      <Stack direction="column" spacing={2} sx={{ p: { xs: 1, sm: 2 } }}>
        <FormControl fullWidth size="small">
          <TextField
            fullWidth
            disabled={isUserSysAdmin()}
            size="small"
            variant="outlined"
            type="text"
            label="Nome da oferta"
            inputProps={{ maxLength: 150 }}
            error={errorOfferName !== ''}
            value={price.offerName ?? ''}
            onChange={(e) => {
              setPrice({ ...price, offerName: e.target.value })
              setErrorOfferName('')
            }}
          />
          {errorOfferName !== '' && (
            <Typography variant="overline" color={'red'}>
              {errorOfferName}
            </Typography>
          )}
        </FormControl>
        {/*<FormControl fullWidth size="small">
          <TextField
            fullWidth
            disabled={isUserSysAdmin()}
            size="small"
            variant="outlined"
            type="number"
            label="Preço"
            InputProps={{ inputProps: { min: 5 }, startAdornment: 'R$' }}
            error={errorPrice !== ''}
            value={price.value === undefined ? '' : price.value}
            onChange={(e) => {
              setPrice({
                ...price,
                value:
                  e.target.value === ''
                    ? undefined
                    : NumberFunctions.toNumber(e.target.value),
              })
              setErrorPrice('')
            }}
          />
          {errorPrice !== '' && (
            <Typography variant="overline" color={'red'}>
              {errorPrice}
            </Typography>
          )}
        </FormControl>*/}
        <FormControl fullWidth size="small">
          <PriceInput
            label="Preço"
            value={!price.value ? 0 : price.value}
            error={errorPrice !== ''}
            InputProps={{ inputProps: { min: 5 } }}
            onChange={(value) => {
              setPrice({ ...price, value })
              setErrorPrice('')
            }}
          />
          {errorPrice !== '' && (
            <Typography variant="overline" color={'red'}>
              {errorPrice}
            </Typography>
          )}
        </FormControl>
        {typePaymentValue === TProductTypePayment.RecurringSubscription && (
          <FormControl fullWidth size="small" error={errorFrequency !== ''}>
            <InputLabel id="days-select-label">Frequência cobrança</InputLabel>
            <Select
              variant="outlined"
              disabled={isUserSysAdmin()}
              labelId="days-select-label"
              label="Frequência cobrança"
              value={Number(price.frequency)}
              onChange={(e) => {
                setPrice({
                  ...price,
                  frequency: e.target.value as TProductFrequency,
                })
                setErrorFrequency('')
              }}
            >
              <MenuItem value={Number(TProductFrequency.Weekly)}>
                {Convertions.productFrequencyToString(TProductFrequency.Weekly)}
              </MenuItem>
              <MenuItem value={Number(TProductFrequency.Monthly)}>
                {Convertions.productFrequencyToString(
                  TProductFrequency.Monthly
                )}
              </MenuItem>
              <MenuItem value={Number(TProductFrequency.Bimonthly)}>
                {Convertions.productFrequencyToString(
                  TProductFrequency.Bimonthly
                )}
              </MenuItem>
              <MenuItem value={Number(TProductFrequency.Quarterly)}>
                {Convertions.productFrequencyToString(
                  TProductFrequency.Quarterly
                )}
              </MenuItem>
              <MenuItem value={Number(TProductFrequency.Semester)}>
                {Convertions.productFrequencyToString(
                  TProductFrequency.Semester
                )}
              </MenuItem>
              <MenuItem value={Number(TProductFrequency.Yearly)}>
                {Convertions.productFrequencyToString(TProductFrequency.Yearly)}
              </MenuItem>
            </Select>
            {errorFrequency !== '' && (
              <Typography variant="overline" color={'red'}>
                {errorFrequency}
              </Typography>
            )}
          </FormControl>
        )}
        {typePaymentValue === TProductTypePayment.RecurringSubscription && (
          <FormControl fullWidth size="small">
            <TextField
              fullWidth
              disabled={isUserSysAdmin()}
              size="small"
              variant="outlined"
              type="number"
              label="Preço primeira compra"
              InputProps={{ inputProps: { min: 5 }, startAdornment: 'R$' }}
              error={errorFirstPurchase !== ''}
              value={
                price.firstPurchasePrice === undefined
                  ? ''
                  : price.firstPurchasePrice
              }
              onChange={(e) => {
                setPrice({
                  ...price,
                  firstPurchasePrice:
                    e.target.value === ''
                      ? undefined
                      : NumberFunctions.toNumber(e.target.value),
                })
                setErrorFirstPurchase('')
              }}
            />
            {errorFirstPurchase !== '' && (
              <Typography variant="overline" color={'red'}>
                {errorFirstPurchase}
              </Typography>
            )}
          </FormControl>
        )}

        {typePaymentValue == TProductTypePayment.RecurringSubscription && (
          <Box>
            <TextField
              fullWidth
              disabled={isUserSysAdmin()}
              size="small"
              type="number"
              label="Nº de Ciclos (Informar '0' para cobranças recorrentes sem limites de ciclos)"
              InputLabelProps={{
                shrink: (price.cycleNumbers ?? '') !== '',
              }}
              inputProps={{ inputMode: 'numeric' }}
              value={price.cycleNumbers === undefined ? '' : price.cycleNumbers}
              onChange={(e) => {
                setPrice({
                  ...price,
                  cycleNumbers:
                    e.target.value === ''
                      ? undefined
                      : NumberFunctions.toNumber(e.target.value),
                })
                setErrorCycleNumbers('')
              }}
            />
            {errorCycleNumbers !== '' && (
              <Typography variant="overline" color={'red'}>
                {errorCycleNumbers}
              </Typography>
            )}
          </Box>
        )}

        <FormControlLabel
          control={
            <SwitchRound
              sx={{ mr: 1 }}
              size="small"
              disabled={isUserSysAdmin()}
              checked={customFields?.isCustomFields ?? false}
              onChange={(e) =>
                setPrice({
                  ...price,
                  customFields: {
                    ...customFields,
                    isCustomFields: e.target.checked,
                  },
                })
              }
            />
          }
          label={
            <Typography
              color="#707275"
              fontSize="14px"
              lineHeight="18px"
              fontWeight={500}
            >
              Campos personalizados
            </Typography>
          }
          sx={{ width: '100%' }}
        />

        <Fade
          in={customFields?.isCustomFields ?? false}
          mountOnEnter
          unmountOnExit
        >
          <Box sx={{ px: 3 }}>
            {customFields?.fields.map((field, index) => (
              <FormControlLabel
                disabled={isUserSysAdmin()}
                control={
                  <BpCheckbox
                    checked={field.isChecked}
                    onChange={(e) => {
                      const newFields = customFields.fields
                      newFields[index].isChecked = e.target.checked
                      setPrice({
                        ...price,
                        customFields: {
                          ...customFields,
                          fields: newFields,
                        },
                      })
                    }}
                  />
                }
                label={
                  <Typography
                    lineHeight="18px"
                    fontSize={{ xs: '13px', sm: '14px' }}
                    variant="body2"
                    sx={{
                      color: '#5D5E61',
                    }}
                  >
                    {field.name}
                  </Typography>
                }
              />
            ))}
          </Box>
        </Fade>

        <FormControlLabel
          control={
            <SwitchRound
              sx={{ mr: 1 }}
              size="small"
              disabled={isUserSysAdmin()}
              checked={price.hideCheckoutAddress}
              onChange={(e) =>
                setPrice({ ...price, hideCheckoutAddress: e.target.checked })
              }
            />
          }
          label={
            <Typography
              color="#707275"
              fontSize="14px"
              lineHeight="18px"
              fontWeight={500}
            >
              Ocultar dados de endereço no checkout
            </Typography>
          }
          sx={{ width: '100%' }}
        />

        {isTenantBank && price.id !== '' && (
          <Stack direction="column" spacing={2} sx={{ width: '100%' }}>
            <TextField
              fullWidth
              disabled={isUserSysAdmin()}
              size="small"
              label="Link checkout"
              value={`${payUrl}/${price.codeId}`}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <HtmlTooltip title={<Typography>Copiar URL</Typography>}>
                      <IconButton size="small" onClick={() => onCopyURL(false)}>
                        <img src="/assets/icons/copy/copyGrey.svg" alt="" />
                      </IconButton>
                    </HtmlTooltip>
                  </InputAdornment>
                ),
              }}
            />
            {/* <TextField
              fullWidth
              size="small"
              label="Link imune de afiliado"
              value={`${payUrl}/pro/${price.codeId}`}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <HtmlTooltip title={<Typography>Copiar URL</Typography>}>
                      <IconButton size="small" onClick={() => onCopyURL(true)}>
                        <img src="/assets/icons/copy/copyGrey.svg" alt="" />
                      </IconButton>
                    </HtmlTooltip>
                  </InputAdornment>
                ),
              }}
            /> */}
          </Stack>
        )}

        <ProductPriceDetailPayments
          price={price}
          options={options}
          errorPayments={errorPayments}
          methodTypes={methodTypes}
          setPrice={setPrice}
          onChangeMethodTypes={handleChangeMethodTypes}
        />

        <ProductPriceDetailOrderBumps
          price={price}
          setPrice={setPrice}
          onOrderBump={onOrderBump}
        />

        {/*isTenantBank() && price.id !== '' && (
                    <Stack direction="column" spacing={1} sx={{ p: 1 }}>
                        <Typography variant="subtitle2">Código do widget de página de pagamento</Typography>
                        <CopyBlock
                            text={getProductPriceCodeBlock(price)}
                            codeBlock={true}
                            language="javascript"
                            showLineNumbers={6}
                            startingLineNumber={1}
                            wrapLines
                            wrapLongLines
                            copied
                            theme={solarizedDark}
                            onCopy={() => setCopied(true)}
                        />
                        <Typography variant="caption">Incorpore um atalho facilitado da sua página de pagamento a qualquer endereço web por meio do código</Typography>
                    </Stack>
                )*/}
      </Stack>
    </SwipeableDrawerRightDialog>
  )
}

export default ProductPriceDetail
