import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material'
import { Link } from 'react-router-dom'
import { TMenu } from '../../core/types/Menu'

interface ListItemMenuProps {
  menu: TMenu
  isOpenOrXS: boolean
  selected: string
  setSelected: (value: string) => void
}

const ListItemMenu = ({
  menu,
  isOpenOrXS,
  selected,
  setSelected,
}: ListItemMenuProps) => {
  return (
    <ListItem disablePadding key={menu.name} sx={{ borderRadius: 1, py: 0.2 }}>
      <ListItemButton
        component={Link}
        to={menu.to}
        selected={selected === menu.name}
        sx={{
          borderRadius: 1,
          minHeight: 48,
          justifyContent: isOpenOrXS ? 'initial' : 'center',
          px: 2.5,
        }}
        onClick={() => setSelected(menu.name)}
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            ml: isOpenOrXS ? '0px' : '16px',
            mr: '0px',
            justifyContent: 'center',
          }}
        >
          <Stack alignItems="center" justifyContent="center">
            <img
              src={`${menu.icon.path}${selected === menu.name ? '-selected' : ''}${
                menu.icon.extension
              }`}
              alt={menu.name}
            />
            {(menu.isVersionBeta ?? false) && !isOpenOrXS && (
              <Stack
                sx={{
                  position: 'absolute',
                  left: '10px',
                  bottom: '-3px',
                }}
              >
                <Typography variant="caption" sx={{ color: '#1C52BD' }}>
                  Beta
                </Typography>
              </Stack>
            )}
          </Stack>
        </ListItemIcon>
        <ListItemText
          primary={`${menu.name}${menu.isVersionBeta && isOpenOrXS ? ' (Beta)' : ''}`}
          sx={{
            ml: 0,
            opacity: isOpenOrXS ? 1 : 0,
            color: selected === menu.name ? '#1C52BD' : undefined,
          }}
        />
      </ListItemButton>
    </ListItem>
  )
}

export default ListItemMenu
