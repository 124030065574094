import {
  Box,
  Button,
  Collapse,
  Container,
  FormControl,
  IconButton,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  Stack,
  Typography,
} from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import React, { useCallback, useEffect, useState } from 'react'
import AppWidgetSummary from './Components/app-widget-summary'
import AppDailyCommissions from './Components/app-daily-commissions'
import AppTopSellingProducts from './Components/app-top-selling-products'
import TitlePageTypography from '../../components/Typographys/TitlePageTypography'
import SubtitlePageTypography from '../../components/Typographys/SubtitlePageTypography'
import {
  addDays,
  dayAndMonthString,
  getDayOfWeek,
  lastTime,
  resetTime,
  timeString,
} from '../../core/functions/dateTime'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { ValueOrPercentageGroupButtonsEnum } from '../../components/Buttons/ValueOrPercentageGroupButtons'
import SaleByStatePage from './Components/HorizonChart/saleByState'
import AppPaymentMethods from './Components/app-payment-methods'
import {
  TSalesGroupKey,
  TChartRange,
  TChartType,
  TComissionCard,
  TDailyCommissions,
  TDateRange,
  TConversionsCard,
  TSalesPaymentMethod,
  TSalesForGroupKey,
  TDailySales,
} from '../../core/types/Dashboard'
import DashboardController from '../../core/controllers/DashboardController'
import { AuthContext } from '../../core/context/AuthContext'
import DashboardFunctions from '../../core/functions/DashboardFunctions'
import { IErrorResponse } from '../../core/types/ErrorResponse'
import SaleByCityPage from './Components/HorizonChart/saleByCity'
import AppTopSellingProductTypes from './Components/app-top-selling-product-types'
import BasicDatePicker from '../../components/DateTime/BasicDatePicker'

export default function DashboardPage() {
  const { user, setUser } = React.useContext(AuthContext)

  const [dateTime, setDateTime] = useState(new Date())
  const [visibility, setVisibility] = useState(false)
  const [openSelectType, setOpenSelectType] = React.useState(false)
  const [openSelectRange, setOpenSelectRange] = React.useState(false)
  const [viewFiltersMobile, setViewFiltersMobile] = React.useState(false)
  const [loading, setLoading] = React.useState(false)

  const [chartType, setChartType] = useState(TChartType.Brazil)
  const [chartRange, setChartRange] = useState(TChartRange.last30)
  const [dateRange, setDateRange] = useState<TDateRange>({
    startDate: resetTime(addDays(-29)),
    endDate: lastTime(),
  })

  const [selectedTopSellingProducts, setSelectedTopSellingProducts] =
    React.useState(ValueOrPercentageGroupButtonsEnum.Percentage)
  const [selectedTopSellingProductTypes, setSelectedTopSellingProductTypes] =
    React.useState(ValueOrPercentageGroupButtonsEnum.Percentage)
  const [selectedPaymentMethods, setSelectedPaymentMethods] = React.useState(
    ValueOrPercentageGroupButtonsEnum.Percentage
  )
  const [selectedTopSellingStates, setSelectedTopSellingStates] =
    React.useState(ValueOrPercentageGroupButtonsEnum.Percentage)
  const [selectedTopSellingCity, setSelectedTopSellingCity] = React.useState(
    ValueOrPercentageGroupButtonsEnum.Percentage
  )
  const [selectedDailyCommissions, setSelectedDailyCommissions] =
    React.useState(TDailyCommissions.Daily)

  const [comissionCard, setComissionCard] = useState<TComissionCard>({
    totalComissions: 0,
    totalUniqueSales: 0,
    indicationComissions: 0,
    totalComissionsVariations: 0,
    totalUniqueSalesVariations: 0,
    indicationComissionsVariations: 0,
    totalComissionsDiference: 0,
    totalUniqueSalesDiference: 0,
    indicationComissionsDiference: 0,
  })

  const [conversionsCard, setConversionsCard] =
    React.useState<TConversionsCard>({
      creditCardConversions: 0,
      pixConversions: 0,
      boletoConversions: 0,
      refundedRate: 0,
      creditCardConversionsVariations: 0,
      pixConversionsVariations: 0,
      boletoConversionsVariations: 0,
      refundedRateVariations: 0,
    })

  const [dailySales, setDailySales] = React.useState<Array<TDailySales[]>>([
    [],
    [],
  ])
  const [salesForStates, setSalesForStates] = React.useState<
    TSalesForGroupKey[]
  >([])
  const [salesForCities, setSalesForCities] = React.useState<
    TSalesForGroupKey[]
  >([])
  const [salesPaymentMethod, setSalesPaymentMethod] = React.useState<
    TSalesPaymentMethod[]
  >([])
  const [bestSellingProducts, setBestSellingProducts] = React.useState<
    TSalesGroupKey[]
  >([])
  const [bestSellingProductTypes, setBestSellingProductTypes] = React.useState<
    TSalesGroupKey[]
  >([])

  const handleCloseSelectType = () => {
    setOpenSelectType(false)
  }

  const handleOpenSelectType = () => {
    setOpenSelectType(true)
  }

  const handleCloseSelectRange = () => {
    setOpenSelectRange(false)
  }

  const handleOpenSelectRange = () => {
    setOpenSelectRange(true)
  }

  const getComissionCard = useCallback(
    async (tenantId: string | null | undefined, range: TDateRange) => {
      try {
        setLoading(true)

        const response = await DashboardController.getComissionCard({
          tenantId: tenantId ?? '',
          startPeriod: range.startDate ?? new Date(),
          endPeriod: range.endDate ?? new Date(),
        })
        const responseError = response as IErrorResponse
        const responseData = response as TComissionCard
        if (!responseError.error) {
          setComissionCard(responseData)
        }
      } finally {
        setLoading(false)
      }
    },
    []
  )

  const getConversionsCard = useCallback(
    async (tenantId: string | null | undefined, range: TDateRange) => {
      try {
        setLoading(true)

        const response = await DashboardController.getConversionsCard({
          tenantId: tenantId ?? '',
          startPeriod: range.startDate ?? new Date(),
          endPeriod: range.endDate ?? new Date(),
        })
        const responseError = response as IErrorResponse
        const responseData = response as TConversionsCard
        if (!responseError.error) {
          setConversionsCard(responseData)
        }
      } finally {
        setLoading(false)
      }
    },
    []
  )

  const getDailySales = useCallback(
    async (tenantId: string | null | undefined, range: TDateRange) => {
      if (!visibility) {
        return
      }

      try {
        setLoading(true)

        const startPeriod = range.startDate ?? new Date()
        const endPeriod = range.endDate ?? new Date()

        const response = await DashboardController.getDailySales({
          tenantId: tenantId ?? '',
          startPeriod,
          endPeriod,
          periodType: selectedDailyCommissions,
        })
        const responseError = response as IErrorResponse
        const responseData = response as TDailySales[]
        if (responseError.error) {
          setDailySales([[], []])
          return
        }

        /*const diffInDays = diffDays(startPeriod, endPeriod) - 1;
            const endPeriodBefore = new Date(startPeriod);
            const startPeriodBefore = addDays(diffInDays, startPeriod);

            const responseBefore = await DashboardController.getDailySales({
                tenantId: tenantId ?? "",
                startPeriod: startPeriodBefore,
                endPeriod: endPeriodBefore,
                periodType: selectedDailyCommissions,
            });
            const responseBeforeError = responseBefore as IErrorResponse;
            const responseBeforeData = responseBefore as TDailySales[];
            if (responseBeforeError.error) {
                setDailySales([[], []]);
                return;
            }

            setDailySales([responseData, responseBeforeData]);*/
        setDailySales([responseData, responseData])
      } finally {
        setLoading(false)
      }
    },
    [visibility, selectedDailyCommissions]
  )

  const getSalesForStates = useCallback(
    async (tenantId: string | null | undefined, range: TDateRange) => {
      try {
        setLoading(true)

        const response = await DashboardController.getSalesForStates({
          tenantId: tenantId ?? '',
          startPeriod: range.startDate ?? new Date(),
          endPeriod: range.endDate ?? new Date(),
        })
        const responseError = response as IErrorResponse
        const responseData = response as TSalesForGroupKey[]
        if (!responseError.error) {
          setSalesForStates(responseData)
        }
      } finally {
        setLoading(false)
      }
    },
    []
  )

  const getSalesForCities = useCallback(
    async (tenantId: string | null | undefined, range: TDateRange) => {
      try {
        setLoading(true)
        const response = await DashboardController.getSalesForCities({
          tenantId: tenantId ?? '',
          startPeriod: range.startDate ?? new Date(),
          endPeriod: range.endDate ?? new Date(),
        })
        const responseError = response as IErrorResponse
        const responseData = response as TSalesForGroupKey[]
        if (!responseError.error) {
          setSalesForCities(responseData)
        }
      } finally {
        setLoading(false)
      }
    },
    []
  )

  const getSalesPaymentMethod = useCallback(
    async (tenantId: string | null | undefined, range: TDateRange) => {
      try {
        setLoading(true)

        const response = await DashboardController.getSalesPaymentMethod({
          tenantId: tenantId ?? '',
          startPeriod: range.startDate ?? new Date(),
          endPeriod: range.endDate ?? new Date(),
        })
        const responseError = response as IErrorResponse
        const responseData = response as TSalesPaymentMethod[]
        if (!responseError.error) {
          setSalesPaymentMethod(responseData)
        }
      } finally {
        setLoading(false)
      }
    },
    []
  )

  const getBestSellingProducts = useCallback(
    async (tenantId: string | null | undefined, range: TDateRange) => {
      try {
        setLoading(true)

        const response = await DashboardController.getBestSellingProducts({
          tenantId: tenantId ?? '',
          startPeriod: range.startDate ?? new Date(),
          endPeriod: range.endDate ?? new Date(),
        })
        const responseError = response as IErrorResponse
        const responseData = response as TSalesGroupKey[]
        if (!responseError.error) {
          setBestSellingProducts(responseData)
        }
      } finally {
        setLoading(false)
      }
    },
    []
  )

  const getBestSellingProductsByType = useCallback(
    async (tenantId: string | null | undefined, range: TDateRange) => {
      try {
        setLoading(true)

        const response = await DashboardController.getBestSellingProductsByType(
          {
            tenantId: tenantId ?? '',
            startPeriod: range.startDate ?? new Date(),
            endPeriod: range.endDate ?? new Date(),
          }
        )
        const responseError = response as IErrorResponse
        const responseData = response as TSalesGroupKey[]
        if (!responseError.error) {
          setBestSellingProductTypes(responseData)
        }
      } finally {
        setLoading(false)
      }
    },
    []
  )

  const getDashboardAll = async () => {
    if (!visibility) {
      return
    }

    await getComissionCard(user?.TenantId ?? '', dateRange)
    await getConversionsCard(user?.TenantId ?? '', dateRange)
    await getDailySales(user?.TenantId ?? '', dateRange)
    await getSalesForStates(user?.TenantId ?? '', dateRange)
    await getSalesForCities(user?.TenantId ?? '', dateRange)
    await getSalesPaymentMethod(user?.TenantId ?? '', dateRange)
    await getBestSellingProducts(user?.TenantId ?? '', dateRange)
    await getBestSellingProductsByType(user?.TenantId ?? '', dateRange)
  }

  const salesPaymentMethodSeries = () => {
    const total =
      salesPaymentMethod.length > 0
        ? salesPaymentMethod
            .map((spm) => spm.totalValue)
            .reduce((prev, curr) => prev + curr)
        : 0

    return salesPaymentMethod.map((spm) => ({
      label: spm.paymentMethod,
      value: DashboardFunctions.getSelectedValue(
        selectedPaymentMethods,
        spm.totalValue,
        total
      ),
    }))
  }

  const bestSellingProductsSeries = () => {
    if (bestSellingProducts.length <= 0) {
      return []
    }

    const more =
      bestSellingProducts.length > 9
        ? bestSellingProducts
            .sort((a, b) =>
              selectedTopSellingProducts ===
              ValueOrPercentageGroupButtonsEnum.Value
                ? b.value - a.value
                : b.count - a.count
            )
            .filter((bsp, index) => index >= 4)
            .reduce((prev, curr) => ({
              name: 'Outros',
              count: prev.count + curr.count,
              percentage: prev.percentage + curr.percentage,
              value: prev.value + curr.value,
            }))
        : null

    const series = bestSellingProducts
      .sort((a, b) =>
        selectedTopSellingProducts === ValueOrPercentageGroupButtonsEnum.Value
          ? b.value - a.value
          : b.count - a.count
      )
      .filter((bsp, index) => index < 9)
      .map((bsp) => ({
        label: bsp.name,
        value: bsp.value,
        percentage: bsp.percentage,
      }))

    if (more && more.value > 0) {
      series.push({
        label: more.name,
        value: more.value,
        percentage: more.percentage,
      })
    }

    return series
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setDateTime(new Date())
    }, 30000)

    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    switch (chartRange) {
      case TChartRange.last7:
        setDateRange({ startDate: resetTime(addDays(-6)), endDate: lastTime() })
        return
      case TChartRange.last30:
        setDateRange({
          startDate: resetTime(addDays(-29)),
          endDate: lastTime(),
        })
        return
      case TChartRange.last90:
        setDateRange({
          startDate: resetTime(addDays(-89)),
          endDate: lastTime(),
        })
        return
      case TChartRange.last180:
        setDateRange({
          startDate: resetTime(addDays(-179)),
          endDate: lastTime(),
        })
        return
    }
  }, [chartRange])

  useEffect(() => {
    getDashboardAll()
  }, [dateRange, visibility])

  useEffect(() => {
    getDailySales(user?.TenantId ?? '', dateRange)
  }, [selectedDailyCommissions])

  return (
    <Container maxWidth="xl">
      <Box sx={{ mb: 1 }}>
        <Stack
          direction="row"
          spacing={2}
          alignContent="space-between"
          alignItems="center"
        >
          <Box sx={{ flexGrow: 1 }}>
            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              sx={{ pb: 1 }}
            >
              <TitlePageTypography>Dashboard</TitlePageTypography>
            </Stack>
            <SubtitlePageTypography>
              {getDayOfWeek(dateTime)}
              {', '}
              {dayAndMonthString(dateTime)}
              {' • '}
              {timeString(dateTime)}
            </SubtitlePageTypography>
          </Box>
          <Box sx={{ flexGrow: 0 }}>
            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              spacing={1}
              alignContent="center"
              alignItems="center"
              justifyContent="center"
            >
              <Stack
                direction="row"
                spacing={1}
                alignContent="center"
                alignItems="center"
                justifyContent="center"
              >
                <Button
                  sx={{ p: 0, m: 0, minWidth: 0, borderRadius: '8px' }}
                  onClick={() => setVisibility(!visibility)}
                >
                  <Paper
                    elevation={0}
                    sx={{
                      border: '1px solid #C5C6C9',
                      borderRadius: '8px',
                      p: '8px',
                      fontSize: '0rem',
                    }}
                  >
                    {visibility ? (
                      <img src="/assets/icons/eyes/eyeVisible.svg" alt="" />
                    ) : (
                      <img src="/assets/icons/eyes/eyeInvisible.svg" alt="" />
                    )}
                  </Paper>
                </Button>
                <Button
                  sx={{
                    p: 0,
                    m: 0,
                    minWidth: 0,
                    borderRadius: '8px',
                    display: { xs: 'block', sm: 'none' },
                  }}
                  onClick={() => setViewFiltersMobile(!viewFiltersMobile)}
                >
                  <Paper
                    elevation={0}
                    sx={{
                      border: '1px solid #C5C6C9',
                      borderRadius: '8px',
                      p: '8px',
                      fontSize: '0rem',
                    }}
                  >
                    <img
                      src="/assets/icons/menu/funnel/funnel.svg"
                      alt="Filtro"
                    />
                  </Paper>
                </Button>
                {/*!viewFiltersMobile && (
                                    <FormControl
                                        variant="outlined"
                                        size="small"
                                        sx={{
                                            width: "159px",
                                            backgroundColor: "white",
                                            borderRadius: "8px",
                                            display: { xs: "none", sm: "flex" },
                                        }}
                                    >
                                        <Select
                                            labelId="select-chart-type-label"
                                            id="select-chart-type"
                                            autoWidth={false}
                                            disableUnderline={true}
                                            open={openSelectType}
                                            onClose={handleCloseSelectType}
                                            onOpen={handleOpenSelectType}
                                            value={chartType}
                                            onChange={(event) => {
                                                setChartType(event.target.value as TChartType);
                                            }}
                                            renderValue={(selected) => {
                                                const ct = DashboardFunctions.chartTypes.find(
                                                    (x) => x.code === selected
                                                );
                                                if (ct) {
                                                    return (
                                                        <Stack
                                                            direction="row"
                                                            spacing={1}
                                                            alignContent="center"
                                                            alignItems="center"
                                                        >
                                                            <Stack direction="row" spacing={2}>
                                                                <img
                                                                    alt={ct.name}
                                                                    src={ct.src}
                                                                    style={{ width: 24, height: 24 }}
                                                                />
                                                                <Typography
                                                                    fontWeight={400}
                                                                    fontSize="14px"
                                                                    lineHeight="24px"
                                                                >
                                                                    {ct.name}
                                                                </Typography>
                                                            </Stack>
                                                        </Stack>
                                                    );
                                                }
                                                return <Box></Box>;
                                            }}
                                            IconComponent={() => (
                                                <IconButton
                                                    size="small"
                                                    onClick={() => setOpenSelectType(!openSelectType)}
                                                    sx={{ width: 26, height: 26, mr: 2 }}
                                                >
                                                    {openSelectType ? (
                                                        <KeyboardArrowUpIcon color="disabled" />
                                                    ) : (
                                                        <KeyboardArrowDownIcon color="disabled" />
                                                    )}
                                                </IconButton>
                                            )}
                                            style={{ borderRadius: "8px", paddingRight: "0px" }}
                                        >
                                            {DashboardFunctions.chartTypes.map((ct) => (
                                                <MenuItem key={ct.name} value={ct.code}>
                                                    <Stack direction="row" spacing={2}>
                                                        <img
                                                            alt={ct.name}
                                                            src={ct.src}
                                                            style={{ width: 24, height: 24 }}
                                                        />
                                                        <Typography fontWeight={400} fontSize="14px" lineHeight="18px">
                                                            {ct.name}
                                                        </Typography>
                                                    </Stack>
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                )*/}
              </Stack>

              <FormControl
                variant="outlined"
                size="small"
                sx={{
                  width: '210px',
                  backgroundColor: 'white',
                  borderRadius: '8px',
                  display: { xs: 'none', sm: 'flex' },
                }}
              >
                <Select
                  labelId="select-chart-range-label"
                  id="select-chart-range"
                  autoWidth={false}
                  disableUnderline={true}
                  open={openSelectRange}
                  onClose={handleCloseSelectRange}
                  onOpen={handleOpenSelectRange}
                  value={chartRange}
                  onChange={(event) => {
                    setChartRange(event.target.value as TChartRange)
                  }}
                  renderValue={(selected) => {
                    const ct = DashboardFunctions.chartRanges.find(
                      (x) => x.code === selected
                    )
                    if (ct) {
                      return (
                        <Stack
                          direction="row"
                          spacing={1}
                          alignContent="center"
                          alignItems="center"
                        >
                          <Stack direction="row" spacing={2}>
                            <img
                              alt="Período"
                              src="/assets/dashboard/calendar.svg"
                              style={{ width: 24, height: 24 }}
                            />
                            <Typography
                              fontWeight={400}
                              fontSize="14px"
                              lineHeight="24px"
                            >
                              {ct.name}
                            </Typography>
                          </Stack>
                        </Stack>
                      )
                    }
                    return <Box></Box>
                  }}
                  IconComponent={() => (
                    <IconButton
                      size="small"
                      onClick={() => setOpenSelectRange(!openSelectRange)}
                      sx={{ width: 26, height: 26, mr: 2 }}
                    >
                      {openSelectRange ? (
                        <KeyboardArrowUpIcon color="disabled" />
                      ) : (
                        <KeyboardArrowDownIcon color="disabled" />
                      )}
                    </IconButton>
                  )}
                  style={{ borderRadius: '8px', paddingRight: '0px' }}
                >
                  {DashboardFunctions.chartRanges.map((cr) => (
                    <MenuItem key={cr.name} value={cr.code}>
                      <Stack direction="row" spacing={2}>
                        <Typography
                          fontWeight={400}
                          fontSize="14px"
                          lineHeight="18px"
                        >
                          {cr.name}
                        </Typography>
                      </Stack>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>
          </Box>
        </Stack>
      </Box>

      <Box width="100%">
        <Collapse in={viewFiltersMobile}>
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            justifyContent="end"
            sx={{ mb: 2 }}
          >
            {viewFiltersMobile && (
              <FormControl
                fullWidth
                variant="outlined"
                size="small"
                sx={{
                  backgroundColor: 'white',
                  borderRadius: '8px',
                  display: { xs: 'block', sm: 'none' },
                }}
              >
                <Select
                  fullWidth
                  autoWidth={false}
                  disableUnderline={true}
                  //open={openSelectRange}
                  //onClose={handleCloseSelectRange}
                  //onOpen={handleOpenSelectRange}
                  value={chartRange}
                  onChange={(event) => {
                    setChartRange(event.target.value as TChartRange)
                  }}
                  renderValue={(selected) => {
                    const ct = DashboardFunctions.chartRanges.find(
                      (x) => x.code === selected
                    )
                    if (ct) {
                      return (
                        <Stack
                          direction="row"
                          spacing={1}
                          alignContent="center"
                          alignItems="center"
                        >
                          <Stack direction="row" spacing={2}>
                            <img
                              alt="Período"
                              src="/assets/dashboard/calendar.svg"
                              style={{ width: 24, height: 24 }}
                            />
                            <Typography
                              fontWeight={400}
                              fontSize="14px"
                              lineHeight="24px"
                            >
                              {ct.name}
                            </Typography>
                          </Stack>
                        </Stack>
                      )
                    }
                    return <Box></Box>
                  }}
                  /*IconComponent={() => (
                                        <IconButton
                                            size="small"
                                            onClick={() => setOpenSelectRange(!openSelectRange)}
                                            sx={{ width: 26, height: 26, mr: 2 }}
                                        >
                                            {openSelectRange ? (
                                                <KeyboardArrowUpIcon color="disabled" />
                                            ) : (
                                                <KeyboardArrowDownIcon color="disabled" />
                                            )}
                                        </IconButton>
                                    )}*/
                  style={{ borderRadius: '8px', paddingRight: '0px' }}
                >
                  {DashboardFunctions.chartRanges.map((cr) => (
                    <MenuItem key={cr.name} value={cr.code}>
                      <Stack direction="row" spacing={2}>
                        <Typography
                          fontWeight={400}
                          fontSize="14px"
                          lineHeight="18px"
                        >
                          {cr.name}
                        </Typography>
                      </Stack>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </Stack>
        </Collapse>
      </Box>

      <Box width="100%">
        <Collapse in={chartRange === TChartRange.custom}>
          <Stack
            spacing={1}
            direction={{ xs: 'column', sm: 'row' }}
            justifyContent="end"
            sx={{ mb: 2 }}
          >
            <Stack
              direction="row"
              flexDirection="row"
              sx={{
                width: { xs: '100%', sm: '210px' },
                backgroundColor: 'white',
                borderRadius: '16px',
              }}
            >
              <BasicDatePicker
                value={dateRange.startDate}
                setValue={(e) => {
                  if (e) {
                    setDateRange({ ...dateRange, startDate: resetTime(e) })
                  }
                }}
              />
            </Stack>
            <Stack
              direction="row"
              flexDirection="row"
              sx={{
                width: { xs: '100%', sm: '210px' },
                backgroundColor: 'white',
                borderRadius: '16px',
              }}
            >
              <BasicDatePicker
                value={dateRange.endDate}
                setValue={(e) => {
                  if (e) {
                    setDateRange({ ...dateRange, endDate: lastTime(e) })
                  }
                }}
              />
            </Stack>
          </Stack>
        </Collapse>
      </Box>

      <LinearProgress
        sx={{ mb: 1, opacity: loading ? 1 : 0, transition: 'all .2s' }}
      />

      <Grid container spacing={3}>
        <Grid xs={12} lg={4}>
          <AppWidgetSummary
            title="Total de Comissões"
            total={comissionCard.totalComissions}
            increasing={comissionCard.totalComissionsVariations}
            subtype={
              comissionCard.totalComissionsDiference >= 0
                ? 'positive'
                : 'negative'
            }
            isSubtype={true}
            subtotal={comissionCard.totalComissionsDiference}
            type="money"
            src="/assets/icons/payments/pay.svg"
            isVisibleValue={visibility}
          />
        </Grid>

        <Grid xs={12} lg={4}>
          <AppWidgetSummary
            title="Total de Vendas Únicas"
            total={comissionCard.totalUniqueSales}
            increasing={comissionCard.totalUniqueSalesVariations}
            subtype={
              comissionCard.totalUniqueSalesDiference >= 0
                ? 'positive'
                : 'negative'
            }
            isSubtype={true}
            subtotal={comissionCard.totalUniqueSalesDiference}
            type="int"
            src="/assets/icons/payments/pay.svg"
            isVisibleValue={visibility}
          />
        </Grid>

        <Grid xs={12} lg={4}>
          <AppWidgetSummary
            title="Comissão de indicações"
            total={comissionCard.indicationComissions}
            increasing={comissionCard.indicationComissionsVariations}
            subtype={
              comissionCard.indicationComissionsDiference >= 0
                ? 'positive'
                : 'negative'
            }
            isSubtype={true}
            subtotal={comissionCard.indicationComissionsDiference}
            type="money"
            src="/assets/icons/payments/pay.svg"
            isVisibleValue={visibility}
          />
        </Grid>

        <Grid xs={12} lg={8}>
          <AppDailyCommissions
            title="Comissões"
            total={449}
            percentage={16}
            subtype="positive"
            isVisibleValue={visibility}
            selected={selectedDailyCommissions}
            setSelected={setSelectedDailyCommissions}
            chart={{
              labels: dailySales[0].map((ss) => ss.date.toString()),
              series: [
                {
                  name: 'Período selecionado',
                  type: 'column',
                  fill: 'solid',
                  data: dailySales[0].map((ss) => ss.value),
                  dates: dailySales[0].map((ss) => ss.date),
                },
                {
                  name: 'Período anterior do selecionado',
                  type: 'line',
                  fill: 'solid',
                  data: dailySales[1].map((ssb) => ssb.value),
                  dates: dailySales[1].map((ssb) => ssb.date),
                },
              ],
            }}
          />
        </Grid>

        <Grid xs={12} lg={4}>
          <Stack direction="column" spacing={1}>
            <AppWidgetSummary
              title="Conversão Boleto"
              total={conversionsCard.boletoConversions}
              increasing={conversionsCard.boletoConversionsVariations}
              isSubtype={false}
              subtotal={0}
              subtype={
                conversionsCard.boletoConversionsVariations >= 0
                  ? 'positive'
                  : 'negative'
              }
              type="percentage"
              src="/assets/icons/payments/barcode.svg"
              sx={{ height: '101.33px' }}
              isVisibleValue={visibility}
            />

            <AppWidgetSummary
              title="Conversão PIX"
              total={conversionsCard.pixConversions}
              increasing={conversionsCard.pixConversionsVariations}
              isSubtype={false}
              subtotal={0}
              subtype={
                conversionsCard.pixConversionsVariations >= 0
                  ? 'positive'
                  : 'negative'
              }
              type="percentage"
              src="/assets/icons/payments/pix.svg"
              sx={{ height: '101.33px' }}
              isVisibleValue={visibility}
            />

            <AppWidgetSummary
              title="Reembolso"
              total={conversionsCard.refundedRate}
              increasing={conversionsCard.refundedRateVariations}
              isSubtype={false}
              subtotal={0}
              subtype={
                conversionsCard.refundedRateVariations <= 0
                  ? 'positive'
                  : 'negative'
              }
              type="int"
              src="/assets/icons/payments/pay.svg"
              sx={{ height: '101.33px' }}
              isVisibleValue={visibility}
            />
          </Stack>
        </Grid>

        <Grid xs={12} md={6} lg={4}>
          <AppTopSellingProducts
            title="Produtos mais vendidos"
            isVisibleValue={visibility}
            selected={selectedTopSellingProducts}
            setSelected={setSelectedTopSellingProducts}
            chart={{
              series: bestSellingProductsSeries(),
            }}
          />
        </Grid>

        <Grid xs={12} md={6} lg={4}>
          <AppTopSellingProductTypes
            title="Tipos de produtos mais vendidos"
            isVisibleValue={visibility}
            selected={selectedTopSellingProductTypes}
            setSelected={setSelectedTopSellingProductTypes}
            chart={{
              series: bestSellingProductTypes.map((bspt) => ({
                label: bspt.name,
                value: bspt.value,
                percentage: bspt.percentage,
              })),
            }}
          />
        </Grid>

        <Grid xs={12} md={6} lg={4}>
          <AppPaymentMethods
            title="Formas de Pagamento"
            isVisibleValue={visibility}
            selected={selectedPaymentMethods}
            setSelected={setSelectedPaymentMethods}
            chart={{
              series: salesPaymentMethodSeries(),
            }}
          />
        </Grid>

        <Grid xs={12} md={6} lg={4}>
          <SaleByStatePage
            title="Vendas por Estado"
            isVisibleValue={visibility}
            selected={selectedTopSellingStates}
            setSelected={setSelectedTopSellingStates}
            chartLinearProgres={{
              series: salesForStates.map((sfs) => ({
                label: sfs.name,
                value: sfs.value,
                total: salesForStates
                  .map((x) => x.value)
                  .reduce((prev, curr) => prev + curr),
              })),
            }}
          />
        </Grid>

        <Grid xs={12} md={6} lg={4}>
          <SaleByCityPage
            title="Vendas por Cidade"
            isVisibleValue={visibility}
            selected={selectedTopSellingCity}
            setSelected={setSelectedTopSellingCity}
            chartLinearProgres={{
              series: salesForCities.map((sfs) => ({
                label: sfs.name,
                value: sfs.value,
                total: salesForCities
                  .map((x) => x.value)
                  .reduce((prev, curr) => prev + curr),
              })),
            }}
          />
        </Grid>
      </Grid>
    </Container>
  )
}
