import { TAffiliatedStatus } from '../types/Affiliated'
import { TProdutAffiliatesCategory } from '../types/ProductAffiliates'

const productAffiliatesCategories = [
  TProdutAffiliatesCategory.HealthAndSports,
  TProdutAffiliatesCategory.FinanceAndInvestments,
  TProdutAffiliatesCategory.Relationships,
  TProdutAffiliatesCategory.BusinessAndCareer,
  TProdutAffiliatesCategory.Spirituality,
  TProdutAffiliatesCategory.Sexuality,
  TProdutAffiliatesCategory.Entertainment,
  TProdutAffiliatesCategory.CulinaryAndGastronomy,
  TProdutAffiliatesCategory.Languages,
  TProdutAffiliatesCategory.Law,
  TProdutAffiliatesCategory.AppsSoftware,
  TProdutAffiliatesCategory.Literature,
  TProdutAffiliatesCategory.HomeAndConstruction,
  TProdutAffiliatesCategory.PersonalDevelopment,
  TProdutAffiliatesCategory.FashionAndBeauty,
  TProdutAffiliatesCategory.AnimalsAndPlants,
  TProdutAffiliatesCategory.Educational,
  TProdutAffiliatesCategory.hobbies,
  TProdutAffiliatesCategory.Internet,
  TProdutAffiliatesCategory.EcologyAndEnvironment,
  TProdutAffiliatesCategory.MusicAndArts,
  TProdutAffiliatesCategory.InformationTechnology,
  TProdutAffiliatesCategory.DigitalEntrepreneurship,
  TProdutAffiliatesCategory.Others,
]

const productAffiliatesCategoriesFilter = [
  TProdutAffiliatesCategory.All,
  ...productAffiliatesCategories,
]

const descriptionProductAffiliatesCategory = (
  value?: TProdutAffiliatesCategory
) => {
  switch (value) {
    case TProdutAffiliatesCategory.All:
      return 'Todos'
    case TProdutAffiliatesCategory.HealthAndSports:
      return 'Saúde e Esportes'
    case TProdutAffiliatesCategory.FinanceAndInvestments:
      return 'Finanças e Investimentos'
    case TProdutAffiliatesCategory.Relationships:
      return 'Relacionamentos'
    case TProdutAffiliatesCategory.BusinessAndCareer:
      return 'Negócios e Carreira'
    case TProdutAffiliatesCategory.Spirituality:
      return 'Espiritualidade'
    case TProdutAffiliatesCategory.Sexuality:
      return 'Sexualidade'
    case TProdutAffiliatesCategory.Entertainment:
      return 'Entretenimento'
    case TProdutAffiliatesCategory.CulinaryAndGastronomy:
      return 'Culinária e Gastronomia'
    case TProdutAffiliatesCategory.Languages:
      return 'Idiomas'
    case TProdutAffiliatesCategory.Law:
      return 'Direito'
    case TProdutAffiliatesCategory.AppsSoftware:
      return 'Apps & Software'
    case TProdutAffiliatesCategory.Literature:
      return 'Literatura'
    case TProdutAffiliatesCategory.HomeAndConstruction:
      return 'Casa e Construção'
    case TProdutAffiliatesCategory.PersonalDevelopment:
      return 'Desenvolvimento Pessoal'
    case TProdutAffiliatesCategory.FashionAndBeauty:
      return 'Moda e Beleza'
    case TProdutAffiliatesCategory.AnimalsAndPlants:
      return 'Animais e Plantas'
    case TProdutAffiliatesCategory.Educational:
      return 'Educacional'
    case TProdutAffiliatesCategory.hobbies:
      return 'Hobbies'
    case TProdutAffiliatesCategory.Internet:
      return 'Internet'
    case TProdutAffiliatesCategory.EcologyAndEnvironment:
      return 'Ecologia e Meio Ambiente'
    case TProdutAffiliatesCategory.MusicAndArts:
      return 'Música e Artes'
    case TProdutAffiliatesCategory.InformationTechnology:
      return 'Tecnologia da Informação'
    case TProdutAffiliatesCategory.DigitalEntrepreneurship:
      return 'Empreendedorismo Digital'
    case TProdutAffiliatesCategory.Others:
      return 'Outros'
    default:
      return ''
  }
}

const descriptionAffiliatedStatus = (value?: TAffiliatedStatus) => {
  switch (value) {
    case TAffiliatedStatus.Accepted:
      return 'Aceito'
    case TAffiliatedStatus.Pending:
      return 'Pendente'
    case TAffiliatedStatus.Refused:
      return 'Recusado'
    case TAffiliatedStatus.Blocked:
      return 'Bloqueado'
    default:
      return ''
  }
}

const affiliatedStatusMap: Record<
  TAffiliatedStatus,
  { label: string; backgroundColor: string; color: string }
> = {
  [TAffiliatedStatus.Accepted]: {
    label: 'Aceito',
    backgroundColor: 'rgba(28, 189, 63, 0.16)',
    color: 'rgba(0, 157, 35, 1)',
  },
  [TAffiliatedStatus.Blocked]: {
    label: 'Bloqueado',
    backgroundColor: '#BD1C1C29',
    color: '#D4362C',
  },
  [TAffiliatedStatus.Refused]: {
    label: 'Recusado',
    backgroundColor: '#BD1C1C29',
    color: '#D4362C',
  },
  [TAffiliatedStatus.Pending]: {
    label: 'Pendente',
    backgroundColor: '#FBF3E2',
    color: '#887100',
  },
  [TAffiliatedStatus.All]: {
    label: 'Todos',
    backgroundColor: '#FBF3E2',
    color: '#887100',
  },
}

const getStatusMap = (status?: TAffiliatedStatus) => {
  return affiliatedStatusMap[status ?? TAffiliatedStatus.Pending]
}

const AffiliatesFunction = {
  productAffiliatesCategories,
  productAffiliatesCategoriesFilter,
  affiliatedStatusMap,
  descriptionProductAffiliatesCategory,
  descriptionAffiliatedStatus,
  getStatusMap,
}

export default AffiliatesFunction
