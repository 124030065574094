import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Typography,
} from '@mui/material'
import SwipeableDrawerRightDialog from '../../../../../components/Dialogs/SwipeableDrawerRightDialog'
import {
  TIndicatedComissionLevelEnum,
  TIndicatedTenantItem,
} from '../../../../../core/types/IndicatedTenant'
import IndicatedFunctions from '../../../../../core/functions/IndicatedFunctions'
import BasicDatePicker from '../../../../../components/DateTime/BasicDatePicker'
import { maskPercentage } from '../../../../../masks/masks'
import { HtmlTooltip } from '../../../../../components/Tooltip/HtmlTooltip'
import InfoIcon from '@mui/icons-material/InfoRounded'
import { red } from '@mui/material/colors'

interface UserSettingIndicatedProps {
  currentIndicated: TIndicatedTenantItem | null
  sending: boolean
  errorCurrentIndicatedValue: string
  setCurrentIndicated: (value: TIndicatedTenantItem | null) => void
  setErrorIndicatedValue: (value: string) => void
  handleCloseIndicated: () => void
  handleOpenIndicated: (value: boolean) => void
  handleSaveIndicated: () => Promise<void>
}

const UserSettingIndicated = ({
  currentIndicated,
  sending,
  errorCurrentIndicatedValue,
  setCurrentIndicated,
  setErrorIndicatedValue,
  handleCloseIndicated,
  handleOpenIndicated,
  handleSaveIndicated,
}: UserSettingIndicatedProps) => {
  return (
    <SwipeableDrawerRightDialog
      open={currentIndicated !== null}
      onClose={handleCloseIndicated}
      setOpen={handleOpenIndicated}
      title="Editar configuração do indicado"
      subtitle={`${currentIndicated?.name} (${currentIndicated?.email})`}
      buttons={[
        {
          title: 'Cancelar',
          onClick: handleCloseIndicated,
          type: 'negative',
        },
        { title: 'Salvar', onClick: handleSaveIndicated, type: 'positive' },
      ]}
      buttonsDisabled={sending}
    >
      <Box sx={{ width: '100%', pt: 3, px: 3 }}>
        <Stack direction="row" spacing={2} width="100%">
          <FormControl fullWidth size="small">
            <InputLabel id="days-select-label">Nível</InputLabel>
            <Select
              variant="outlined"
              labelId="days-select-label"
              label="Nível"
              value={
                currentIndicated?.indicatedComissionLevelEnum ??
                TIndicatedComissionLevelEnum.None
              }
              onChange={(e) => {
                const indicatedComissionLevelEnum = e.target
                  .value as TIndicatedComissionLevelEnum
                const indicatedComissionsPercentage =
                  indicatedComissionLevelEnum ===
                  TIndicatedComissionLevelEnum.Basic
                    ? 5
                    : indicatedComissionLevelEnum ===
                        TIndicatedComissionLevelEnum.Gold
                      ? 10
                      : indicatedComissionLevelEnum ===
                          TIndicatedComissionLevelEnum.Platinum
                        ? 15
                        : 0
                setCurrentIndicated({
                  ...currentIndicated!,
                  indicatedComissionLevelEnum,
                  indicatedComissionsPercentage,
                })
              }}
            >
              {IndicatedFunctions.indicatedComissionLevels.map((level) => (
                <MenuItem value={level}>
                  {IndicatedFunctions.getIndicatedComissionLevelString(level)}
                </MenuItem>
              ))}
            </Select>
            <Stack marginTop={'10px'}>
              <BasicDatePicker
                value={currentIndicated?.receiveComissionUntilDateOf}
                setValue={(value) =>
                  setCurrentIndicated({
                    ...currentIndicated!,
                    receiveComissionUntilDateOf: value ?? new Date(),
                  })
                }
              />
            </Stack>
          </FormControl>
          <Box width="100%">
            <OutlinedInput
              fullWidth
              size="small"
              type="text"
              value={maskPercentage(
                currentIndicated?.indicatedComissionsPercentage ?? 0
              )}
              onChange={(e) => {
                setErrorIndicatedValue('')
                const value = maskPercentage(e.target.value)
                  .replaceAll('.', '')
                  .replaceAll(',', '.')
                setCurrentIndicated({
                  ...currentIndicated!,
                  indicatedComissionsPercentage: Number(value),
                })
              }}
              endAdornment={
                <HtmlTooltip
                  title={
                    <Typography>
                      Percentual a receber de comissão para cada indicado
                      cadastrado. Podendo ser de 0% até 30%.
                    </Typography>
                  }
                >
                  <InfoIcon fontSize="small" sx={{ color: 'grey' }} />
                </HtmlTooltip>
              }
              error={errorCurrentIndicatedValue !== ''}
            />

            {errorCurrentIndicatedValue !== '' && (
              <Typography variant="caption" color={red[700]}>
                {errorCurrentIndicatedValue}
              </Typography>
            )}
          </Box>
        </Stack>
      </Box>
    </SwipeableDrawerRightDialog>
  )
}

export default UserSettingIndicated
