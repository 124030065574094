import { Box, CircularProgress, Tab, Tabs } from '@mui/material'
import SwipeableDrawerRightDialog from '../../../../components/Dialogs/SwipeableDrawerRightDialog'
import { maskPercentage } from '../../../../masks/masks'
import React, { useCallback, useEffect, useRef } from 'react'
import { TIndicatedTenantItem } from '../../../../core/types/IndicatedTenant'
import IndicatedController from '../../../../core/controllers/IndicatedController'
import Hosts from '../../../../core/functions/hosts'
import { useTenantSettingsByTenantId } from '../../../../core/hooks/useTenantSettingsByTenantId'
import { a11yProps, TabPanel } from '../../../../components/Tabs'
import { TDataTablePagination } from '../../../../components/Tables/DataTable'
import UserSettingGeneral from './Tabs/UserSettingGeneral'
import UserSettingValues from './Tabs/UserSettingValues'
import UserSettingIndicated from './UserSettingIndicated'
import MyProducts from '../../../Products/MyProducts'
import { UserSettingProps } from './UserSettingTypes'
import UserSettingWidgets from './UserSettingColumns'
import UserSettingIndicateds from './Tabs/UserSettungIndicateds'
import UserSettingFunctions from './UserSettingFunctions'
import UserSettingAffiliateds from './Tabs/UserSettingAffiliateds'
import NumberFunctions from '../../../../core/functions/NumberFunctions'
import TenantFunctions from '../../../../core/functions/TenantFunctions'
import {
  USER_SETTING_TAB_AFFILIATEDS,
  USER_SETTING_TAB_GENERAL,
  USER_SETTING_TAB_INDICATEDS,
  USER_SETTING_TAB_PRODUCTS,
  USER_SETTING_TAB_VALUES,
} from './UserSettingConsts'

const UserSetting = ({
  open,
  sending,
  tenantSettings,
  handleClose,
  handleSave,
  setOpen,
  setTenantSettings,
  setSending,
  setSuccess,
  setError,
  setMessage,
  executeUserSettings,
}: UserSettingProps) => {
  const [errorTenant, setErrorTenant] = React.useState('')
  const [errorImmediateWithdrawal, setErrorImmediateWithdrawal] =
    React.useState('')
  const [errorPlatformFee, setErrorPlatformFee] = React.useState('')
  const [errorAnticipationD15Fee, setErrorAnticipationD15Fee] =
    React.useState('')
  const [errorAnticipationD2Fee, setErrorAnticipationD2Fee] = React.useState('')
  const [errorIndicatedValue, setErrorIndicatedValue] = React.useState('')
  const [errorCurrentIndicatedValue, setErrorCurrentIndicatedValue] =
    React.useState('')
  const [errorAffiliateFee, setErrorAffiliateFee] = React.useState('')

  const [loading, setLoading] = React.useState(false)

  const [tabIndex, setTabIndex] = React.useState(0)

  const [indicateds, setIndicateds] = React.useState<TIndicatedTenantItem[]>([])
  const [currentIndicated, setCurrentIndicated] =
    React.useState<TIndicatedTenantItem | null>(null)

  const id = useRef('')

  const recipientUrl = TenantFunctions.getTenantRecipientUrl(
    tenantSettings.recipientId
  )

  const dataPagination: TDataTablePagination<TIndicatedTenantItem> = {
    items: indicateds,
    page: 0,
    rowsPerPage: 0,
    total: 0,
    totalPages: 0,
  }

  const { data: settings, loading: loadingSettings } =
    useTenantSettingsByTenantId({
      id: tenantSettings.tenantId,
      requestRecipient: true,
    })

  const getIndicatedList = useCallback(
    async (id: string) => {
      UserSettingFunctions.getIndicatedList({
        id,
        setLoading,
        setError,
        setIndicateds,
      })
    },
    [setLoading, setError, setIndicateds]
  )

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue)
    if (newValue === 1) {
      getIndicatedList(tenantSettings.tenantId)
    }
  }

  const handleChangeSave = () => {
    var error = false

    if (tenantSettings.tenantId === '') {
      setErrorTenant('Campo obrigatório')
      error = true
    }

    const immediateWithdrawal = maskPercentage(
      tenantSettings.immediateWithdrawal
    )
      .replaceAll('.', '')
      .replaceAll(',', '.')
    tenantSettings.immediateWithdrawal = Number(immediateWithdrawal)
    if (tenantSettings.immediateWithdrawal > 90) {
      setTabIndex(USER_SETTING_TAB_VALUES)
      setErrorImmediateWithdrawal('O valor precisa ser no máximo 90%')
      error = true
    }

    if (tenantSettings.enableAnticipationD15) {
      const anticipationD15Fee = tenantSettings.anticipationD15Fee ?? 0
      if (anticipationD15Fee <= 0 || anticipationD15Fee > 5) {
        setTabIndex(USER_SETTING_TAB_VALUES)
        setErrorAnticipationD15Fee(
          'O valor precisa ser maior que zero (0%) e menor que cinco (5%)'
        )
        error = true
      }
    }

    if (tenantSettings.enableAnticipationD2) {
      const anticipationD2Fee = tenantSettings.anticipationD2Fee ?? 0
      if (anticipationD2Fee <= 0 || anticipationD2Fee > 5) {
        setTabIndex(USER_SETTING_TAB_VALUES)
        setErrorAnticipationD2Fee(
          'O valor precisa ser maior que zero (0%) e menor que cinco (5%)'
        )
        error = true
      }
    }

    const indicatedComissionsRateValue = maskPercentage(
      tenantSettings.indicatedComissionsRateValue ?? '0'
    )
      .replaceAll('.', '')
      .replaceAll(',', '.')
    tenantSettings.indicatedComissionsRateValue = Number(
      indicatedComissionsRateValue
    )
    if (tenantSettings.indicatedComissionsRateValue > 30) {
      if (tenantSettings.disableIndicatedComissions) {
        tenantSettings.indicatedComissionsRateValue = 0
      } else {
        setTabIndex(USER_SETTING_TAB_INDICATEDS)
        setErrorIndicatedValue('O valor precisa ser no máximo 30%')
        error = true
      }
    }

    if (tenantSettings.enableAffiliateFees) {
      const affiliateFees = maskPercentage(tenantSettings.affiliateFees ?? '0')
        .replaceAll('.', '')
        .replaceAll(',', '.')
      tenantSettings.affiliateFees = Number(affiliateFees)

      if (tenantSettings.affiliateFees <= 0) {
        setErrorAffiliateFee('O valor precisa ser maior que zero (0%)')
        setTabIndex(USER_SETTING_TAB_AFFILIATEDS)
        error = true
      }
      if (tenantSettings.affiliateFees > 90) {
        setErrorAffiliateFee('O valor precisa ser no máximo 90%')
        setTabIndex(USER_SETTING_TAB_AFFILIATEDS)
        error = true
      }
    }

    if (error) {
      return
    }

    handleSave()
  }

  const handleEnableOrDisableIndicatedComission = async (
    item: TIndicatedTenantItem
  ) => {
    UserSettingFunctions.enableOrDisableIndicated({
      data: {
        indicationOfId: tenantSettings.tenantId,
        tenantId: item.tenantId,
        value: !item.isEnableCommissions,
      },
      setSending,
      setError,
      setSuccess,
      setLoading,
      setIndicateds,
    })
  }

  const handleCloseIndicated = () => {
    setErrorCurrentIndicatedValue('')
    setCurrentIndicated(null)
  }

  const handleOpenIndicated = (value: boolean) => {
    setErrorCurrentIndicatedValue('')
    if (!value) {
      handleCloseIndicated()
    }
  }

  const handleSaveIndicated = async () => {
    setErrorCurrentIndicatedValue('')
    if (!currentIndicated) {
      setError('Nenhum indicado selecionado')
      return
    }

    const indicatedComissionsPercentage = maskPercentage(
      currentIndicated.indicatedComissionsPercentage ?? '0'
    )
      .replaceAll('.', '')
      .replaceAll(',', '.')
    currentIndicated.indicatedComissionsPercentage = Number(
      indicatedComissionsPercentage
    )
    if (currentIndicated.indicatedComissionsPercentage > 30) {
      if (!currentIndicated.isEnableCommissions) {
        currentIndicated.indicatedComissionsPercentage = 0
      } else {
        setErrorCurrentIndicatedValue('O valor precisa ser no máximo 30%')
        return
      }
    }

    setSending(true)
    try {
      let response = await IndicatedController.updateIndicatedTenant({
        data: {
          tenantId: currentIndicated.tenantId,
          comissionsPercentage: currentIndicated.indicatedComissionsPercentage,
          indicatedComissionLevelEnum:
            currentIndicated.indicatedComissionLevelEnum,
          receiveComissionUntilDateOf:
            currentIndicated.receiveComissionUntilDateOf,
        },
      })
      if (!response.success) {
        setError(response.error)
      } else {
        setSuccess(true)
        handleCloseIndicated()
        getIndicatedList(tenantSettings.tenantId)
      }
    } finally {
      setSending(false)
    }
  }

  const handleChangeCopyRecipientId = async () => {
    await Hosts.copyTextToClipboard(tenantSettings.recipientId ?? '')
    setMessage('Código do recebedor copiado')
  }

  const handleOpenRecipient = () => {
    Hosts.openNewBlank(recipientUrl)
  }

  const handleChangeImmediateWithdrawal = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setErrorImmediateWithdrawal('')
    const immediateWithdrawal = NumberFunctions.maskPercentageToNumber(
      e.target.value
    )
    setTenantSettings({ ...tenantSettings, immediateWithdrawal })
  }

  const handleChangePlataformFee = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setErrorImmediateWithdrawal('')
    const plataformFee = NumberFunctions.maskPercentageToNumber(e.target.value)
    setTenantSettings({ ...tenantSettings, plataformFee })
  }

  const handleChangeIndicatedComissionsRateValue = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setErrorIndicatedValue('')
    const indicatedComissionsRateValue = NumberFunctions.maskPercentageToNumber(
      e.target.value
    )
    setTenantSettings({ ...tenantSettings, indicatedComissionsRateValue })
  }

  const handleChangeSalesEnabled = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    UserSettingFunctions.setSalesEnabled({
      salesEnabled: e.target.checked,
      tenantSettings,
      setSending,
      setError,
      setSuccess,
      setTenantSettings,
      executeUserSettings,
    })
  }

  const handleChangeWithdrawlsEnabled = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    UserSettingFunctions.setWithdrawlsEnabled({
      withdrawlsEnabled: e.target.checked,
      tenantSettings,
      setSending,
      setError,
      setSuccess,
      setTenantSettings,
      executeUserSettings,
    })
  }

  const handleChangeEnableRegisterProducts = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    UserSettingFunctions.enableRegisterProducts({
      enabled: e.target.checked,
      tenantSettings,
      setSending,
      setError,
      setSuccess,
      setTenantSettings,
      executeUserSettings,
    })
  }

  const handleChangeClose = () => {
    setTabIndex(0)
    handleClose()
  }

  const columns = UserSettingWidgets.getUserSettingColumns({
    handleEnableOrDisableIndicatedComission,
    setCurrentIndicated,
  })

  useEffect(() => {
    if (tenantSettings.id !== id.current) {
      id.current = tenantSettings.id
      setTabIndex(USER_SETTING_TAB_GENERAL)
    }
  }, [tenantSettings])

  return (
    <SwipeableDrawerRightDialog
      open={open}
      onClose={handleChangeClose}
      setOpen={setOpen}
      title="Configurações do usuário"
      subtitle={`${tenantSettings.tenantName} (${tenantSettings.tenantEmail})`}
      buttons={[
        { title: 'Cancelar', onClick: handleChangeClose, type: 'negative' },
        { title: 'Salvar', onClick: handleChangeSave, type: 'positive' },
      ]}
      buttonsDisabled={sending}
    >
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={tabIndex}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Geral" {...a11yProps(USER_SETTING_TAB_GENERAL)} />
            <Tab label="Valores" {...a11yProps(USER_SETTING_TAB_VALUES)} />
            <Tab
              label="Indicados"
              {...a11yProps(USER_SETTING_TAB_INDICATEDS)}
            />
            <Tab label="Produtos" {...a11yProps(USER_SETTING_TAB_PRODUCTS)} />
            <Tab
              label="Afiliados"
              {...a11yProps(USER_SETTING_TAB_AFFILIATEDS)}
            />
          </Tabs>
        </Box>
        <TabPanel value={tabIndex} index={USER_SETTING_TAB_GENERAL}>
          <UserSettingGeneral
            tenantSettings={tenantSettings}
            settings={settings}
            loadingSettings={loadingSettings}
            handleChangeCopyRecipientId={handleChangeCopyRecipientId}
            handleOpenRecipient={handleOpenRecipient}
            handleChangeSalesEnabled={handleChangeSalesEnabled}
            handleChangeWithdrawlsEnabled={handleChangeWithdrawlsEnabled}
            handleChangeEnableRegisterProducts={
              handleChangeEnableRegisterProducts
            }
          />
        </TabPanel>
        <TabPanel value={tabIndex} index={USER_SETTING_TAB_VALUES}>
          <UserSettingValues
            tenantSettings={tenantSettings}
            errorAnticipationD15Fee={errorAnticipationD15Fee}
            errorAnticipationD2Fee={errorAnticipationD2Fee}
            errorImmediateWithdrawal={errorImmediateWithdrawal}
            errorIndicatedValue={errorIndicatedValue}
            errorPlatformFee={errorPlatformFee}
            setTenantSettings={setTenantSettings}
            setErrorAnticipationD2Fee={setErrorAnticipationD2Fee}
            setErrorAnticipationD15Fee={setErrorAnticipationD15Fee}
            handleChangeImmediateWithdrawal={handleChangeImmediateWithdrawal}
            handleChangeIndicatedComissionsRateValue={
              handleChangeIndicatedComissionsRateValue
            }
            handleChangePlataformFee={handleChangePlataformFee}
          />
        </TabPanel>
        <TabPanel value={tabIndex} index={USER_SETTING_TAB_INDICATEDS}>
          {loading && <CircularProgress />}
          {!loading && (
            <UserSettingIndicateds
              indicateds={indicateds}
              dataPagination={dataPagination}
              columns={columns}
            />
          )}
        </TabPanel>
        <TabPanel
          value={tabIndex}
          index={USER_SETTING_TAB_PRODUCTS}
          sx={{ pt: 2 }}
        >
          <MyProducts tenantId={tenantSettings.tenantId} />
        </TabPanel>
        <TabPanel value={tabIndex} index={USER_SETTING_TAB_AFFILIATEDS}>
          <UserSettingAffiliateds
            tenantSettings={tenantSettings}
            errorAffiliateFee={errorAffiliateFee}
            setTenantSettings={setTenantSettings}
            setErrorAffiliateFee={setErrorAffiliateFee}
          />
        </TabPanel>

        <UserSettingIndicated
          currentIndicated={currentIndicated}
          errorCurrentIndicatedValue={errorCurrentIndicatedValue}
          sending={sending}
          setCurrentIndicated={setCurrentIndicated}
          setErrorIndicatedValue={setErrorIndicatedValue}
          handleCloseIndicated={handleCloseIndicated}
          handleOpenIndicated={handleOpenIndicated}
          handleSaveIndicated={handleSaveIndicated}
        />
      </Box>
    </SwipeableDrawerRightDialog>
  )
}

export default UserSetting
