import * as React from 'react'
import {
  Box,
  Typography,
  Backdrop,
  CircularProgress,
  Stack,
  IconButton,
  Button,
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useSalesFunnel } from '../../../core/hooks/useSalesFunnel'
import ConfirmDialog from '../../../components/Dialogs/ConfirmDialog'
import SalesFunnelController from '../../../core/controllers/SalesFunnelController'
import SuccessSnackbar from '../../../components/Snackbar/SuccessSnackbar'
import { TSalesFunnel } from '../../../core/types/SalesFunnel'
import DataTable, {
  IDataTableColumn,
} from '../../../components/Tables/DataTable'
import { HtmlTooltip } from '../../../components/Tooltip/HtmlTooltip'
import TitlePageTypography from '../../../components/Typographys/TitlePageTypography'
import DataTableHeader from '../../../components/Tables/DataTableHeader'
import AlertDefault from '../../../components/Alerts'
import { IconDelete, IconEdit } from '../../../core/constants/IconConsts'

export default function SalesFunnelPage() {
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)

  const [search, setSearch] = React.useState('')
  const [deleteId, setDeleteId] = React.useState('')
  const [errorSending, setErrorSending] = React.useState('')

  const [sending, setSending] = React.useState(false)
  const [success, setSuccess] = React.useState(false)

  const oldSearch = React.useRef('')

  const { loading, data, error, execute, clear } = useSalesFunnel({
    page,
    rows: rowsPerPage,
    search: search,
  })

  const columns: readonly IDataTableColumn<TSalesFunnel>[] = [
    { id: 'name', label: 'Nome', minWidth: 80 },
    { id: 'productName', label: 'Produto', minWidth: 80 },
    {
      id: 'action',
      label: 'Ações',
      minWidth: 100,
      render(id, value, row) {
        return (
          <Stack direction="row" spacing={1}>
            <HtmlTooltip title="Editar funil">
              <IconButton
                size="small"
                onClick={() => navigate(`/sales/funnel/${row.id}`)}
              >
                <img src={IconEdit} alt="edit" />
              </IconButton>
            </HtmlTooltip>
            <HtmlTooltip title="Editar etapas do funil">
              <IconButton
                size="small"
                onClick={() => navigate(`/sales/funnel/${row.id}/steps`)}
              >
                <img
                  src="/assets/icons/menu/funnel/funnelBlack.svg"
                  alt="step"
                />
              </IconButton>
            </HtmlTooltip>
            <HtmlTooltip
              title={`${row.disabled ? 'Habilitar' : 'Desabilitar'} funil`}
            >
              <IconButton
                size="small"
                onClick={() => handleChangeEnableDisableSalesFunnel(row)}
              >
                <img
                  src={`/assets/icons/actions/${row.disabled ? 'checked' : 'cancel'}.svg`}
                  alt="action"
                />
              </IconButton>
            </HtmlTooltip>
            <HtmlTooltip title="Excluir funil">
              <IconButton size="small" onClick={() => setDeleteId(row.id)}>
                <img src={IconDelete} alt="delete" />
              </IconButton>
            </HtmlTooltip>
          </Stack>
        )
      },
    },
  ]

  React.useEffect(() => {
    if (data.rowsPerPage !== rowsPerPage) {
      setRowsPerPage(data.rowsPerPage)
    }

    if (oldSearch.current !== search) {
      oldSearch.current = search
      setPage(0)
    }
  }, [data])

  const navigate = useNavigate()

  const handleChangePage = (newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (rows: number) => {
    setRowsPerPage(rows)
    setPage(0)
  }

  const deleteSalesFunnel = async () => {
    if (deleteId === '') {
      return
    }

    setSending(true)
    try {
      const response = await SalesFunnelController.remove({ id: deleteId })
      if (!response.success) {
        setErrorSending(response.error)
        return
      }

      setSuccess(true)
      execute()
    } finally {
      setSending(false)
    }
  }

  const handleChangeEnableDisableSalesFunnel = async (
    salesFunnel: TSalesFunnel
  ) => {
    setSending(true)
    try {
      var response

      if (salesFunnel.disabled) {
        response = await SalesFunnelController.enable({ id: salesFunnel.id })
      } else {
        response = await SalesFunnelController.disable({ id: salesFunnel.id })
      }
      if (!response.success) {
        setErrorSending(response.error)
        return
      }

      setSuccess(true)
      execute()
    } finally {
      setSending(false)
    }
  }

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          p: 2,
        }}
      >
        <TitlePageTypography>Funil de Vendas</TitlePageTypography>

        <Stack direction={{ sx: 'column', sm: 'row' }} spacing={1}>
          <DataTableHeader
            totalItems={data.total}
            setSearch={setSearch}
            executeSearch={execute}
            rowsPerPage={rowsPerPage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />

          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate('/sales/funnel/new')}
            sx={{
              borderRadius: '4px',
              padding: '8px 16px',
              gap: '10px',
              textDecoration: 'none',
            }}
          >
            <Typography
              sx={{
                fontFamily: 'Montserrat',
                fontSize: '14px',
                lineHeight: '24px',
                fontWeight: 700,
              }}
            >
              Novo Funil de Venda
            </Typography>
          </Button>
        </Stack>
      </Box>

      {error !== '' && (
        <Box sx={{ mb: 2 }}>
          <AlertDefault onClose={clear} severity="error" sx={{ width: '100%' }}>
            {error}
          </AlertDefault>
        </Box>
      )}

      <DataTable
        columns={columns}
        data={data}
        page={page}
        rowsPerPage={rowsPerPage}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        hasNewCustomHeader
      />

      <ConfirmDialog
        open={deleteId !== ''}
        onClose={() => setDeleteId('')}
        message="Deseja realmente deleterar o funil de venda ?"
        onYes={deleteSalesFunnel}
      ></ConfirmDialog>
      <SuccessSnackbar open={success} onClose={() => setSuccess(false)}>
        Concluído com sucesso
      </SuccessSnackbar>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  )
}
