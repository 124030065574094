import TagFunctions from './TagFunctions'

const TAG =
  'window.$crisp=[];window.CRISP_WEBSITE_ID="3d8c67ad-777f-41fe-890b-c151c8029f81";(function(){d=document;s=d.createElement("script");s.src="https://client.crisp.chat/l.js";s.async=1;d.getElementsByTagName("head")[0].appendChild(s);})();'

const addTagDash = () => {
  const tag = TagFunctions.addScriptHeader(TAG, 'text/javascript')
  return [tag]
}

const CrispFunctions = {
  addTagDash,
}

export default CrispFunctions
