import {
  Alert,
  Button,
  Checkbox,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material'
import { Box, useMediaQuery } from '@mui/system'
import { useContext, useEffect, useState } from 'react'
import {
  Control,
  UseFormSetValue,
  UseFormWatch,
  useFieldArray,
} from 'react-hook-form'
import { TProduct } from '../../../../core/types/Product'
import ViewQuiltIcon from '@mui/icons-material/ViewQuiltRounded'
import ConfirmDialog from '../../../../components/Dialogs/ConfirmDialog'
import SuccessSnackbar from '../../../../components/Snackbar/SuccessSnackbar'
import { TCustomCheckout } from '../../../../core/types/CustomCheckout'
import CustomCheckoutController from '../../../../core/controllers/CustomCheckoutController'
import { useCustomCheckoutsByProduct } from '../../../../core/hooks/useCustomCheckoutsByProduct'
import ProductCustomCheckoutRegister from '../../../../components/Product/CustomCheckout/ProductCustomCheckoutRegister'
import Hosts from '../../../../core/functions/hosts'
import LoadingBackdrop from '../../../../components/Loading/LoadingBackdrop'
import { TUserType } from '../../../../core/types/Auth'
import { AuthContext } from '../../../../core/context/AuthContext'
import ListItemTitleTypography from '../../../../components/Typographys/ListItemTitleTypography'
import ListItemData from '../../../../components/ListItem/ListItemData'
import { IconDelete, IconEdit } from '../../../../core/constants/IconConsts'
export interface IProductCustomCheckoutProp {
  control: Control<TProduct, any>
  watch: UseFormWatch<TProduct>
  setValue: UseFormSetValue<TProduct>
  setError: (error: string) => void
}

const customCheckoutDefault: TCustomCheckout = {
  id: '',
  name: '',
  productId: '',
  productName: '',
  prices: [],
  defaultValue: false,
}

const ProductCustomsCheckout = ({
  control,
  watch,
  setValue,
  setError,
}: IProductCustomCheckoutProp): JSX.Element => {
  const [open, setOpen] = useState(false)
  const [current, setCurrent] = useState<TCustomCheckout>({
    ...customCheckoutDefault,
  })
  const [openConfirmDelete, setOpenConfirmeDelete] = useState(false)
  const [sending, setSending] = useState(false)
  const [success, setSuccess] = useState(false)
  const [errorName, setErrorName] = useState('')
  const [errorPrices, setErrorPrices] = useState('')
  const [deleteId, setDeleteId] = useState('')

  const { user } = useContext(AuthContext)
  const isAdmin = user?.UserType === TUserType.SystemAdmin

  const { register } = control
  const { fields: prices } = useFieldArray({
    control,
    name: `prices`,
  })
  const isSmallScreen = useMediaQuery((theme: any) =>
    theme.breakpoints.down('md')
  )
  const productId = watch('id')

  const { loading, data, error, execute, clear } = useCustomCheckoutsByProduct({
    productId,
  })

  const setCurrentDefault = () => {
    setCurrent({ ...customCheckoutDefault })
  }

  const handleChangeEdit = async (productCustomCheckout?: TCustomCheckout) => {
    productCustomCheckout ??= { ...customCheckoutDefault }
    if (productCustomCheckout.id !== '') {
      setSending(true)
      try {
        const response = await CustomCheckoutController.get({
          id: productCustomCheckout.id,
        })
        if (response.data) {
          productCustomCheckout = response.data
        }
      } finally {
        setSending(false)
      }
    }
    setCurrent(productCustomCheckout)
    setOpen(true)
  }

  const handleChangeClose = () => {
    setCurrentDefault()
    setOpen(false)
  }

  const handleChangeSave = async () => {
    if (errorName !== '') {
      setErrorName('')
    }
    if (errorPrices !== '') {
      setErrorPrices('')
    }

    let isErrors = false
    if (!current.name || current.name === '') {
      setErrorName('Campo obrigatório')
      isErrors = true
    }

    if (!current.defaultValue && current.prices.length <= 0) {
      setErrorPrices('Selecione no mínimo uma oferta')
      isErrors = true
    }

    if (isErrors) {
      return
    }

    current.productId = productId

    setSending(true)
    try {
      var response
      if (current.id === '') {
        response = await CustomCheckoutController.insert({ data: current })
        current.id = response.data
      } else {
        response = await CustomCheckoutController.update({ data: current })
      }

      if (!response.success) {
        setError(response.error)
        return
      }
      setSuccess(true)
      handleChangeClose()
      execute()
      //Hosts.openNewBlankCustomCheckout(current.id)
    } finally {
      setSending(false)
    }
  }

  const handleChangeDelete = (productCustomCheckout: TCustomCheckout) => {
    setDeleteId(productCustomCheckout.id)
    setOpenConfirmeDelete(true)
  }

  const handleDeleteClose = () => {
    setDeleteId('')
    setCurrentDefault()
    setOpenConfirmeDelete(false)
  }

  const deleteCustomCheckout = async () => {
    if (deleteId === '') {
      return
    }

    setSending(true)
    setSuccess(false)
    setError('')

    try {
      const response = await CustomCheckoutController.remove({ id: deleteId })

      if (!response.success) {
        setError(response.error)
      } else {
        setSuccess(true)
        handleDeleteClose()
        execute()
      }
    } finally {
      setSending(false)
    }
  }

  useEffect(() => setError(error), [error])
  return (
    <Box>
      <Stack spacing={2} p={2} sx={{ backgroundColor: '#FFFFFF' }}>
        {prices.length <= 0 && (
          <Alert
            variant="filled"
            severity="warning"
            sx={{ width: '100%', mb: 2 }}
          >
            É necessário cadastrar ofertas para poder criar o checkout
            personalizado
          </Alert>
        )}
        {prices.length > 0 && (
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography
              sx={{
                fontFamily: 'Montserrat',
                fontWeight: 700,
                fontSize: '16px',
                lineHeight: '20px',
                color: '#343948',
              }}
            >
              Checkouts
            </Typography>
            {!isAdmin && !isSmallScreen && (
              <Button
                variant="outlined"
                size="large"
                sx={{
                  color: '#1C52BD',
                  borderColor: '#1C52BD',
                  fontFamily: 'Montserrat',
                  fontWeight: 700,
                  fontSize: '14px',
                  lineHeight: '24px',
                }}
                onClick={() => handleChangeEdit()}
              >
                + Novo Checkout Personalizado
              </Button>
            )}
          </Stack>
        )}
        <Stack direction="column" spacing={1}>
          <Stack
            direction="row"
            spacing={1}
            alignContent="center"
            alignItems="center"
          >
            {!isSmallScreen && (
              <Box sx={{ width: '85%' }}>
                <ListItemTitleTypography>Nome</ListItemTitleTypography>
              </Box>
            )}
            {!isSmallScreen && (
              <Box sx={{ width: '7%' }}>
                <ListItemTitleTypography>Padrão?</ListItemTitleTypography>
              </Box>
            )}
            {!isSmallScreen && (
              <Box sx={{ width: '9%' }}>
                <ListItemTitleTypography>Ações</ListItemTitleTypography>
              </Box>
            )}
          </Stack>

          {data.map((customCheckout, index) => (
            <ListItemData key={`customCheckoutItem${index}`}>
              <Box sx={{ width: '90%' }}>
                <Typography
                  color="#38393B"
                  fontWeight={500}
                  fontSize="14px"
                  lineHeight="18px"
                >
                  {customCheckout.name}
                </Typography>
              </Box>

              {!isSmallScreen && (
                <Box
                  sx={{
                    width: '4%',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Checkbox
                    checked={customCheckout.defaultValue}
                    disabled
                    sx={{ width: '30px', height: '30px' }}
                  />
                </Box>
              )}

              {!isAdmin && (
                <Stack
                  direction="row"
                  spacing={0.5}
                  sx={{ width: '10%' }}
                  alignItems="center"
                  justifyContent="center"
                >
                  <Tooltip title="Editar configurações">
                    <IconButton
                      size="small"
                      onClick={() => handleChangeEdit(customCheckout)}
                    >
                      <img src={IconEdit} alt="edit" />
                    </IconButton>
                  </Tooltip>
                  {!isSmallScreen && (
                    <Tooltip title="Editar layout">
                      <IconButton
                        size="small"
                        onClick={() =>
                          Hosts.openNewBlankCustomCheckout(customCheckout.id)
                        }
                      >
                        <ViewQuiltIcon color="primary" />
                      </IconButton>
                    </Tooltip>
                  )}
                  {!isSmallScreen && (
                    <Tooltip title="Excluir checkout">
                      <IconButton
                        size="small"
                        onClick={() => handleChangeDelete(customCheckout)}
                        color="error"
                      >
                        <img src={IconDelete} alt="delete" />
                      </IconButton>
                    </Tooltip>
                  )}
                  {isAdmin && (
                    <Tooltip title="Ver configurações">
                      <IconButton
                        size="small"
                        onClick={() => handleChangeEdit(customCheckout)}
                      >
                        <img
                          src="/assets/icons/eyes/eyeButtonBlack.svg"
                          alt="edit"
                        />
                      </IconButton>
                    </Tooltip>
                  )}
                </Stack>
              )}
            </ListItemData>
          ))}
          {isSmallScreen && (
            <Button
              variant="outlined"
              size="large"
              sx={{
                color: '#1C52BD',
                borderColor: '#1C52BD',
                fontFamily: 'Montserrat',
                fontWeight: 700,
                fontSize: '14px',
                lineHeight: '24px',
              }}
              onClick={() => handleChangeEdit()}
            >
              + Novo Checkout Personalizado
            </Button>
          )}
        </Stack>
      </Stack>

      <ProductCustomCheckoutRegister
        open={open}
        sending={sending}
        customCheckout={current}
        errorName={errorName}
        errorPrices={errorPrices}
        productPrices={prices}
        checkouts={data}
        watch={watch}
        setOpen={setOpen}
        setCustomCheckout={setCurrent}
        handleChangeSave={handleChangeSave}
      />

      <ConfirmDialog
        open={openConfirmDelete}
        title="Confirmar exclusão"
        message="Deseja realmente deletar o checkout personalizado?"
        onClose={handleDeleteClose}
        onYes={deleteCustomCheckout}
      />

      <LoadingBackdrop open={loading || sending} />

      <SuccessSnackbar open={success} onClose={() => setSuccess(false)}>
        Concluído com sucesso
      </SuccessSnackbar>
    </Box>
  )
}

export default ProductCustomsCheckout
