import { Box, Divider, Skeleton, Stack, Typography } from '@mui/material'
import {
  TCheckoutOrder,
  TCheckoutOrderDetails,
} from '../../../../../core/types/CheckoutOrder'
import ListItemDetail from '../../../../../components/ListItem/ListItemDetail'
import NumberFunctions from '../../../../../core/functions/NumberFunctions'
import { useContext } from 'react'
import { AuthContext } from '../../../../../core/context/AuthContext'
import { TUserType } from '../../../../../core/types/Auth'
import { TProductVoucherValue } from '../../../../../core/types/Voucher'
import ListItemDefault from '../../../../../components/ListItem/ListItemDefault'

export interface IOrderDetailValuesProp {
  checkoutOrder?: TCheckoutOrder
  details?: TCheckoutOrderDetails
  loading: boolean
}

const OrderDetailValues = ({
  checkoutOrder,
  details,
  loading,
}: IOrderDetailValuesProp) => {
  const { user, setUser } = useContext(AuthContext)

  const basePrice = details?.basePrice ?? 0
  const voucherValue = details?.voucherValue ?? 0

  const isVoucher =
    details?.voucherIdentifier !== undefined &&
    details?.voucherIdentifier !== null &&
    details?.voucherIdentifier !== ''

  const isVoucherValue =
    isVoucher &&
    details?.voucherType === TProductVoucherValue.value &&
    checkoutOrder?.mainProduct

  const isVoucherPercentage =
    isVoucher && details?.voucherType === TProductVoucherValue.percentage

  const discount = (basePrice * voucherValue) / 100

  if (loading) {
    return (
      <Stack spacing={2} sx={{ p: 2 }}>
        <Skeleton variant="rounded" width="100%" height="45px" />
        <Skeleton variant="rounded" width="100%" height="45px" />
        <Skeleton variant="rounded" width="100%" height="45px" />
        <Skeleton variant="rounded" width="100%" height="45px" />
        <Skeleton variant="rounded" width="100%" height="45px" />
        <Skeleton variant="rounded" width="100%" height="45px" />
        <Skeleton variant="rounded" width="100%" height="45px" />
        <Skeleton variant="rounded" width="100%" height="45px" />
      </Stack>
    )
  }

  return (
    <Box>
      <ListItemDefault
        title="Preço base do produto"
        value={NumberFunctions.formatMoneyDefault(basePrice)}
      />
      {isVoucherValue && (
        <ListItemDefault
          title="Desconto"
          value={NumberFunctions.formatMoneyDefault(voucherValue)}
        />
      )}
      {isVoucherPercentage && (
        <ListItemDefault
          title="Desconto"
          value={NumberFunctions.formatMoneyDefault(discount)}
        />
      )}
      <ListItemDefault
        title="Taxas"
        value={NumberFunctions.formatMoneyDefault(details?.rates)}
      />
      {(user?.UserType === TUserType.SystemAdmin ||
        user?.TenantId === details?.tenantId) && (
        <ListItemDefault title="" px={0} py={0}>
          <Box width="100%">
            <ListItemDetail
              title="Divisão dos valores"
              value={
                <Stack direction="column" spacing={1}>
                  {details?.recipients?.map((recipient) => (
                    <Box>
                      <Stack
                        display="inline"
                        direction="row"
                        spacing={1}
                        alignContent="center"
                        alignItems="center"
                      >
                        <Typography display="inline" variant="subtitle2">
                          {recipient.name}:
                        </Typography>
                        <Typography display="inline" variant="body2">
                          {NumberFunctions.formatMoneyDefault(recipient.value)}
                        </Typography>
                      </Stack>
                    </Box>
                  ))}
                </Stack>
              }
              viewDivider={false}
            />
          </Box>
        </ListItemDefault>
      )}
      <Typography variant="subtitle2" sx={{ px: 2, py: 1 }}>
        Seu recebimento
      </Typography>
      <ListItemDefault
        title="Valor"
        value={NumberFunctions.formatMoneyDefault(details?.value)}
      />
    </Box>
  )
}

export default OrderDetailValues
