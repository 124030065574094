import { Box, Button, Chip, Stack, Typography } from '@mui/material'
import AccordionDefault from '../../../components/Accordions/AccordionDefault'
import AccordionSummaryDefault from '../../../components/Accordions/AccordionSummaryDefault'
import { dateTimeString } from '../../../core/functions/dateTime'
import AccordionDetailsDefault from '../../../components/Accordions/AccordionDetailsDefault'
import { TAffiliated } from '../../../core/types/Affiliated'
import AffiliatesFunction from '../../../core/functions/affiliates'
import Convertions from '../../../core/functions/convertions'

interface MyAffiliationsMobileProps {
  affiliated: TAffiliated
  handleChangeItem: (item: TAffiliated) => void
}

const MyAffiliationsMobile = ({
  affiliated,
  handleChangeItem,
}: MyAffiliationsMobileProps) => {
  const statusMap = AffiliatesFunction.getStatusMap(affiliated.status)
  const { label, ...style } = statusMap

  return (
    <Box
      key={Convertions.replaceSpacesAndNewlines(affiliated.codeId)}
      width="100%"
      sx={{ mb: 1 }}
    >
      <AccordionDefault>
        <AccordionSummaryDefault>
          <Box>
            <Stack
              direction="row"
              spacing={2}
              sx={{
                width: '100%',
                justifyContent: 'space-between',
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box>
                  <Typography
                    fontFamily="Montserrat"
                    fontWeight={500}
                    fontSize="14px"
                    lineHeight="18px"
                    color="rgba(56, 57, 59, 1)"
                  >
                    {affiliated.productName}
                  </Typography>
                  <Typography
                    fontFamily="Montserrat"
                    fontWeight={500}
                    fontSize="12px"
                    lineHeight="16px"
                    color="rgba(99, 126, 239, 1)"
                  >
                    {dateTimeString(affiliated.createdDate)}
                  </Typography>
                </Box>
              </Box>
              <Box>
                <Chip size="small" label={label} sx={{ ...style }} />
              </Box>
            </Stack>
          </Box>
        </AccordionSummaryDefault>

        <AccordionDetailsDefault>
          <Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <Typography
                variant="body2"
                fontFamily="Montserrat"
                fontWeight={500}
                fontSize="12px"
                lineHeight="16px"
                color="rgba(56, 57, 59, 1)"
              >
                Tipo de venda
              </Typography>

              <Stack direction={'row'} spacing={1}>
                <Typography
                  fontFamily="Montserrat"
                  fontWeight={400}
                  fontSize="12px"
                  lineHeight="16px"
                  color="rgba(28, 82, 189, 1)"
                >
                  <img
                    src={'/assets/icons/arrow/roundArrow.svg'}
                    alt="icon-eyes"
                    style={{
                      width: '10px',
                      height: '10px',
                    }}
                  />
                  Assinatura
                </Typography>
              </Stack>
            </Box>

            <Box
              sx={{
                mt: 0.5,
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <Typography
                variant="body2"
                fontFamily="Montserrat"
                fontWeight={500}
                fontSize="12px"
                lineHeight="16px"
                color="rgba(56, 57, 59, 1)"
              >
                Ativo:
              </Typography>
              <Stack direction={'row'} spacing={1}>
                <Typography
                  fontFamily="Montserrat"
                  fontWeight={600}
                  fontSize="12px"
                  lineHeight="16px"
                  color="rgba(56, 57, 59, 1)"
                >
                  {affiliated.productActive ? 'Sim' : 'Não'}
                </Typography>
              </Stack>
            </Box>

            <Box
              sx={{
                mt: 0.5,
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <Typography
                variant="body2"
                fontFamily="Montserrat"
                fontWeight={500}
                fontSize="12px"
                lineHeight="16px"
                color="rgba(56, 57, 59, 1)"
              >
                Comissão:
              </Typography>
              <Typography
                fontFamily="Montserrat"
                fontWeight={600}
                fontSize="12px"
                lineHeight="16px"
                color="rgba(56, 57, 59, 1)"
              >
                {affiliated.commissionPercentage}%
              </Typography>
            </Box>

            <Box
              sx={{
                mt: 0.5,
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <Typography
                variant="body2"
                fontFamily="Montserrat"
                fontWeight={500}
                fontSize="12px"
                lineHeight="16px"
                color="rgba(56, 57, 59, 1)"
              >
                Comissão Global:
              </Typography>
              <Typography
                fontFamily="Montserrat"
                fontWeight={600}
                fontSize="12px"
                lineHeight="16px"
                color="rgba(56, 57, 59, 1)"
              >
                {affiliated.globalCommissionPercentage}%
              </Typography>
            </Box>

            <Stack
              direction="row"
              spacing={1}
              justifyContent="space-between"
              sx={{ mt: 1 }}
            >
              <Button
                size="small"
                onClick={(event) => {
                  event.stopPropagation()
                  handleChangeItem(affiliated)
                }}
                startIcon={
                  <img
                    src={'/assets/icons/eyes/eyeButtonWhite.svg'}
                    alt="icon-eyes"
                    style={{ width: '20px', height: '20px' }}
                  />
                }
                sx={{
                  backgroundColor: 'rgba(56, 57, 59, 1)',
                  color: 'white',
                  '&:hover': {
                    backgroundColor: 'rgba(56, 57, 59, 0.9)',
                  },
                  width: '120px',
                  height: '24px',

                  borderRadius: '4px',
                  padding: '2px 8px 2px 4px',
                  gap: '8px',
                }}
              >
                <Typography
                  sx={{
                    width: '61px',
                    height: '16px',
                    fontFamily: 'Montserrat',
                    fontWeight: 700,
                    fontSize: '12px',
                    lineHeight: '16px',
                    textAlign: 'center',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  Visualizar
                </Typography>
              </Button>
            </Stack>
          </Box>
        </AccordionDetailsDefault>
      </AccordionDefault>
    </Box>
  )
}

export default MyAffiliationsMobile
