import {
  Box,
  Fade,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Typography,
} from '@mui/material'
import ListItemDefault from '../../../../../components/ListItem/ListItemDefault'
import { maskPercentage } from '../../../../../masks/masks'
import InfoInputAdornment from '../../../../../components/Inputs/InfoInputAdornment'
import SwitchRoundSmall from '../../../../../components/Switch/SwitchRoundSmall'
import { TIndicatedComissionLevelEnum } from '../../../../../core/types/IndicatedTenant'
import IndicatedFunctions from '../../../../../core/functions/IndicatedFunctions'
import { TTenantSettings } from '../../../../../core/types/Tenant'
import { red } from '@mui/material/colors'
import NumberFunctions from '../../../../../core/functions/NumberFunctions'

export interface IUserSettingValuesProps {
  tenantSettings: TTenantSettings
  errorImmediateWithdrawal: string
  errorPlatformFee: string
  errorAnticipationD15Fee: string
  errorAnticipationD2Fee: string
  errorIndicatedValue: string
  setTenantSettings: (value: TTenantSettings) => void
  setErrorAnticipationD2Fee: (value: string) => void
  setErrorAnticipationD15Fee: (value: string) => void
  handleChangeImmediateWithdrawal: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void
  handleChangePlataformFee: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void
  handleChangeIndicatedComissionsRateValue: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void
}

const UserSettingValues = ({
  tenantSettings,
  errorImmediateWithdrawal,
  errorPlatformFee,
  errorAnticipationD15Fee,
  errorAnticipationD2Fee,
  errorIndicatedValue,
  setTenantSettings,
  setErrorAnticipationD2Fee,
  setErrorAnticipationD15Fee,
  handleChangeImmediateWithdrawal,
  handleChangePlataformFee,
  handleChangeIndicatedComissionsRateValue,
}: IUserSettingValuesProps) => {
  const handleChangeAnticipationD2Fee = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setErrorAnticipationD2Fee('')
    const taxD2 = NumberFunctions.maskPercentageToNumber(e.target.value)
    setTenantSettings({
      ...tenantSettings,
      anticipationD2Fee: taxD2,
    })
  }

  const handleChangeAnticipationD15Fee = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setErrorAnticipationD15Fee('')
    const taxD15 = NumberFunctions.maskPercentageToNumber(e.target.value)
    setTenantSettings({
      ...tenantSettings,
      anticipationD15Fee: taxD15,
    })
  }

  return (
    <Stack direction="column" sx={{ py: 1 }}>
      <ListItemDefault
        title="Saque imediato (%)"
        widthTitlePercentage={40}
        variant="compact"
        value={
          <Box width="100%">
            <OutlinedInput
              fullWidth
              size="small"
              type="text"
              value={maskPercentage(tenantSettings.immediateWithdrawal)}
              onChange={handleChangeImmediateWithdrawal}
              endAdornment={
                <InfoInputAdornment title="Percentual para saque especial sobre o valor em garantia. Podendo ser de 0% até 90%." />
              }
              error={errorImmediateWithdrawal !== ''}
            />
            {errorImmediateWithdrawal !== '' && (
              <Typography variant="caption" color={red[700]}>
                {errorImmediateWithdrawal}
              </Typography>
            )}
          </Box>
        }
      />
      <ListItemDefault
        title="Taxa de transação (%)"
        widthTitlePercentage={40}
        variant="compact"
        value={
          <Box width="100%">
            <OutlinedInput
              fullWidth
              size="small"
              type="text"
              value={maskPercentage(tenantSettings.plataformFee)}
              onChange={handleChangePlataformFee}
              endAdornment={
                <InfoInputAdornment title="Definir a taxa especial das transações a nível de vendedor." />
              }
              error={errorPlatformFee !== ''}
            />
            {errorPlatformFee !== '' && (
              <Typography variant="caption" color={red[700]}>
                {errorPlatformFee}
              </Typography>
            )}
          </Box>
        }
      />
      <ListItemDefault
        title={
          <FormControlLabel
            control={
              <SwitchRoundSmall
                sx={{ m: 1 }}
                checked={tenantSettings.enableAnticipationD15 ?? false}
                onChange={(e) => {
                  let anticipationD15Fee =
                    tenantSettings.anticipationD15Fee ?? 0
                  if (anticipationD15Fee <= 0) {
                    anticipationD15Fee = 2
                  }
                  setTenantSettings({
                    ...tenantSettings,
                    enableAnticipationD15: e.target.checked,
                    anticipationD15Fee,
                  })
                }}
              />
            }
            label={
              <Typography variant="subtitle2">
                Habilitar antecipação D + 15
              </Typography>
            }
            sx={{ width: '100%' }}
          />
        }
        widthTitlePercentage={40}
        variant="compact"
        value={
          <Fade
            in={tenantSettings?.enableAnticipationD15 ?? false}
            mountOnEnter
            unmountOnExit
          >
            <Box width="100%">
              <OutlinedInput
                fullWidth
                size="small"
                type="text"
                value={maskPercentage(tenantSettings.anticipationD15Fee ?? 0)}
                onChange={handleChangeAnticipationD15Fee}
                endAdornment={
                  <InfoInputAdornment title="Percentual de acréscimo nas transações. Podendo ser de 0% até 5%." />
                }
                error={errorAnticipationD15Fee !== ''}
              />
              {errorAnticipationD15Fee !== '' && (
                <Typography variant="caption" color={red[700]}>
                  {errorAnticipationD15Fee}
                </Typography>
              )}
            </Box>
          </Fade>
        }
      />
      <ListItemDefault
        title={
          <FormControlLabel
            control={
              <SwitchRoundSmall
                sx={{ m: 1 }}
                checked={tenantSettings.enableAnticipationD2 ?? false}
                onChange={(e) => {
                  let anticipationD2Fee = tenantSettings.anticipationD2Fee ?? 0
                  if (anticipationD2Fee <= 0) {
                    anticipationD2Fee = 4
                  }
                  setTenantSettings({
                    ...tenantSettings,
                    enableAnticipationD2: e.target.checked,
                    anticipationD2Fee,
                  })
                }}
              />
            }
            label={
              <Typography variant="subtitle2">
                Habilitar antecipação D + 2
              </Typography>
            }
            sx={{ width: '100%' }}
          />
        }
        widthTitlePercentage={40}
        variant="compact"
        value={
          <Fade
            in={tenantSettings.enableAnticipationD2 ?? false}
            mountOnEnter
            unmountOnExit
          >
            <Box width="100%">
              <OutlinedInput
                fullWidth
                size="small"
                type="text"
                value={maskPercentage(tenantSettings.anticipationD2Fee ?? 0)}
                onChange={handleChangeAnticipationD2Fee}
                endAdornment={
                  <InfoInputAdornment title="Taxa de reajuste na taxa de parcelamento. Podendo ser de 0% até 5%." />
                }
                error={errorAnticipationD2Fee !== ''}
              />
              {errorAnticipationD2Fee !== '' && (
                <Typography variant="caption" color={red[700]}>
                  {errorAnticipationD2Fee}
                </Typography>
              )}
            </Box>
          </Fade>
        }
      />
      <ListItemDefault
        title={
          <FormControlLabel
            control={
              <SwitchRoundSmall
                sx={{ m: 1 }}
                checked={tenantSettings.disableIndicatedComissions ?? false}
                onChange={(e) => {
                  let anticipationD2Fee = tenantSettings.anticipationD2Fee ?? 0
                  if (anticipationD2Fee <= 0) {
                    anticipationD2Fee = 4
                  }
                  setTenantSettings({
                    ...tenantSettings,
                    disableIndicatedComissions: e.target.checked,
                    anticipationD2Fee,
                  })
                }}
              />
            }
            label={
              <Typography variant="subtitle2">
                Desabilitar sistema indicações
              </Typography>
            }
            sx={{ width: '100%' }}
          />
        }
        widthTitlePercentage={40}
        variant="compact"
        value={
          <Fade
            in={!(tenantSettings.disableIndicatedComissions ?? false)}
            mountOnEnter
            unmountOnExit
          >
            <Stack direction="row" spacing={2} width="100%">
              <FormControl fullWidth size="small">
                <InputLabel id="days-select-label">Nível</InputLabel>
                <Select
                  variant="outlined"
                  labelId="days-select-label"
                  label="Nível"
                  value={
                    tenantSettings.indicatedComissionLevel ??
                    TIndicatedComissionLevelEnum.None
                  }
                  onChange={(e) => {
                    const indicatedComissionLevel = e.target
                      .value as TIndicatedComissionLevelEnum
                    const indicatedComissionsRateValue =
                      indicatedComissionLevel ===
                      TIndicatedComissionLevelEnum.Basic
                        ? 5
                        : indicatedComissionLevel ===
                            TIndicatedComissionLevelEnum.Gold
                          ? 10
                          : indicatedComissionLevel ===
                              TIndicatedComissionLevelEnum.Platinum
                            ? 15
                            : 0
                    setTenantSettings({
                      ...tenantSettings,
                      indicatedComissionLevel,
                      indicatedComissionsRateValue,
                    })
                  }}
                >
                  {IndicatedFunctions.indicatedComissionLevels.map((level) => (
                    <MenuItem value={level}>
                      {IndicatedFunctions.getIndicatedComissionLevelString(
                        level
                      )}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Box width="100%">
                <OutlinedInput
                  fullWidth
                  size="small"
                  type="text"
                  value={maskPercentage(
                    tenantSettings.indicatedComissionsRateValue ?? 0
                  )}
                  onChange={handleChangeIndicatedComissionsRateValue}
                  endAdornment={
                    <InfoInputAdornment title="Percentual a receber de comissão para cada indicado cadastrado. Podendo ser de 0% até 30%." />
                  }
                  error={errorIndicatedValue !== ''}
                />
                {errorIndicatedValue !== '' && (
                  <Typography variant="caption" color={red[700]}>
                    {errorIndicatedValue}
                  </Typography>
                )}
              </Box>
            </Stack>
          </Fade>
        }
      />
    </Stack>
  )
}

export default UserSettingValues
