import { Box, Button, Chip, Stack, Tooltip, Typography } from '@mui/material'
import { IDataTableColumn } from '../../../components/Tables/DataTable'
import { TProduct, TProductStatus } from '../../../core/types/Product'
import DataTableCellTypography from '../../../components/Typographys/DataTableCellTypography'
import Convertions from '../../../core/functions/convertions'
import { HtmlTooltip } from '../../../components/Tooltip/HtmlTooltip'
import { ProductSituationEnum } from '../../../core/types/Situation'
import ProductSituationBlockModal from './ProductSituationBlockModal'
import { Link } from 'react-router-dom'
import { TenantController } from '../../../core/controllers/TenantController'
import { TTenantSettings } from '../../../core/types/Tenant'
import { IErrorResponse } from '../../../core/types/ErrorResponse'

const getStatusChipStyles = (status: TProductStatus) => {
  const statusStyles = {
    [TProductStatus.Active]: {
      backgroundColor: 'rgba(28, 189, 63, 0.16)',
      color: 'rgba(0, 157, 35, 1)',
    },
    [TProductStatus.Inactive]: {
      backgroundColor: 'rgba(189, 28, 28, 0.16)',
      color: 'rgba(212, 54, 44, 1)',
    },
  }

  return statusStyles[status]
}

interface MyProductsColumnsProp {
  isAdmin: boolean
  status: string
  setTenantSettings: (value: TTenantSettings) => void
}

const myProductsColumns = ({
  isAdmin,
  status,
  setTenantSettings,
}: MyProductsColumnsProp): IDataTableColumn<TProduct>[] => {
  const handleChangeTenant = async (product: TProduct) => {
    const { tenantId } = product
    const result = await TenantController.getSettings({ id: tenantId || '' })
    if (result) {
      const resultError = result as IErrorResponse
      if (resultError.error) {
        return
      }
      const tenantSettings = result as TTenantSettings
      setTenantSettings(tenantSettings)
    }
  }

  const columns: IDataTableColumn<TProduct>[] = [
    {
      id: 'name',
      label: 'Nome',
      width: isAdmin ? '50%' : '70%',
      render(id, value, row) {
        return (
          <Stack direction="row" alignItems="center">
            <img
              src={
                row.imageProductUrl
                  ? row.imageProductUrl
                  : '/assets/logo/logoCeletus.svg'
              }
              style={{
                width: 40,
                height: 40,
                marginRight: 10,
                borderRadius: 6.67,
                objectFit: 'cover',
                objectPosition: 'center',
              }}
            />
            <DataTableCellTypography>{value}</DataTableCellTypography>
            {Convertions.productTypePaymentToString(row.typePayment) ===
              'Assinatura' && (
              <HtmlTooltip title={<Typography>Recorrência</Typography>}>
                <img
                  src={'/assets/icons/arrow/roundArrow.svg'}
                  alt="icon-eyes"
                  style={{
                    width: '15px',
                    height: '15px',
                    marginLeft: '5px',
                  }}
                />
              </HtmlTooltip>
            )}
          </Stack>
        )
      },
    },
  ]

  if (isAdmin) {
    const tenantColumn: IDataTableColumn<TProduct> = {
      id: 'tenantName',
      label: 'Produtor',
      width: '20%',
      render(id, value, row) {
        return (
          <Button
            color="inherit"
            onClick={() => handleChangeTenant(row)}
            sx={{ textTransform: 'none' }}
          >
            {value}
          </Button>
        )
      },
    }
    columns.push(tenantColumn)
  }

  const otherColumns: IDataTableColumn<TProduct>[] = [
    {
      id: 'status',
      label: 'Situação',
      width: '12%',
      render(id, value, row) {
        return (
          <Stack direction="row" spacing={1} alignItems="center">
            <Chip
              label={Convertions.productStatusToString(value)}
              size="small"
              sx={getStatusChipStyles(value)}
            />
          </Stack>
        )
      },
    },
    {
      id: 'memberServiceId',
      label: 'Referência',
      width: '8%',
    },
    {
      id: 'action',
      label: 'Ações',
      width: '5%',
      render(id, value, row) {
        return (
          <Box>
            {row.situation === ProductSituationEnum.Blocked ? (
              <ProductSituationBlockModal row={row} />
            ) : (
              status !== 'deleted' && (
                <Tooltip title="Editar o produto">
                  <Button
                    component={Link}
                    to={`/product/${row.id}`}
                    variant="text"
                    size="small"
                    sx={{
                      px: 1,
                      py: 0.5,
                      m: 0,
                      minWidth: 0,
                      borderRadius: '8px',
                    }}
                  >
                    <img
                      src="/assets/icons/eyes/eyeButtonBlack.svg"
                      alt="action-eye"
                      style={{ fontSize: '1.2rem', color: 'black' }}
                    />
                  </Button>
                </Tooltip>
              )
            )}
          </Box>
        )
      },
    },
  ]

  columns.push(...otherColumns)
  return columns
}

export default myProductsColumns
