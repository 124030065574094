import React, { useContext } from 'react'
import { Box, Stack, Typography, Chip, Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import AccordionDefault from '../../../components/Accordions/AccordionDefault'
import AccordionDetailsDefault from '../../../components/Accordions/AccordionDetailsDefault'
import AccordionSummaryDefault from '../../../components/Accordions/AccordionSummaryDefault'
import { TUserType } from '../../../core/types/Auth'
import { AuthContext } from '../../../core/context/AuthContext'
import { TCoproducer, TCoproducerStatus } from '../../../core/types/Coproducer'
import {
  addDays,
  dateString,
  dateTimeString,
} from '../../../core/functions/dateTime'
import MyCoproducerFunctions from './MyCoproducerFunctions'

interface CoproducerCardProps {
  coproducer: TCoproducer
  index?: number
  onClick?: () => void
}

const CoproducerCard: React.FC<CoproducerCardProps> = ({
  coproducer,
  index,
  onClick,
}) => {
  const navigate = useNavigate()
  const [expanded, setExpanded] = React.useState(false)
  const { user } = useContext(AuthContext)
  const isAdmin = user?.UserType === TUserType.SystemAdmin

  const truncateString = (str: string = '', num: number) => {
    if (str.length <= num) {
      return str
    }
    return str.slice(0, num) + '...'
  }

  const toggleExpand = () => {
    setExpanded(!expanded)
  }

  return (
    <Box key={`abandoned${index}`} width="100%" sx={{ mb: 1 }}>
      <AccordionDefault>
        <AccordionSummaryDefault>
          <Box>
            <Stack
              direction="row"
              spacing={2}
              sx={{ width: '100%', justifyContent: 'space-between' }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <img
                  src={
                    coproducer.productImageUrl || '/assets/logo/logoCeletus.svg'
                  }
                  alt="coproducer-image"
                  style={{
                    width: 40,
                    height: 40,
                    marginRight: '8px',
                    borderRadius: 6.67,
                    objectFit: 'cover',
                    objectPosition: 'center',
                  }}
                />
                <Box>
                  <Typography
                    sx={{
                      color: 'rgba(56, 57, 59, 1) ',
                      fontFamily: 'Montserrat',
                      fontSize: '14px',
                      lineHeight: '18px',
                      fontWeight: 500,
                    }}
                  >
                    {truncateString(coproducer.productName, 20)}
                  </Typography>
                </Box>
              </Box>

              <Chip
                label={MyCoproducerFunctions.getStatusLabel(coproducer.status)}
                size="small"
                sx={MyCoproducerFunctions.getStatusChipStyles(
                  coproducer.status
                )}
              />
            </Stack>
          </Box>
        </AccordionSummaryDefault>

        <AccordionDetailsDefault>
          <Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <Typography
                variant="body2"
                color="textSecondary"
                sx={{
                  textAlign: 'left',
                  fontFamily: 'Montserrat',
                  fontSize: '12px',
                  lineHeight: '16px',
                  fontWeight: 500,
                }}
              >
                Início
              </Typography>
              <Typography
                sx={{
                  color: 'rgba(56, 57, 59, 1)',
                  textAlign: 'right',
                  fontFamily: 'Montserrat',
                  fontSize: '12px',
                  lineHeight: '16px',
                  fontWeight: 600,
                }}
              >
                {dateTimeString(coproducer.createdDate)}
              </Typography>
            </Box>

            <Box
              sx={{
                mt: 0.5,
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <Typography
                variant="body2"
                color="textSecondary"
                sx={{
                  textAlign: 'left',
                  fontFamily: 'Montserrat',
                  fontSize: '12px',
                  lineHeight: '16px',
                  fontWeight: 500,
                }}
              >
                Vencimento
              </Typography>
              <Typography
                sx={{
                  color: 'rgba(56, 57, 59, 1)',
                  textAlign: 'right',
                  fontFamily: 'Montserrat',
                  fontSize: '12px',
                  lineHeight: '16px',
                  fontWeight: 600,
                }}
              >
                {coproducer.contractDuration === 999
                  ? 'Eterno'
                  : dateTimeString(
                      addDays(
                        coproducer.contractDuration || 0,
                        coproducer.createdDate
                      )
                    )}
              </Typography>
            </Box>

            <Box
              sx={{
                mt: 0.5,
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <Typography
                variant="body2"
                color="textSecondary"
                sx={{
                  textAlign: 'left',
                  fontFamily: 'Montserrat',
                  fontSize: '12px',
                  lineHeight: '16px',
                  fontWeight: 500,
                }}
              >
                NFe
              </Typography>
              <Typography
                sx={{
                  color: 'rgba(56, 57, 59, 1)',
                  textAlign: 'right',
                  fontFamily: 'Montserrat',
                  fontSize: '12px',
                  lineHeight: '16px',
                  fontWeight: 600,
                }}
              >
                {coproducer.issuanceInvoices === true ? 'Sim' : 'Não'}
              </Typography>
            </Box>

            <Box
              sx={{
                mt: 0.5,
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <Typography
                variant="body2"
                color="textSecondary"
                sx={{
                  textAlign: 'left',
                  fontFamily: 'Montserrat',
                  fontSize: '12px',
                  lineHeight: '16px',
                  fontWeight: 500,
                }}
              >
                Comissão
              </Typography>
              <Typography
                sx={{
                  color: 'rgba(56, 57, 59, 1)',
                  textAlign: 'right',
                  fontFamily: 'Montserrat',
                  fontSize: '12px',
                  lineHeight: '16px',
                  fontWeight: 600,
                }}
              >
                {coproducer.commissionPercentage}%
              </Typography>
            </Box>

            {/*<Stack sx={{ mt: 1, width: '120px' }}>
              <Button
                size="small"
                onClick={onClick}
                startIcon={
                  <img
                    src={'/assets/icons/eyes/eyeButtonWhite.svg'}
                    alt="icon-eyes"
                    style={{ width: '20px', height: '20px' }}
                  />
                }
                sx={{
                  backgroundColor: 'rgba(56, 57, 59, 1)',
                  color: 'white',
                  '&:hover': {
                    backgroundColor: 'rgba(56, 57, 59, 0.9)',
                  },
                  borderRadius: '4px',
                  padding: '2px 8px 2px 4px',
                }}
              >
                <Typography
                  sx={{
                    height: '16px',
                    fontFamily: 'Montserrat',
                    fontWeight: 700,
                    fontSize: '12px',
                    lineHeight: '16px',
                    textAlign: 'center',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  Visualizar
                </Typography>
              </Button>
            </Stack>*/}
          </Box>
        </AccordionDetailsDefault>
      </AccordionDefault>
    </Box>
  )
}

export default CoproducerCard
