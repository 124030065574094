import { TSubscriptionDetails } from '../types/CheckoutOrder'
import { SubscriptionStatusEnum } from '../types/Order'

const subscriptionStatusMap: Record<
  SubscriptionStatusEnum,
  { label: string; backgroundColor: string; color: string }
> = {
  [SubscriptionStatusEnum.Active]: {
    label: 'Ativo',
    backgroundColor: 'rgba(28, 189, 63, 0.16)',
    color: 'rgba(0, 157, 35, 1)',
  },
  [SubscriptionStatusEnum.Blocked]: {
    label: 'Bloqueado',
    backgroundColor: '#BD1C1C29',
    color: '#D4362C',
  },
  [SubscriptionStatusEnum.Canceled]: {
    label: 'Cancelado',
    backgroundColor: '#BD1C1C29',
    color: '#D4362C',
  },
  [SubscriptionStatusEnum.Delayed]: {
    label: 'Atrasado',
    backgroundColor: '#FBF3E2',
    color: '#887100',
  },
}

const getStatusMap = (status?: SubscriptionStatusEnum) => {
  return subscriptionStatusMap[status ?? SubscriptionStatusEnum.Delayed]
}

const getStatusLabel = (status?: SubscriptionStatusEnum) => {
  if (!status) return ''
  return subscriptionStatusMap[status]?.label ?? ''
}

const inAssurance = (subscription: TSubscriptionDetails | null | undefined) => {
  if (subscription?.orderData?.inAssurance) {
    return true
  }

  const exists = subscription?.transactions.find(
    (t) => t.inAssurance && t.status === 'paid'
  )
  return exists?.inAssurance ?? false
}

const SubscriptionFunctions = {
  getStatusMap,
  getStatusLabel,
  inAssurance,
}

export default SubscriptionFunctions
