import { TIndicatedComissionLevelEnum } from './IndicatedTenant'

export enum TTenantAnticipationType {
  None = 0,
  D2 = 2,
  D15 = 15,
  D30 = 30,
}

export enum TPersonType {
  Individual = 1,
  Corporation = 2,
}

export type ITenant = {
  id: string
  accessKey: string
  name: string
  email: string
  status?: number
  paymentServiceUrl?: string
  memberServiceDomain?: string
  memberServiceToken?: string
  memberServiceApiKey?: string
  token: string
  googleTagManagerId?: string
  createdDate?: Date
  updatedDate?: Date
  phone?: string
  enableMemberClass: boolean
  urlClub?: string | null | undefined
  enableRegisterProducts?: boolean | null | undefined
}

export type ITenantBankAccount = {
  recipientDocument: string
  accountName: string
  bankCode: string
  agencyNumber: string
  accountNumber: string
  accountNumberDigit: string
  accountType: number
  agencyNumberCheck?: number | null
  tenantId?: string
}

export enum EIdentityValidation {
  Unverified = 1,
  Waiting = 2,
  Verified = 3,
  Pending = 4,
  Denied = 5,
}

export type ITenantBalance = {
  availableAmount: number
  waitingFundsAmount: number
  totalAmount: number
  transferredAmount: number
  withdraws: IWithdraw[]
  recipientStatus?: string
  immediateWithdrawal: number
  anticipationType?: TTenantAnticipationType | null
  minWithdrawValue: number
  isRequestIdentityValidation: boolean
  identityValidation: EIdentityValidation
  identityValidationDescription: string
  identityValidationUrl: string
}

export type TIndentityVerificationURL = {
  identityValidationUrl: string
}

export type IWithdraw = {
  amount: number
  createdAt: Date
  status: WithdrawStatusEnum
  updatedAt?: Date
  details?: IWithdrawDetail[]
}

export type IWithdrawDetail = {
  detail: string
  registerDate?: Date
}

export enum WithdrawStatusEnum {
  pending = 1,
  approved = 2,
  processing = 3,
  fail = 4,
}

export type TSignUpTenant = {
  name: string
  email: string
  emailConfirm?: string
  password: string
  indicatedOf?: string | null
}

export type TTenantSettings = {
  id: string
  tenantId: string
  immediateWithdrawal: number
  plataformFee: number

  tenantCode?: string
  tenantName?: string
  tenantEmail?: string
  recipientId?: string

  enableAnticipationD15?: boolean | null
  anticipationD15Fee?: number | null
  enableAnticipationD2?: boolean | null
  anticipationD2Fee?: number | null
  chargeAnticipationOnPix?: boolean | null

  disableIndicatedComissions?: boolean | null
  indicatedComissionLevel?: TIndicatedComissionLevelEnum | null
  indicatedComissionsRateValue?: number | null
  acceptIndicationUseTerms?: boolean | null

  recipientStatus?: string | null

  withdrawlsEnabled?: boolean | null
  salesEnabled?: boolean | null
  enableRegisterProducts?: boolean | null

  enableAffiliateFees?: boolean | null
  affiliateFees?: number | null
}

export type TTenantSettingsPagination = {
  items: TTenantSettings[]
  page: number
  rowsPerPage: number
  totalPages: number
  total: number
}

export type TTenantAddressRegister = {
  street: string
  complement: string
  number: string
  neighborhood: string
  city: string
  state: string
  zipCode: string
  referencePoint: string
}

export type TTenantManagingPartner = {
  name: string
  email: string
  document: string
  birthDate: Date
  monthyIncome: number
  declaredLegal: boolean
  phone: string
  professionalOccupation: string
  address: TTenantAddressRegister
}

export type TTenantRegister = {
  personType: TPersonType
  email: string
  document: string
  phone: string
  address: TTenantAddressRegister
  bankAccount: ITenantBankAccount

  name: string
  revenue: number

  birthDate?: Date | null
  professionalOccupation?: string | null

  tradingName?: string | null
  mainAddress?: TTenantAddressRegister
  managingPartner?: TTenantManagingPartner
}

export type TSalesAnalysisByProducer = {
  id: string
  name: string
  email: string

  allTransactionsLast30Days?: number
  allTransactionsLastGeneral?: number

  allCommissionsReceived?: number
  allCommissionsReceivedLast30Days?: number

  allWithdraws?: number
  allAccountBalance?: number
  allBalance?: number

  celetusGeneralIncame?: number
  celetusLast30DaysIncame?: number
  allGatewayFees?: number
  allGatewayFeesLast30Days?: number
  allProfit?: number
  allProfitLast30Days?: number

  createdDate: Date
  updatedDate: Date
}

export type TSalesAnalysisByProducerPagination = {
  items: TSalesAnalysisByProducer[]
  page: number
  rowsPerPage: number
  totalPages: number
  total: number
  info?: number
}
