import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Chip,
  Skeleton,
  Stack,
} from '@mui/material'
import {
  TCheckoutOrder,
  TCheckoutOrderDetails,
} from '../../../../../core/types/CheckoutOrder'
import Convertions from '../../../../../core/functions/convertions'
import NumberFunctions from '../../../../../core/functions/NumberFunctions'
import { dateTimeString } from '../../../../../core/functions/dateTime'
import { TProductVoucherValue } from '../../../../../core/types/Voucher'
import { useContext } from 'react'
import { AuthContext } from '../../../../../core/context/AuthContext'
import { TUserType } from '../../../../../core/types/Auth'
import OrderController from '../../../../../core/controllers/OrderController'
import ListItemDefault from '../../../../../components/ListItem/ListItemDefault'
import OrderFunctions from '../../../../../core/functions/OrderFunctions'
import {
  CheckoutOrderLinkColumn,
  CheckoutOrderCustomerWhatsAppColumn,
  checkoutOrderStatusColorMap,
} from '../../CheckoutOrderConsts'
import Hosts from '../../../../../core/functions/hosts'

export interface IOrderDetailOrderProp {
  checkoutOrder?: TCheckoutOrder
  details?: TCheckoutOrderDetails
  isOpened: boolean
  loading: boolean
  setSending: (value: boolean) => void
  setSuccess: (value: boolean) => void
  setError: (value: string) => void
  execute: () => void
  getDetails: (id: string, productPriceId?: string) => Promise<void>
  setCopied: (value: boolean) => void
}

const OrderDetailOrder = ({
  checkoutOrder,
  details,
  isOpened,
  loading,
  setSending,
  setSuccess,
  setError,
  execute,
  getDetails,
  setCopied,
}: IOrderDetailOrderProp) => {
  const { user } = useContext(AuthContext)

  const colorConfig =
    checkoutOrderStatusColorMap[checkoutOrder?.status ?? 'peding'] ||
    checkoutOrderStatusColorMap.default

  const handleDownloadBoleto = async (codeId: string) => {
    const boletoURL = await OrderController.getPaymentLink({ codeId })
    window.open(boletoURL.data, '_blank')
  }

  const handleCopyPixCode = async (codeId: string) => {
    const pixCode = await OrderController.getPaymentLink({ codeId })
    Hosts.copyTextToClipboard(pixCode.data)
    setCopied(true)
  }

  const linkColumn = CheckoutOrderLinkColumn({
    setCopied,
    onDownloadBoleto: handleDownloadBoleto,
    onCopyPix: handleCopyPixCode,
    execute,
  })
  const whatsColumn = CheckoutOrderCustomerWhatsAppColumn({ execute })

  const handleChangeUnlock = async () => {
    if (!checkoutOrder?.id) {
      setError('Nenhuma venda selecionada')
      return
    }

    setSending(true)
    try {
      const response = await OrderController.unlockSale({
        checkoutOrderId: checkoutOrder.id,
      })

      if (!response.success) {
        setError(response.error)
        return
      }

      setSuccess(true)
      await getDetails(checkoutOrder.id, checkoutOrder.productPriceId)
      execute()
    } finally {
      setSending(false)
    }
  }

  if (loading) {
    return (
      <Stack spacing={2} sx={{ p: 2 }}>
        <Skeleton variant="rounded" width="100%" height="45px" />
        <Skeleton variant="rounded" width="100%" height="45px" />
        <Skeleton variant="rounded" width="100%" height="45px" />
        <Skeleton variant="rounded" width="100%" height="45px" />
        <Skeleton variant="rounded" width="100%" height="45px" />
        <Skeleton variant="rounded" width="100%" height="45px" />
        <Skeleton variant="rounded" width="100%" height="45px" />
        <Skeleton variant="rounded" width="100%" height="45px" />
      </Stack>
    )
  }

  return (
    <Box>
      <ListItemDefault
        py={0.6}
        title="ID da venda"
        value={
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            justifyContent="space-between"
          >
            <Box>{details?.codeId}</Box>
            <Stack direction="row" spacing={1}>
              {isOpened &&
                linkColumn.render &&
                details &&
                checkoutOrder &&
                linkColumn.render('codeId', details?.codeId, checkoutOrder)}
              {whatsColumn.render &&
                checkoutOrder &&
                whatsColumn.render(
                  'phone',
                  checkoutOrder?.phone,
                  checkoutOrder
                )}
            </Stack>
          </Stack>
        }
      />
      <ListItemDefault
        title="Status"
        value={
          <Box sx={{ width: '100%' }}>
            <Chip
              label={Convertions.checkoutOrderStatusToString(
                checkoutOrder?.status
              )}
              size="small"
              sx={{
                backgroundColor: colorConfig.backgroundColor,
                color: colorConfig.color,
              }}
            />
          </Box>
        }
      />
      {details?.errors && details.errors.length > 0 && (
        <Stack sx={{ direction: 'column', mt: 0.5, px: 2 }}>
          {details.errors.map((error) => (
            <Stack direction="column">
              <Alert severity="warning" sx={{ width: '100%', mb: 1 }}>
                <AlertTitle>
                  {Convertions.ckeckoutOrderDetailsError(error.type)} -{' '}
                  {dateTimeString(error.createdDate)}
                </AlertTitle>
                {Convertions.ckeckoutOrderDetailsDescriptionError(error.type)}
              </Alert>
            </Stack>
          ))}
        </Stack>
      )}

      <ListItemDefault
        title="Valor líquido"
        value={NumberFunctions.formatMoneyDefault(details?.value)}
      />
      <ListItemDefault title="Produto" value={checkoutOrder?.productName} />
      <ListItemDefault title="Oferta" value={checkoutOrder?.offerName ?? ''} />
      <ListItemDefault
        title="Método de pagamento"
        value={Convertions.paymentMethodToString(checkoutOrder?.paymentyMethod)}
      />
      <ListItemDefault
        title="Data de criação"
        value={dateTimeString(checkoutOrder?.createdDate)}
      />
      {details?.paymentDate && (
        <ListItemDefault
          title="Data de pagamento"
          value={dateTimeString(details.paymentDate)}
        />
      )}
      {details?.voucherIdentifier !== undefined &&
        details?.voucherIdentifier !== null &&
        details?.voucherIdentifier !== '' && (
          <ListItemDefault
            title="Cupom de desconto"
            value={`${details?.voucherIdentifier} - ${
              details?.voucherType === TProductVoucherValue.value
                ? NumberFunctions.formatMoneyDefault(details?.voucherValue)
                : NumberFunctions.toPercentage(details?.voucherValue)
            }`}
          />
        )}
      {details?.refundClaimedDate && (
        <ListItemDefault
          title="Data da reclamação"
          value={dateTimeString(details.refundClaimedDate)}
        />
      )}
      {details?.refundedProblem && (
        <ListItemDefault title="Problema" value={details.refundedProblem} />
      )}
      {details?.refundedReason && (
        <ListItemDefault title="Motivo" value={details.refundedReason} />
      )}
      {details?.isSubscriptionPaymentCancel && (
        <ListItemDefault
          title="Cobrança da Recorrência"
          value={<Chip label="Cancelado" size="small" color="error" />}
        />
      )}
      {details?.isBlocked && (
        <ListItemDefault title="" px={0} py={0}>
          <Box width="100%">
            <ListItemDefault
              title={
                <Chip label="Venda Bloqueada" size="small" color="error" />
              }
              value={details?.reasonBlocked ?? 'Motivo não identificado'}
              viewDivider={false}
              px={0}
              py={0}
            />
            {user?.UserType === TUserType.SystemAdmin && (
              <Box sx={{ px: 2, pb: 2 }}>
                <Button
                  fullWidth
                  variant="contained"
                  size="small"
                  onClick={handleChangeUnlock}
                  sx={{ textTransform: 'none' }}
                >
                  Desbloquear venda
                </Button>
              </Box>
            )}
          </Box>
        </ListItemDefault>
      )}
    </Box>
  )
}

export default OrderDetailOrder
