import { Button, ButtonGroup, Stack, Tooltip, Typography } from '@mui/material'
import { IDataTableColumn } from '../../../../components/Tables/DataTable'
import { TIndicatedTenantItem } from '../../../../core/types/IndicatedTenant'
import IndicatedFunctions from '../../../../core/functions/IndicatedFunctions'
import NumberFunctions from '../../../../core/functions/NumberFunctions'
import { dateString } from '../../../../core/functions/dateTime'
import EditIcon from '@mui/icons-material/Edit'
import CheckIcon from '@mui/icons-material/Check'
import BlockIcon from '@mui/icons-material/Block'

interface GetUserSettingColumns {
  handleEnableOrDisableIndicatedComission: (
    row: TIndicatedTenantItem
  ) => Promise<void>
  setCurrentIndicated: (row: TIndicatedTenantItem) => void
}

const getUserSettingColumns = ({
  handleEnableOrDisableIndicatedComission,
  setCurrentIndicated,
}: GetUserSettingColumns): readonly IDataTableColumn<TIndicatedTenantItem>[] => [
  {
    id: 'name',
    label: 'Nome',
    minWidth: 140,
    render(id, value, row) {
      return (
        <Stack direction="column">
          <Typography
            variant="subtitle2"
            fontStyle={!row.isEnableCommissions ? 'italic' : undefined}
            sx={{
              textDecoration: !row.isEnableCommissions
                ? 'line-through'
                : undefined,
            }}
          >
            {value}
          </Typography>
          <Typography variant="body2">{row.email}</Typography>
        </Stack>
      )
    },
  },
  {
    id: 'indicatedComissionsPercentage',
    label: 'Configurações',
    minWidth: 140,
    render(id, value, row) {
      return (
        <Stack direction="column">
          <Typography
            variant="subtitle2"
            fontStyle={!row.isEnableCommissions ? 'italic' : undefined}
            sx={{
              textDecoration: !row.isEnableCommissions
                ? 'line-through'
                : undefined,
            }}
          >
            {IndicatedFunctions.getIndicatedComissionLevelString(
              row.indicatedComissionLevelEnum
            )}
            {' - '}
            {NumberFunctions.toPercentage(row.indicatedComissionsPercentage)}
          </Typography>
          <Typography variant="caption">
            Válido até {dateString(row.receiveComissionUntilDateOf)}
          </Typography>
        </Stack>
      )
    },
  },
  {
    id: 'action',
    label: 'Ações',
    minWidth: 20,
    render(id, value, row) {
      return (
        <ButtonGroup
          variant="outlined"
          aria-label="outlined button group"
          sx={{ mr: 4.5 }}
        >
          <Tooltip
            title={
              row.isEnableCommissions ? 'Desativar comissão' : 'Ativar comissão'
            }
          >
            <Button
              size="small"
              color={row.isEnableCommissions ? 'error' : 'primary'}
              onClick={() => handleEnableOrDisableIndicatedComission(row)}
            >
              {!row.isEnableCommissions ? <CheckIcon /> : <BlockIcon />}
            </Button>
          </Tooltip>
          <Tooltip title="Editar">
            <Button
              size="small"
              color="primary"
              onClick={() => setCurrentIndicated(row)}
              disabled={!row.isEnableCommissions}
            >
              <EditIcon />
            </Button>
          </Tooltip>
        </ButtonGroup>
      )
    },
  },
]

const UserSettingWidgets = {
  getUserSettingColumns,
}

export default UserSettingWidgets
