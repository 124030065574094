import React, { useContext } from 'react'
import { Box, Stack, Typography, Chip, Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import AccordionDefault from '../../../../components/Accordions/AccordionDefault'
import AccordionDetailsDefault from '../../../../components/Accordions/AccordionDetailsDefault'
import AccordionSummaryDefault from '../../../../components/Accordions/AccordionSummaryDefault'
import { IOrderBump } from '../../../../core/types/OrderBump'
import { AuthContext } from '../../../../core/context/AuthContext'
import { TUserType } from '../../../../core/types/Auth'

interface OrderBumpsCardProps {
  orderbump: IOrderBump
  orderbumpImage?: string
  index?: number
  onClick: () => void
}

const OrderBumpsCard: React.FC<OrderBumpsCardProps> = ({
  orderbump,
  orderbumpImage,
  index,
  onClick,
}) => {
  const navigate = useNavigate()
  const [expanded, setExpanded] = React.useState(false)

  const { user } = useContext(AuthContext)
  const isAdmin = user?.UserType === TUserType.SystemAdmin

  const truncateString = (str: string = '', num: number) => {
    if (str.length <= num) {
      return str
    }
    return str.slice(0, num) + '...'
  }

  const toggleExpand = () => {
    setExpanded(!expanded)
  }

  type StatusType = 'active' | 'inactive'

  const getStatusChipStyles = (status = false) => {
    const statusStyles: Record<
      StatusType,
      { backgroundColor: string; color: string }
    > = {
      active: {
        backgroundColor: 'rgba(28, 189, 63, 0.16)',
        color: 'rgba(0, 157, 35, 1)',
      },
      inactive: {
        backgroundColor: 'rgba(189, 28, 28, 0.16)',
        color: 'rgba(212, 54, 44, 1)',
      },
    }

    const statusType: StatusType = status ? 'active' : 'inactive'
    return statusStyles[statusType]
  }

  return (
    <Box key={`abandoned${index}`} width="100%" sx={{ mb: 1 }}>
      <AccordionDefault>
        <AccordionSummaryDefault>
          <Box>
            <Stack
              direction="row"
              spacing={2}
              sx={{ width: '100%', justifyContent: 'space-between' }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <img
                  src={orderbumpImage}
                  alt="orderbump-image"
                  style={{
                    width: 40,
                    height: 40,
                    marginRight: '8px',
                    borderRadius: 6.67,
                    objectFit: 'cover',
                    objectPosition: 'center',
                  }}
                />
                <Box>
                  <Typography
                    sx={{
                      color: 'rgba(56, 57, 59, 1) ',
                      fontFamily: 'Montserrat',
                      fontSize: '14px',
                      lineHeight: '18px',
                      fontWeight: 500,
                    }}
                  >
                    {truncateString(orderbump.title, 20)}
                  </Typography>
                </Box>
              </Box>

              <Chip
                label={orderbump.active ? 'Ativo' : 'Inativo'}
                size="small"
                sx={getStatusChipStyles(orderbump.active)}
              />
            </Stack>
          </Box>
        </AccordionSummaryDefault>

        <AccordionDetailsDefault>
          <Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <Typography
                variant="body2"
                color="textSecondary"
                sx={{
                  textAlign: 'left',
                  fontFamily: 'Montserrat',
                  fontSize: '12px',
                  lineHeight: '16px',
                  fontWeight: 500,
                }}
              >
                Nome:
              </Typography>
              <Typography
                sx={{
                  color: 'rgba(56, 57, 59, 1)',
                  textAlign: 'right',
                  fontFamily: 'Montserrat',
                  fontSize: '12px',
                  lineHeight: '16px',
                  fontWeight: 600,
                }}
              >
                {orderbump.productName}
              </Typography>
            </Box>
            <Box
              sx={{
                mt: 0.5,
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <Typography
                variant="body2"
                color="textSecondary"
                sx={{
                  textAlign: 'left',
                  fontFamily: 'Montserrat',
                  fontSize: '12px',
                  lineHeight: '16px',
                  fontWeight: 500,
                }}
              >
                Oferta:
              </Typography>
              <Typography
                sx={{
                  color: 'rgba(56, 57, 59, 1)',
                  textAlign: 'right',
                  fontFamily: 'Montserrat',
                  fontSize: '12px',
                  lineHeight: '16px',
                  fontWeight: 600,
                }}
              >
                {orderbump.offerName}
              </Typography>
            </Box>
            <Stack sx={{ mt: 1, width: isAdmin ? '120px' : '90px' }}>
              <Button
                size="small"
                onClick={onClick}
                startIcon={
                  <img
                    src={
                      isAdmin
                        ? '/assets/icons/eyes/eyeButtonWhite.svg'
                        : '/assets/icons/actions/editWhite.svg'
                    }
                    alt="icon-eyes"
                    style={{ width: '20px', height: '20px' }}
                  />
                }
                sx={{
                  backgroundColor: 'rgba(56, 57, 59, 1)',
                  color: 'white',
                  '&:hover': {
                    backgroundColor: 'rgba(56, 57, 59, 0.9)',
                  },
                  borderRadius: '4px',
                  padding: '2px 8px 2px 4px',
                }}
              >
                <Typography
                  sx={{
                    height: '16px',
                    fontFamily: 'Montserrat',
                    fontWeight: 700,
                    fontSize: '12px',
                    lineHeight: '16px',
                    textAlign: 'center',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  {isAdmin ? 'Visualizar' : 'Editar'}
                </Typography>
              </Button>
            </Stack>
          </Box>
        </AccordionDetailsDefault>
      </AccordionDefault>
    </Box>
  )
}

export default OrderBumpsCard
