import { ITenantBankAccount } from '../../../../../core/types/Tenant'

const isTenantBank = (tenantBank: ITenantBankAccount | null | undefined) => {
  return (
    tenantBank !== undefined &&
    tenantBank !== null &&
    tenantBank.accountNumber !== undefined &&
    tenantBank.accountNumber !== null &&
    tenantBank.bankCode !== undefined &&
    tenantBank.bankCode !== null
  )
}

const ProductPriceFunctions = {
  isTenantBank,
}

export default ProductPriceFunctions
