import React from 'react'
import { IErrorResponse } from '../types/ErrorResponse'
import { TProductSimple } from '../types/Product'
import ProductController from '../controllers/ProductController'
import AffiliationController from '../controllers/AffiliationController'

export const useAffiliationProducts = () => {
  const [loading, setLoading] = React.useState<boolean>(false)
  const [data, setData] = React.useState<TProductSimple[]>([])
  const [error, setError] = React.useState<string>('')

  const execute = async () => {
    if (!loading) {
      try {
        setLoading(true)

        if (error !== '') {
          setError('')
        }

        let response = await AffiliationController.getListProducts()
        let responseError = response as IErrorResponse

        if (responseError.code) {
          response = await ProductController.getList({
            includeAffiliatedProducts: false,
          })
        }

        responseError = response as IErrorResponse
        const responseData = response as TProductSimple[]

        if (responseError.code) {
          setError(responseError.error)
          setData([])
        } else {
          setData(responseData)
        }
      } catch (e) {
        var message = 'Erro não identificado'
        if (typeof e === 'string') {
          message = e.toUpperCase()
        } else if (e instanceof Error) {
          const err = e as Error
          message = err.message
        }
        setError(message)
      } finally {
        setLoading(false)
      }
    }
  }

  React.useEffect(() => {
    execute()
  }, [])

  const clear = () => {
    setError('')
  }

  return {
    loading,
    data,
    error,
    execute,
    clear,
  }
}
