import { productPriceCustomFields } from '../../../../../core/data/productPriceCustomFields'
import {
  TProductPrice,
  TProductPriceInstallmentFees,
} from '../../../../../core/types/Product'

export const productPriceDefault: TProductPrice = {
  id: '',
  value: undefined,
  firstPurchasePrice: undefined,
  paymentMethods: [],
  orderBumps: [],
  codeId: '',
  hideCheckoutAddress: false,
  disabled: false,
  disableDate: null,
  redirectProductPriceId: null,
  isDisabled: false,
  installmentFeesType: TProductPriceInstallmentFees.Customer,
  customFields: { ...productPriceCustomFields },
}

export const getProductPriceCodeBlock = (
  price: TProductPrice | null
) => `<script>
(function () {
    localStorage.setItem("codeId", "${price?.codeId}");
})();
</script>
<script
    defer="defer"
    src="${process.env.REACT_APP_STATIC_HOST}/bundle.js"
></script>
<div id="checkout-affiliatespay"></div>`
