export enum ProductSituationEnum {
  All = 0,
  Pending = 1,
  Approved = 2,
  Blocked = 3,
  Rejected = 4,
}

export interface TSituation {
  id: string
  reason: string
  isResolved: boolean
  situation: ProductSituationEnum
  situationDescription: string
  userName: string
  createdDate: Date
}

export interface TSituationRegister {
  situation: ProductSituationEnum
  reason?: string
}

export interface TSituationUpdate {
  reason: string
}
