import {
  // methodTypeBankTransfer,
  methodTypeBoleto,
  methodTypeCreditCard,
  // methodTypeDebitCard,
  methodTypePix,
} from '../constants'
import { IPayment } from '../types/Payment'

export const methodTypes: { data: IPayment[] } = {
  data: [
    {
      id: methodTypeCreditCard,
      code: 'CreditCard',
      name: 'Cartão de Crédito',
      hasInstallments: true,
    },
    // {
    //     id: methodTypeDebitCard,
    //     code: 'DebitCard',
    //     name: 'Cartão de Débito',
    //     hasInstallments: false,
    // },
    {
      id: methodTypePix,
      code: 'Pix',
      name: 'Pix',
      hasInstallments: false,
    },
    {
      id: methodTypeBoleto,
      code: 'Boleto',
      name: 'Boleto',
      hasInstallments: false,
    },
  ],
}
