import { ImageUploadType } from '../../components/ImageUpload/ImageUpload'
import { numberOnly } from '../../masks/masks'
import api, { getResponseError } from '../../services/api'
import { methodTypeCreditCard } from '../constants'
import { TCommissionRuleProduct } from '../types/CommissionRule'
import {
  TCoproducer,
  TCoproducerPagination,
  TCoproducerStatus,
} from '../types/Coproducer'
import { IErrorResponse, IResponse, TResponse } from '../types/ErrorResponse'
import {
  IProductInstallmentsProps,
  TProduct,
  TProductInstallment,
  TProductLink,
  TProductPagination,
  TProductPixel,
  TProductPrice,
  TProductPriceInstallmentFees,
  TProductSimple,
  TProductStatus,
} from '../types/Product'
import { ProductSituationEnum } from '../types/Situation'
import AffiliationController from './AffiliationController'

interface IGetProductProp {
  tenantId?: string
  page: number
  rows: number
  name?: string
  active: boolean | null
  status: TProductStatus
  productSituation: ProductSituationEnum | null
}

interface ICoproducerProp {
  page: number
  rows: number
  search?: string
  status?: TCoproducerStatus
}

const getAll = async ({
  tenantId,
  page,
  rows,
  name,
  active,
  status,
  productSituation,
}: IGetProductProp): Promise<TProductPagination | IErrorResponse> => {
  try {
    const producer = tenantId ? `/producer/${tenantId}` : ''
    let path = `/Product${producer}?PageNumber=${page}&RowsPerPage=${rows}&Active=${active}`

    if (active) {
      path += `&Status=${status}`
    }

    if (name && name !== '') {
      path += `&Name=${name}`
    }

    if (
      productSituation !== null &&
      productSituation !== ProductSituationEnum.All
    ) {
      path += `&Situation=${productSituation}`
    }
    const { data } = await api.get<TProductPagination>(path)
    if (data) {
      return data
    }

    return {
      code: 1,
      error: 'Erro inesperado do servidor',
    }
  } catch (error) {
    const e = getResponseError(error)
    return {
      code: 2,
      error: e,
    }
  }
}

const getList = async ({
  includeAffiliatedProducts = false,
}: {
  includeAffiliatedProducts?: boolean
}): Promise<TProductSimple[] | IErrorResponse> => {
  try {
    const { data } = await api.get<TProductSimple[]>(
      `/Product/list?includeAffiliatedProducts=${includeAffiliatedProducts}`
    )
    if (data) {
      return data.sort((a, b) => (a.name > b.name ? 1 : -1))
    }

    return {
      code: 1,
      error: 'Erro inesperado do servidor',
    }
  } catch (error) {
    const e = getResponseError(error)
    return {
      code: 2,
      error: e,
    }
  }
}

const getAffiliationList = async (): Promise<
  TCommissionRuleProduct[] | IErrorResponse
> => {
  try {
    const { data } = await api.get<TCommissionRuleProduct[]>(
      '/Product/productionAffiliations/list'
    )
    if (data) {
      return data
    }

    return {
      code: 1,
      error: 'Erro inesperado do servidor',
    }
  } catch (error) {
    const e = getResponseError(error)
    return {
      code: 2,
      error: e,
    }
  }
}

const getProductPixels = async ({
  productId,
  affiliatedCode,
}: {
  productId: string
  affiliatedCode?: string
}): Promise<TProductPixel[] | IErrorResponse> => {
  try {
    var url = `/ProductPixel/product/${productId}`

    if (affiliatedCode && affiliatedCode !== '') {
      url += `/affiliated/${affiliatedCode}`
    }

    const { data } = await api.get<TProductPixel[]>(url)
    if (data) {
      return data.map((d) => ({
        ...d,
        pixelDomain: (d.pixelDomain ?? '').startsWith('pixel.')
          ? (d.pixelDomain ?? '').replace('pixel.', '')
          : d.pixelDomain,
      }))
      //return data;
    }

    return {
      code: 1,
      error: 'Erro inesperado do servidor',
    }
  } catch (error) {
    const e = getResponseError(error)
    return {
      code: 2,
      error: e,
    }
  }
}

const getPricesByProduct = async ({
  productId,
}: {
  productId: string
}): Promise<TProductPrice[] | IErrorResponse> => {
  try {
    const { data } = await api.get<any[]>(`/Product/price/${productId}/list`)
    if (data) {
      const prices = data.map((p) => {
        p.customFields = p.customFields ? JSON.parse(p.customFields) : null
        return p
      })
      return prices as TProductPrice[]
    }

    return {
      code: 1,
      error: 'Erro inesperado do servidor',
    }
  } catch (error) {
    const e = getResponseError(error)
    return {
      code: 2,
      error: e,
    }
  }
}

const getOrderBumpPricesByProduct = async ({
  productId,
  orderBumpId,
}: {
  productId: string
  orderBumpId: string | null
}): Promise<TProductPrice[] | IErrorResponse> => {
  try {
    const obId = orderBumpId === 'new' ? null : orderBumpId
    var url = `/Product/price/${productId}/list/orderBumps`

    if (obId) {
      url += `?orderBumpId=${obId}`
    }
    const { data } = await api.get<TProductPrice[]>(url)
    if (data) {
      return data
    }

    return {
      code: 1,
      error: 'Erro inesperado do servidor',
    }
  } catch (error) {
    const e = getResponseError(error)
    return {
      code: 2,
      error: e,
    }
  }
}

const get = async ({
  id,
}: {
  id: string
}): Promise<TProduct | IErrorResponse> => {
  try {
    const { data } = await api.get<TProduct>(`/Product/${id}`)
    if (data) {
      var response = await getPricesByProduct({ productId: id })
      const err = response as IErrorResponse
      if (err.code) {
        return err
      }
      const prices = response as TProductPrice[]
      data.prices = prices

      return data
    }

    return {
      code: 1,
      error: 'Erro inesperado do servidor',
    }
  } catch (error) {
    const e = getResponseError(error)
    return {
      code: 2,
      error: e,
    }
  }
}

const insert = async ({ data }: { data: TProduct }): Promise<IResponse> => {
  try {
    data.assurance = !data.assurance ? null : data.assurance
    if (data.memberServiceSettingId === '') data.memberServiceSettingId = null

    const { data: response } = await api.post('/Product', data)
    if (response) {
      return {
        success: true,
        code: 0,
        error: '',
        data: response,
      }
    }

    return {
      success: false,
      code: 1,
      error: 'Erro inesperado do servidor',
    }
  } catch (error: any) {
    const e = getResponseError(error)
    return {
      success: false,
      code: 2,
      error: e,
    }
  }
}

const update = async ({ data }: { data: TProduct }): Promise<IResponse> => {
  try {
    const json = {
      id: data.id,
      memberServiceId: data.memberServiceId,
      name: data.name,
      description: data.description,
      approvedPageLink: data.approvedPageLink,
      awaitingPaymentPageLink: data.awaitingPaymentPageLink,
      awaitingCreditAnalysisPageLink: data.awaitingCreditAnalysisPageLink,
      enableAffiliateProgram: data.enableAffiliateProgram,
      assurance: !data.assurance ? null : data.assurance,
      status: data.status,
      memberServiceSettingId:
        data.memberServiceSettingId === '' ? null : data.memberServiceSettingId,
      voxuyPlanId: data.voxuyPlanId,
      voxuyEventId: data.voxuyEventId,
      externalLink: data.externalLink,
      accessUser: data.accessUser,
      accessPassword: data.accessPassword,
      supportEmail: data.supportEmail,
      delivery: data.delivery,
      deliveryUrl: data.deliveryUrl,
      statementDescription: data.statementDescription,
      productSaleLink: data.productSaleLink,
    }
    const { data: response } = await api.put('/Product', json)
    if (response) {
      return {
        success: true,
        code: 0,
        error: '',
      }
    }

    return {
      success: false,
      code: 1,
      error: 'Erro inesperado do servidor',
    }
  } catch (error) {
    const e = getResponseError(error)
    return {
      success: false,
      code: 2,
      error: e,
    }
  }
}

const upload = async (
  itemId: string,
  uploadType: ImageUploadType,
  file: File
): Promise<IResponse> => {
  try {
    const formData = new FormData()
    formData.append('file', file)
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    }
    const { data: response } = await api.post(
      `/Product/${itemId}/${uploadType}/upload`,
      formData,
      config
    )
    if (response) {
      return {
        success: true,
        code: 0,
        error: '',
      }
    }

    return {
      success: false,
      code: 1,
      error: 'Erro inesperado do servidor',
    }
  } catch (error: any) {
    const e = getResponseError(error)
    return {
      success: false,
      code: 2,
      error: e,
    }
  }
}

const insertOrUpdate = async ({
  data,
  deletedIds,
}: {
  data: TProduct
  deletedIds: string[]
}): Promise<IResponse> => {
  var response
  const { imageCover, imageProduct, prices, ...product } = data
  const newProduct = !product.id || product.id === null || product.id === ''

  if (newProduct) {
    response = await insert({ data: product })
    product.id = response.data as string
  } else {
    response = await update({ data: product })
  }

  if (!response.success) {
    return response
  } else {
    if (imageCover && imageCover !== null) {
      response = await upload(product.id, ImageUploadType.Cover, imageCover)
      if (!response.success) {
        return response
      }
    }

    if (imageProduct && imageProduct !== null) {
      response = await upload(product.id, ImageUploadType.Product, imageProduct)
      if (!response.success) {
        return response
      }
    }

    if (prices) {
      await Promise.all(
        prices.map(async (price: any) => {
          price.productId = product.id
          if (price.id === '') {
            response = await insertPrice({ data: price })
          } else {
            response = await updatePrice({ data: price })
          }

          if (!response.success) {
            return response
          }
        })
      )
    }

    await Promise.all(
      deletedIds.map(async (priceId: string) => {
        response = await deletePrice({ priceId })
        if (!response.success) {
          return response
        }
      })
    )

    if (
      data.affiliate &&
      data.affiliate.supportPhone &&
      data.affiliate.category
    ) {
      data.affiliate.productId = product.id
      data.affiliate.supportPhone = numberOnly(data.affiliate.supportPhone)

      if (!data.affiliate.id || data.affiliate.id === '') {
        response = await AffiliationController.insertProduct({
          data: data.affiliate,
        })
      } else {
        response = await AffiliationController.updateProduct({
          data: data.affiliate,
        })
      }
      if (!response.success) {
        return response
      }
    }
  }

  return {
    success: true,
    code: 0,
    error: '',
    data: product.id,
  }
}

const deleteProduct = async ({ id }: { id: string }): Promise<IResponse> => {
  try {
    const { data: response } = await api.delete(`/Product/${id}`)
    if (response) {
      return {
        success: true,
        code: 0,
        error: '',
      }
    }

    return {
      success: false,
      code: 1,
      error: 'Erro inesperado do servidor',
    }
  } catch (error: any) {
    const e = getResponseError(error)
    return {
      success: false,
      code: 2,
      error: e,
    }
  }
}

const insertPrice = async ({
  data,
}: {
  data: TProductPrice
}): Promise<IResponse> => {
  try {
    const methodCreditCard = data.paymentMethods.find(
      (p) => p.methodType === methodTypeCreditCard
    )
    const isInstallmentFees = [
      TProductPriceInstallmentFees.Producer,
      TProductPriceInstallmentFees.Hybrid,
    ].includes(data.installmentFeesType)

    const endInstallmentFees = isInstallmentFees
      ? methodCreditCard?.installmentsNumber
      : undefined

    const json = {
      productId: data.productId,
      value: Number(data.value || 0),
      firstPurchasePrice: data.firstPurchasePrice || null,
      frequency: data.frequency,
      paymentMethods: data.paymentMethods,
      orderBumps: data.orderBumps || [],
      hideCheckoutAddress: data.hideCheckoutAddress,
      offerName: data.offerName,
      cycleNumbers: data.cycleNumbers,
      installmentFeesType: data.installmentFeesType,
      maxInstallmentsForProducer: data.endInstallmentFees ?? endInstallmentFees,
      customFields: JSON.stringify(data.customFields),
    }
    const { data: response } = await api.post('/Product/price', json)
    if (response) {
      return {
        success: true,
        code: 0,
        error: '',
      }
    }

    return {
      success: false,
      code: 1,
      error: 'Erro inesperado do servidor',
    }
  } catch (error: any) {
    const e = getResponseError(error)
    return {
      success: false,
      code: 2,
      error: e,
    }
  }
}

const updatePrice = async ({
  data,
}: {
  data: TProductPrice
}): Promise<IResponse> => {
  try {
    const methodCreditCard = data.paymentMethods.find(
      (p) => p.methodType === methodTypeCreditCard
    )
    const isInstallmentFees = [
      TProductPriceInstallmentFees.Producer,
      TProductPriceInstallmentFees.Hybrid,
    ].includes(data.installmentFeesType)

    const endInstallmentFees = isInstallmentFees
      ? methodCreditCard?.installmentsNumber
      : undefined

    const json = {
      id: data.id,
      value: Number(data.value || 0),
      firstPurchasePrice: data.firstPurchasePrice || null,
      frequency: data.frequency,
      paymentMethods: data.paymentMethods,
      orderBumps: data.orderBumps || [],
      hideCheckoutAddress: data.hideCheckoutAddress,
      offerName: data.offerName,
      cycleNumbers: data.cycleNumbers,
      installmentFeesType: data.installmentFeesType,
      maxInstallmentsForProducer: data.endInstallmentFees ?? endInstallmentFees,
      customFields: JSON.stringify(data.customFields),
    }
    const { data: response } = await api.put('/Product/price', json)
    if (response) {
      return {
        success: true,
        code: 0,
        error: '',
      }
    }

    return {
      success: false,
      code: 1,
      error: 'Erro inesperado do servidor',
    }
  } catch (error: any) {
    const e = getResponseError(error)
    return {
      success: false,
      code: 2,
      error: e,
    }
  }
}

const deletePrice = async ({
  priceId,
}: {
  priceId: string
}): Promise<IResponse> => {
  try {
    const { data: response } = await api.delete(`/Product/price/${priceId}`)
    if (response) {
      return {
        success: true,
        code: 0,
        error: '',
      }
    }

    return {
      success: false,
      code: 1,
      error: 'Erro inesperado do servidor',
    }
  } catch (error: any) {
    const e = getResponseError(error)
    return {
      success: false,
      code: 2,
      error: e,
    }
  }
}

const disablePrice = async ({
  priceId,
  redirectProductPriceId,
  disableDate,
}: {
  priceId: string
  redirectProductPriceId: string | null
  disableDate?: Date | null
}): Promise<IResponse> => {
  try {
    const json = {
      id: priceId,
      disabled: disableDate === null || disableDate === undefined,
      redirectProductPriceId,
      disableDate,
    }
    const { data: response } = await api.put(
      `/Product/price/${priceId}/disable`,
      json
    )
    if (response) {
      return {
        success: true,
        code: 0,
        error: '',
      }
    }

    return {
      success: false,
      code: 1,
      error: 'Erro inesperado do servidor',
    }
  } catch (error: any) {
    const e = getResponseError(error)
    return {
      success: false,
      code: 2,
      error: e,
    }
  }
}

const enablePrice = async ({
  priceId,
}: {
  priceId: string
}): Promise<IResponse> => {
  try {
    const json = {
      id: priceId,
      disabled: false,
      redirectProductPriceId: null,
      disableDate: null,
    }
    const { data: response } = await api.put(
      `/Product/price/${priceId}/enable`,
      json
    )
    if (response) {
      return {
        success: true,
        code: 0,
        error: '',
      }
    }

    return {
      success: false,
      code: 1,
      error: 'Erro inesperado do servidor',
    }
  } catch (error: any) {
    const e = getResponseError(error)
    return {
      success: false,
      code: 2,
      error: e,
    }
  }
}

const getPaymentByPrice = async ({
  priceId,
}: {
  priceId: string
}): Promise<TProductPrice | IErrorResponse> => {
  try {
    const { data } = await api.get<TProductPrice>(`/Order/price/${priceId}`)
    // const { data } = paymentsByPrice;
    if (data) {
      return data
    }

    return {
      code: 1,
      error: 'Erro inesperado do servidor',
    }
  } catch (error) {
    const e = getResponseError(error)
    return {
      code: 2,
      error: e,
    }
  }
}

const insertCoproducer = async ({
  data,
}: {
  data: TCoproducer
}): Promise<IResponse> => {
  try {
    data.affiliatedCommissionPercentage = Number(
      data.affiliatedCommissionPercentage || 0
    )
    const { data: response } = await api.post('/Product/coproducer', data)
    if (response) {
      return {
        success: true,
        code: 0,
        error: '',
      }
    }

    return {
      success: false,
      code: 1,
      error: 'Erro inesperado do servidor',
    }
  } catch (error: any) {
    const e = getResponseError(error)
    return {
      success: false,
      code: 2,
      error: e,
    }
  }
}

const updateCoproducer = async ({
  data,
}: {
  data: TCoproducer
}): Promise<IResponse> => {
  try {
    data.affiliatedCommissionPercentage = Number(
      data.affiliatedCommissionPercentage || 0
    )
    const { data: response } = await api.put('/Product/coproducer', data)
    if (response) {
      return {
        success: true,
        code: 0,
        error: '',
      }
    }

    return {
      success: false,
      code: 1,
      error: 'Erro inesperado do servidor',
    }
  } catch (error: any) {
    const e = getResponseError(error)
    return {
      success: false,
      code: 2,
      error: e,
    }
  }
}

const cancelCoproducer = async ({
  coproducerId,
}: {
  coproducerId: string
}): Promise<IResponse> => {
  try {
    const { data: response } = await api.put(
      `/Product/coproducer/${coproducerId}/cancel`
    )
    if (response) {
      return {
        success: true,
        code: 0,
        error: '',
      }
    }

    return {
      success: false,
      code: 1,
      error: 'Erro inesperado do servidor',
    }
  } catch (error: any) {
    const e = getResponseError(error)
    return {
      success: false,
      code: 2,
      error: e,
    }
  }
}

const getCoproducer = async ({
  page,
  rows,
  search,
  status,
}: ICoproducerProp): Promise<TCoproducerPagination | IErrorResponse> => {
  try {
    var path = `/Product/coproducer?PageNumber=${page}&RowsPerPage=${rows}`

    if (search && search !== '') {
      path += `&Search=${search}`
    }

    if (status != undefined && status !== TCoproducerStatus.All) {
      path += `&Status=${status}`
    }

    const { data } = await api.get<TCoproducerPagination>(path)
    if (data) {
      return data
    }

    return {
      code: 1,
      error: 'Erro inesperado do servidor',
    }
  } catch (error) {
    const e = getResponseError(error)
    return {
      code: 2,
      error: e,
    }
  }
}

const insertPixel = async ({
  data,
}: {
  data: TProductPixel
}): Promise<IResponse> => {
  try {
    if (data.enabledDomain) {
      const domain = data.pixelDomain ?? ''
      if (domain !== '' && !domain.startsWith('pixel.')) {
        data.pixelDomain = `pixel.${domain}`
      }
    }
    const response = await api.post('/ProductPixel', data)
    return {
      success: true,
      code: 0,
      error: '',
      data: response.data,
    }
  } catch (error: any) {
    const e = getResponseError(error)
    return {
      success: false,
      code: 2,
      error: e,
    }
  }
}

const updatePixel = async ({
  data,
}: {
  data: TProductPixel
}): Promise<IResponse> => {
  try {
    if (data.enabledDomain) {
      const domain = data.pixelDomain ?? ''
      if (domain !== '' && !domain.startsWith('pixel.')) {
        data.pixelDomain = `pixel.${domain}`
      }
    }
    const { data: response } = await api.put('/ProductPixel', data)
    if (response) {
      return {
        success: true,
        code: 0,
        error: '',
        data: response,
      }
    }

    return {
      success: false,
      code: 1,
      error: 'Erro inesperado do servidor',
    }
  } catch (error: any) {
    const e = getResponseError(error)
    return {
      success: false,
      code: 2,
      error: e,
    }
  }
}

const removePixel = async ({
  pixelId,
}: {
  pixelId: string
}): Promise<IResponse> => {
  try {
    await api.delete(`/ProductPixel/${pixelId}`)
    return {
      success: true,
      code: 0,
      error: '',
    }
  } catch (error: any) {
    const e = getResponseError(error)
    return {
      success: false,
      code: 2,
      error: e,
    }
  }
}

const verifyDomain = async (
  domain: string
): Promise<TResponse<{ cnameVerified: boolean }>> => {
  try {
    const response = await api.get(`/ProductPixel/domains/${domain}`)
    if (response.data) {
      return {
        success: true,
        code: 0,
        error: '',
        data: response.data,
      }
    }
    return {
      success: false,
      code: 1,
      error: 'Erro não identificado',
    }
  } catch (error: any) {
    const e = getResponseError(error)
    return {
      success: false,
      code: 2,
      error: e,
    }
  }
}

const getLinksByProduct = async ({
  productId,
}: {
  productId: string
}): Promise<TProductLink[] | IErrorResponse> => {
  try {
    const { data } = await api.get<TProductLink[]>(
      `/Product/${productId}/links`
    )
    if (data) {
      return data
    }

    return {
      code: 1,
      error: 'Erro inesperado do servidor',
    }
  } catch (error) {
    const e = getResponseError(error)
    return {
      code: 2,
      error: e,
    }
  }
}

const insertLink = async ({
  data,
}: {
  data: TProductLink
}): Promise<IResponse> => {
  try {
    await api.post('/Product/link', data)
    return {
      success: true,
      code: 0,
      error: '',
    }
  } catch (error: any) {
    const e = getResponseError(error)
    return {
      success: false,
      code: 2,
      error: e,
    }
  }
}

const updateLink = async ({
  data,
}: {
  data: TProductLink
}): Promise<IResponse> => {
  try {
    const { data: response } = await api.put('/Product/link', data)
    if (response) {
      return {
        success: true,
        code: 0,
        error: '',
      }
    }

    return {
      success: false,
      code: 1,
      error: 'Erro inesperado do servidor',
    }
  } catch (error: any) {
    const e = getResponseError(error)
    return {
      success: false,
      code: 2,
      error: e,
    }
  }
}

const removeLink = async ({
  productLinkId,
}: {
  productLinkId: string
}): Promise<IResponse> => {
  try {
    await api.delete(`/Product/link/${productLinkId}`)
    return {
      success: true,
      code: 0,
      error: '',
    }
  } catch (error: any) {
    const e = getResponseError(error)
    return {
      success: false,
      code: 2,
      error: e,
    }
  }
}

const calculeIsntallments = async ({
  maxInstallment,
  value,
  maxInstallmentForProducer,
}: IProductInstallmentsProps): Promise<
  TProductInstallment[] | IErrorResponse
> => {
  try {
    const { data } = await api.get(`/Product/price/installments`, {
      params: {
        MaxInstallment: maxInstallment,
        Value: value,
        MaxInstallmentForProducer: maxInstallmentForProducer,
        IncludePlataformFee: true,
      },
    })
    if (data) {
      return data
    }

    return {
      code: 1,
      error: 'Erro inesperado do servidor',
    }
  } catch (error: any) {
    const e = getResponseError(error)
    return {
      code: 2,
      error: e,
    }
  }
}

const ProductController = {
  getAll,
  getList,
  get,
  insert,
  update,
  upload,
  insertOrUpdate,
  deleteProduct,
  insertPrice,
  updatePrice,
  deletePrice,
  disablePrice,
  enablePrice,
  getPaymentByPrice,
  getPricesByProduct,
  getOrderBumpPricesByProduct,
  insertCoproducer,
  updateCoproducer,
  cancelCoproducer,
  getCoproducer,
  getAffiliationList,
  getProductPixels,
  insertPixel,
  updatePixel,
  removePixel,
  verifyDomain,
  getLinksByProduct,
  insertLink,
  updateLink,
  removeLink,
  calculeIsntallments,
}

export default ProductController
