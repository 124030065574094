import {
  Box,
  Button,
  Stack,
  Typography,
  Slide,
  Tabs,
  Tab,
  Popover,
  Alert,
  AlertTitle,
} from '@mui/material'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import {
  TProduct,
  TProductAssurance,
  TProductDelivery,
  TProductFrequency,
  TProductLink,
  TProductLinkCategory,
  TProductLinkDuration,
  TProductStatus,
  TProductTypePayment,
} from '../../../core/types/Product'
import { useProduct } from '../../../core/hooks/useProduct'
import ProductController from '../../../core/controllers/ProductController'
import { useProductOrderBumps } from '../../../core/hooks/useProductOrderBumps'
import { MultiSelectedItem } from '../../../components/Dialogs/MultiSelectedDialog'
import ProductGeneral from './Tabs/General'
import ProductPrices from './Tabs/Prices'
import ProductCoproducers from './Tabs/Coproducers'
import ProductAffiliates, { productLinkDefault } from './Tabs/Affiliates'
import NumberFunctions from '../../../core/functions/NumberFunctions'
import { useCurrentTenantBank } from '../../../core/hooks/useCurrentTenantBank'
import { AuthContext } from '../../../core/context/AuthContext'
import { decodeToken } from 'react-jwt'
import { TAuthTenant } from '../../../core/types/AuthTenant'
import { useQuery } from '../../../components/Query/useQuery'
import ConfirmDialog from '../../../components/Dialogs/ConfirmDialog'
import Hosts from '../../../core/functions/hosts'
import SuccessSnackbar from '../../../components/Snackbar/SuccessSnackbar'
import LoadingBackdrop from '../../../components/Loading/LoadingBackdrop'
import ErrorSnackbar from '../../../components/Snackbar/ErrorSnackbar'
import CopiedSnackbar from '../../../components/Snackbar/CopiedSnackbar'
import { useMemberServiceList } from '../../../core/hooks/useMemberServiceList'
import ProductPixels from './Tabs/Pixels'
import { useProductLinks } from '../../../core/hooks/useProductLinks'
import Validations from '../../../core/functions/validations'
import { methodTypeCreditCard, methodTypePix } from '../../../core/constants'
import ProductFunctions from '../../../core/functions/ProductFunctions'
import ProductCustomsCheckout from './Tabs/CustomsCheckout'
import ProductVouchers from './Tabs/Vouchers'
import SimpleCard from '../../../components/Cards/SimpleCard'
import ContainerBox from '../../../components/Divs/ContainerBox'
import { TUserType } from '../../../core/types/Auth'
import Situation from './Tabs/Situation'
import { useSituationService } from '../../../core/hooks/useSituationActions'
import { dateTimeString } from '../../../core/functions/dateTime'
import ProductSituationController from '../../../core/controllers/ProductSituationController'
import { ProductSituationEnum } from '../../../core/types/Situation'
import { TenantController } from '../../../core/controllers/TenantController'
import { ITenant } from '../../../core/types/Tenant'
import MemberClassModal from '../../Apps/MemberClass'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ pt: 2 }}>{children}</Box>}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
    sx: {
      textTransform: 'none',
      fontSize: '14px',
      fontWeight: 600,
      lineHeight: '18px',
    },
  }
}

const ProductPage: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const newProduct = id === 'new'

  const { user } = useContext(AuthContext)
  const isAdmin = user?.UserType === TUserType.SystemAdmin

  const json = decodeToken(user?.token ?? '')
  const authTenant = json as TAuthTenant

  const query = useQuery()

  const typePaymentQuery =
    query.get('type') === 'RecurringSubscription'
      ? TProductTypePayment.RecurringSubscription
      : TProductTypePayment.OneTimePayment

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const [currentLink, setCurrentLink] =
    React.useState<TProductLink>(productLinkDefault)

  const [productOrderBumps, setProductOrderBumps] = React.useState<
    MultiSelectedItem[]
  >([])

  const [animationState, setAnimationState] = React.useState(false)
  const [sending, setSending] = React.useState(false)
  const [success, setSuccess] = React.useState(false)
  const [copied, setCopied] = React.useState(false)
  const [openConfirmDelete, setOpenConfirmeDelete] = React.useState(false)
  const [deleted, setDeleted] = React.useState(false)

  const [tabIndex, setTabIndex] = React.useState(0)

  const [error, setError] = React.useState('')
  const [errorIdentifier, setErrorIdentifier] = React.useState<
    string | undefined
  >()
  const [errorUrl, setErrorUrl] = React.useState<string | undefined>()

  const [errorSendingProductSituation, setErrorSendingProductSituation] =
    useState('')
  const [approvedMessage, setApprovedMessage] = useState('')
  const [successProductSituation, setSuccessProductSituation] = useState(false)

  const saveProductId = useRef('')

  const [selectedOption, setSelectedOption] = React.useState<string>('')

  const [windowSize, setWindowSize] = React.useState([
    window.outerWidth,
    window.outerHeight,
  ])

  const [currentUser, setCurrentUser] = useState<ITenant | null>(null)
  const [openMemberClass, setOpenMemberClass] = useState(false)
  const handleClose = () => setOpenMemberClass(false)

  const {
    loading: loadingSituation,
    data: dataSituation,
    error: errorSituation,
    execute: executeSituation,
    clear,
  } = useSituationService({
    productId: id || '',
  })

  const navigate = useNavigate()

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue)
  }

  const getCurrentUser = async () => {
    try {
      const response = await TenantController.getByCurrentUser()
      setCurrentUser(response as ITenant)
    } catch (error) {
      console.error('Error fetching currentUSer data:', error)
    }
  }

  useEffect(() => {
    getCurrentUser()
  }, [])

  const handleChangeSelectedOption = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value
    if (value === 'club' && currentUser?.enableMemberClass === false) {
      setOpenMemberClass(true)
    } else if (
      value === 'external' ||
      value === 'integration' ||
      value === 'club'
    ) {
      setSelectedOption(value)
    }
  }

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    watch,
    setValue,
    setFocus,
    setError: setErrorForm,
  } = useForm<TProduct>({
    defaultValues: {
      id: newProduct ? undefined : id,
      name: '',
      description: '',
      memberServiceId: '',
      approvedPageLink: '',
      awaitingPaymentPageLink: '',
      awaitingCreditAnalysisPageLink: '',
      imageCover: undefined,
      imageProduct: undefined,
      enableAffiliateProgram: false,
      prices: [],
      coproducers: [],
      affiliate: null,
      typePayment: typePaymentQuery,
      status: TProductStatus.Active,
      memberServiceSettingId: '',
      assurance: TProductAssurance.Seven,
    },
  })

  const { data: memberService, execute: exceuteMemberService } =
    useMemberServiceList()
  const { data, loading, execute } = useProduct({ id: id || '' })
  const { data: orderBumps, loading: loadingOrderBumps } = useProductOrderBumps(
    {
      typePayment: watch('typePayment'),
    }
  )
  const { data: tenantBank } = useCurrentTenantBank({
    tenantId: authTenant.TenantId,
  })
  const {
    data: links,
    loading: loadLinks,
    execute: getLinks,
  } = useProductLinks({ productId: id || '' })

  const deliveryValue = watch('delivery')

  React.useEffect(() => {
    setAnimationState(true)

    window.addEventListener('resize', (ev) => {
      setWindowSize([window.outerWidth, window.outerHeight])
    })

    return () => setAnimationState(false)
  }, [])

  React.useEffect(() => {
    if (data !== null) {
      reset(data)
    }
    return () => {}
  }, [data, reset])

  React.useEffect(() => {
    var multiSelecteds: MultiSelectedItem[] = []
    orderBumps.map((orderBump) => {
      if (orderBump.productPrice?.product?.id !== id) {
        multiSelecteds.push({
          id: orderBump.id,
          name: `${orderBump.productPrice?.product?.name || ''} - ${NumberFunctions.formatMoneyDefault(
            orderBump.productPrice?.value
          )}`,
          selected: false,
          object: orderBump,
        })
      }
    })
    multiSelecteds = multiSelecteds.sort((a, b) => (a.name > b.name ? 1 : -1))

    setProductOrderBumps(multiSelecteds)
    return () => setProductOrderBumps(multiSelecteds)
  }, [orderBumps])

  React.useEffect(() => {
    if (data !== null) {
      if (data.memberServiceSettingId) {
        const MemberClassId = memberService.find(
          (element) =>
            element.identifier === 'Celetus Club' &&
            element.id === data.memberServiceSettingId
        )

        setSelectedOption(MemberClassId ? 'club' : 'integration')
      } else {
        setSelectedOption('external')
      }
    }
  }, [data])

  const Submit = async (data: TProduct) => {
    var errorsValidation = false

    if (!data.productSaleLink || data.productSaleLink === '') {
      setErrorForm('productSaleLink', {
        message: 'Campo obrigatório',
        type: 'required',
      })
      setFocus('productSaleLink')
      errorsValidation = true
    } else if (!Validations.URL(data.productSaleLink)) {
      setErrorForm('productSaleLink', {
        message: 'URL inválida',
        type: 'invalidURL',
      })
      setFocus('productSaleLink')
      errorsValidation = true
    }

    if (deliveryValue === TProductDelivery.Course) {
      if (selectedOption === 'external') {
        if (!data.externalLink || data.externalLink === '') {
          setErrorForm('externalLink', {
            message: 'Campo obrigatório',
            type: 'required',
          })
          setFocus('externalLink')
          errorsValidation = true
        } else if (!Validations.URL(data.externalLink)) {
          setErrorForm('externalLink', {
            message: 'URL inválida',
            type: 'invalidURL',
          })
          setFocus('externalLink')
          errorsValidation = true
        }
        if (!data.accessUser || data.accessUser === '') {
          setErrorForm('accessUser', {
            message: 'Campo obrigatório',
            type: 'required',
          })
          setFocus('accessUser')
          errorsValidation = true
        } else if (!Validations.email(data.accessUser)) {
          setErrorForm('accessUser', {
            message: 'E-mail inválido',
            type: 'invalidEmail',
          })
          setFocus('accessUser')
          errorsValidation = true
        }
        if (!data.accessPassword || data.accessPassword === '') {
          setError('Campo obrigatório')
          setFocus('accessPassword')
          errorsValidation = true
        }
      }

      if (selectedOption === 'integration') {
        if (
          !data.memberServiceSettingId ||
          data.memberServiceSettingId === ''
        ) {
          setTabIndex(0)
          setFocus('memberServiceSettingId')
          setError('É necessário informar uma integração com o Cademí')
          return
        }
      }
    }

    if (deliveryValue === TProductDelivery.eBook) {
      if (!data.deliveryUrl || data.deliveryUrl === '') {
        setErrorForm('deliveryUrl', {
          message: 'Campo obrigatório',
          type: 'required',
        })
        setFocus('deliveryUrl')
        errorsValidation = true
      } else if (!Validations.URL(data.deliveryUrl)) {
        setErrorForm('deliveryUrl', {
          message: 'URL inválida',
          type: 'invalidURL',
        })
        setFocus('deliveryUrl')
        errorsValidation = true
      }
    }

    if (data.prices) {
      data.prices.map((price) => {
        if (!price.value || price.value < 5) {
          setTabIndex(1)
          setError(
            `O preço do produto precisa ser no mínimo de ${NumberFunctions.formatMoneyDefault(5)}`
          )
          errorsValidation = true
          return
        }
        if (
          price.paymentMethods === undefined ||
          price.paymentMethods.length <= 0
        ) {
          setTabIndex(1)
          setError(
            `O preço ${NumberFunctions.formatMoneyDefault(
              price.value
            )} do produto precisa ter pelo menos uma forma de pagamento`
          )
          errorsValidation = true
          return
        }
        if (data.typePayment === TProductTypePayment.RecurringSubscription) {
          if (!price.frequency || price.frequency === null) {
            setTabIndex(1)
            setError(
              `O preço ${NumberFunctions.formatMoneyDefault(
                price.value
              )} do produto precisa selecionar uma frequência de cobrança`
            )
            errorsValidation = true
            return
          }

          if (price.frequency !== TProductFrequency.Yearly) {
            price.paymentMethods = price.paymentMethods.filter((pm) =>
              [methodTypeCreditCard, methodTypePix].includes(pm.methodType)
            )
          }
        }

        const payment = price.paymentMethods.find(
          (p) => p.methodType === methodTypeCreditCard
        )
        if (payment) {
          const total = ProductFunctions.maxPaymentInstallment(
            data.typePayment,
            price.frequency,
            price.value
          )

          if (payment.installmentsNumber > total) {
            payment.installmentsNumber = total
          }
        }
      })
    }

    if (data.enableAffiliateProgram === true) {
      if (links.length <= 0) {
        currentLink.identifier = 'Página de Venda'
        currentLink.category = TProductLinkCategory.SalesPage

        if (currentLink.duration === TProductLinkDuration.Unknown) {
          currentLink.duration = null
        }

        if (currentLink.url.trim() === '') {
          setErrorUrl('Campo obrigatório')
          errorsValidation = true
        } else if (!Validations.URL(currentLink.url)) {
          setErrorUrl('URL inválida')
          errorsValidation = true
        }

        if (errorsValidation) {
          setTabIndex(id === 'new' ? 2 : 3)
          return
        }
      }

      if (!data.affiliate?.category) {
        setTabIndex(id === 'new' ? 2 : 3)
        setError('É necessário informar a categoria na aba afiliados')
        return
      }

      if (data.affiliate.supportPhone.trim() === '') {
        setTabIndex(id === 'new' ? 2 : 3)
        setError('É necessário informar o telefone de suporte para o afiliado')
        return
      }

      if (
        data.affiliate.exposeOnMarketplace !== null &&
        data.affiliate.exposeOnMarketplace === true &&
        data.affiliate.description !== null &&
        data.affiliate.description === ''
      ) {
        setTabIndex(id === 'new' ? 2 : 3)
        setError(
          'É necessário informar a descrição para o afiliado no marketplace'
        )
        return
      }

      if (!data.affiliate.commissionPercentage) {
        setTabIndex(id === 'new' ? 2 : 3)
        setError('É necessário informar a comissão do affiliado')
        return
      }

      if (data.affiliate.commissionPercentage <= 0) {
        setTabIndex(id === 'new' ? 2 : 3)
        setError('A comissão do affiliado precisa ser maior que 0%')
        return
      }

      if (data.affiliate.commissionPercentage > 99) {
        setTabIndex(id === 'new' ? 2 : 3)
        setError('A comissão do affiliado precisa sem menor que 99%')
        return
      }

      data.affiliate.globalCommissionPercentage =
        data.affiliate.globalCommissionPercentage || 0
      if (data.affiliate.globalCommissionPercentage > 99) {
        setTabIndex(id === 'new' ? 2 : 3)
        setError('A comissão global do affiliado precisa sem menor que 99%')
        return
      }
    }

    if (errorsValidation) {
      return
    }

    if (selectedOption === 'external') {
      data.memberServiceSettingId = ''
    } else {
      data.externalLink = ''
      data.accessUser = ''
      data.accessPassword = ''
    }

    if (selectedOption === 'club') {
      const MemberClassId = memberService.find(
        (element) => element.identifier === 'Celetus Club'
      )

      data.memberServiceSettingId = MemberClassId?.id
    }

    setSending(true)
    setSuccess(false)
    setError('')
    try {
      const response = await ProductController.insertOrUpdate({
        data,
        deletedIds: [],
      })
      if (!response.success) {
        setError(response.error)
      } else {
        saveProductId.current = response.data

        if (links.length <= 0) {
          if (id && id !== '' && id !== 'new') {
            currentLink.productId = id
          } else {
            currentLink.productId = saveProductId.current
          }
          await ProductController.insertLink({ data: currentLink })
        }

        setSuccess(true)
        execute()
        getLinks()
      }
    } finally {
      setSending(false)
    }
  }

  const handleDeleted = (priceId: string) => {
    ProductController.deletePrice({ priceId })
  }

  const handleProductOrderBumps = (items: MultiSelectedItem[]) => {
    setProductOrderBumps(items)
  }

  const handleClickCopiedPopover = async () => {
    await Hosts.copyTextToClipboard(data?.memberServiceId || '')
    handleClosePopover()
    setCopied(true)
  }

  const handleClickOpenPopover = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    if (newProduct) return
    setAnchorEl(event.currentTarget)
  }

  const handleClosePopover = () => {
    setAnchorEl(null)
  }

  const handleDelete = () => {
    setOpenConfirmeDelete(true)
  }

  const closeDelete = () => {
    setOpenConfirmeDelete(false)
  }

  const deleteProduct = async () => {
    setSending(true)
    try {
      const response = await ProductController.deleteProduct({ id: id || '' })
      if (!response.success) {
        setError(response.error)
        return
      }

      setDeleted(true)
    } finally {
      setSending(false)
    }
  }

  const openPopover = Boolean(anchorEl)
  const idPopover = openPopover ? 'simple-popover' : undefined

  const unresolvedItems = dataSituation.filter(
    (item) =>
      !item.isResolved && item.situation === ProductSituationEnum.Rejected
  )

  const handleResolve = async () => {
    setSending(true)
    try {
      for (const item of unresolvedItems) {
        const response = await ProductSituationController.resolved({
          productSituationActionId: item.id,
        })

        if (!response.success) {
          setErrorSendingProductSituation('Falha ao resolver um dos itens.')
          return
        }
      }

      setSuccessProductSituation(true)
      setApprovedMessage('Todos os itens foram resolvidos')
      executeSituation()
    } catch (error) {
      setErrorSendingProductSituation('Erro inesperado ao resolver itens')
    } finally {
      setSending(false)
    }
  }

  const handleAcceptMemberClass = async () => {
    await exceuteMemberService()
    await getCurrentUser()
  }

  return (
    <ContainerBox>
      <Stack mb={2}>
        {dataSituation
          .filter(
            (item) =>
              !item.isResolved &&
              item.situation === ProductSituationEnum.Rejected
          )
          .map((item) => (
            <Alert key={item.id} severity="warning" sx={{ mb: 1 }}>
              <AlertTitle>{`${item.situationDescription} - ${dateTimeString(item.createdDate)}`}</AlertTitle>
              {item.reason}
            </Alert>
          ))}

        {unresolvedItems.length > 0 && !isAdmin && (
          <Stack direction="row" justifyContent="flex-end">
            <Button variant="contained" color="success" onClick={handleResolve}>
              Resolvido
            </Button>
          </Stack>
        )}
      </Stack>

      <ErrorSnackbar
        open={errorSendingProductSituation !== ''}
        onClose={() => setErrorSendingProductSituation('')}
      >
        {errorSendingProductSituation}
      </ErrorSnackbar>

      <SuccessSnackbar
        open={successProductSituation}
        onClose={() => setSuccessProductSituation(false)}
      >
        {approvedMessage}
      </SuccessSnackbar>

      <Box sx={{ width: '100%', margin: '0 auto' }}>
        <Slide direction="up" in={animationState} mountOnEnter unmountOnExit>
          <Box
            pb={2}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flexBasis: 1,
              justifyContent: 'center',
              alignItems: 'center',
              margin: '0 auto',
            }}
            width="100%"
          >
            <Stack
              component={'form'}
              noValidate
              direction={'column'}
              spacing={3}
              sx={{ width: '100%' }}
              onSubmit={handleSubmit(Submit)}
            >
              <Box sx={{ width: '100%' }}>
                <SimpleCard sx={{ p: 0 }}>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    sx={{ px: 1 }}
                  >
                    <Stack
                      direction="row"
                      width="100%"
                      sx={{
                        maxWidth: {
                          xs: windowSize[0] - 20,
                        },
                      }}
                    >
                      <Tabs
                        value={tabIndex}
                        onChange={handleChange}
                        variant="scrollable"
                        scrollButtons="auto"
                        aria-label="basic tabs example"
                        sx={{ width: '100%' }}
                      >
                        <Tab label="Geral" {...a11yProps(0)} />
                        <Tab label="Ofertas" {...a11yProps(1)} />
                        {id !== 'new' && (
                          <Tab label="Coprodutores" {...a11yProps(2)} />
                        )}
                        <Tab label="Afiliados" {...a11yProps(3)} />
                        {id !== 'new' && (
                          <Tab label="Pixels" {...a11yProps(4)} />
                        )}
                        {id !== 'new' && (
                          <Tab label="Checkout" {...a11yProps(5)} />
                        )}
                        {id !== 'new' && (
                          <Tab label="Cupons" {...a11yProps(6)} />
                        )}
                        {id !== 'new' && isAdmin && (
                          <Tab label="Situação" {...a11yProps(7)} />
                        )}
                        <Stack
                          direction="row"
                          alignItems="center"
                          justifyContent="end"
                          //width="100%"
                          sx={{ flexGrow: 1 }}
                        >
                          {!isAdmin && (
                            <Button
                              variant="text"
                              color="error"
                              onClick={handleDelete}
                              sx={{
                                textTransform: 'none',
                                fontWeight: 600,
                                fontSize: '14px',
                                lineHeight: '18px',
                              }}
                            >
                              Excluir
                            </Button>
                          )}
                        </Stack>
                      </Tabs>
                    </Stack>
                  </Stack>
                </SimpleCard>

                <TabPanel value={tabIndex} index={0}>
                  <ProductGeneral
                    control={control}
                    errors={errors}
                    memberService={memberService}
                    buttonSave={true}
                    setError={setError}
                    watch={watch}
                    setValue={setValue}
                    selectedOption={selectedOption}
                    onChangeSelectedOption={handleChangeSelectedOption}
                    urlClub={currentUser?.urlClub}
                  />
                </TabPanel>
                <TabPanel value={tabIndex} index={1}>
                  <ProductPrices
                    control={control}
                    watch={watch}
                    addDelete={handleDeleted}
                    productOrderBumps={productOrderBumps}
                    setProductOrderBumps={handleProductOrderBumps}
                    saveProductOrderBumps={(items: MultiSelectedItem[]) =>
                      setProductOrderBumps(items)
                    }
                    setError={setError}
                    tenantBank={tenantBank}
                    setValue={setValue}
                    execute={execute}
                  />
                </TabPanel>
                {id !== 'new' && (
                  <TabPanel value={tabIndex} index={2}>
                    <ProductCoproducers
                      control={control}
                      watch={watch}
                      errors={errors}
                      setError={setError}
                      getCoproducer={execute}
                    />
                  </TabPanel>
                )}
                <TabPanel value={tabIndex} index={id !== 'new' ? 3 : 2}>
                  <ProductAffiliates
                    control={control}
                    errors={errors}
                    links={links}
                    productLink={currentLink}
                    errorIdentifier={errorIdentifier}
                    errorUrl={errorUrl}
                    setError={setError}
                    watch={watch}
                    setValue={setValue}
                    execute={execute}
                    getLinks={getLinks}
                    setProductLink={setCurrentLink}
                    setErrorIdentifier={setErrorIdentifier}
                    setErrorUrl={setErrorUrl}
                  />
                </TabPanel>
                {id !== 'new' && (
                  <TabPanel value={tabIndex} index={4}>
                    <ProductPixels watch={watch} setError={setError} />
                  </TabPanel>
                )}
                {id !== 'new' && (
                  <TabPanel value={tabIndex} index={5}>
                    <ProductCustomsCheckout
                      control={control}
                      watch={watch}
                      setValue={setValue}
                      setError={setError}
                    />
                  </TabPanel>
                )}
                {id !== 'new' && (
                  <TabPanel value={tabIndex} index={6}>
                    <ProductVouchers watch={watch} setError={setError} />
                  </TabPanel>
                )}
                {id !== 'new' && isAdmin && (
                  <TabPanel value={tabIndex} index={7}>
                    <Situation />
                  </TabPanel>
                )}
              </Box>
            </Stack>
          </Box>
        </Slide>
      </Box>

      <LoadingBackdrop open={loading || sending || loadingOrderBumps} />

      <ErrorSnackbar open={error !== ''} onClose={() => setError('')}>
        {error}
      </ErrorSnackbar>

      <SuccessSnackbar
        open={success}
        autoHideDuration={2000}
        onClose={() => {
          setSuccess(false)
          if (id === 'new') {
            navigate(`/product/${saveProductId.current}`)
            Hosts.refreshPage()
          }
        }}
      >
        {id !== 'new' ? 'Alterado ' : 'Inserido '} com sucesso
      </SuccessSnackbar>

      <SuccessSnackbar
        open={deleted}
        autoHideDuration={2000}
        onClose={() => navigate('/products')}
      >
        Deletado com sucesso
      </SuccessSnackbar>

      <CopiedSnackbar
        open={copied}
        onClose={() => setCopied(false)}
        message="Código Cademí copiado"
      />

      <Popover
        id={idPopover}
        open={openPopover}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Typography
          onClick={handleClickCopiedPopover}
          sx={{ p: 2, cursor: 'pointer' }}
        >
          Cademí ID: {data?.memberServiceId}
        </Typography>
      </Popover>

      <ConfirmDialog
        open={openConfirmDelete}
        title="Confirmar exclusão"
        message="Após a exclusão não será mais possível ativar novamente o produto. Deseja realmente deletar o produto?"
        onClose={closeDelete}
        onYes={deleteProduct}
      />

      <MemberClassModal
        open={openMemberClass}
        setOpen={setOpenMemberClass}
        setSelectedOption={setSelectedOption}
        onAcceptMemberClass={handleAcceptMemberClass}
      />
    </ContainerBox>
  )
}

export default ProductPage
