import { Box, Paper, Typography } from '@mui/material'
import DataTable, {
  IDataTableColumn,
  TDataTablePagination,
} from '../../../../../components/Tables/DataTable'
import { TIndicatedTenantItem } from '../../../../../core/types/IndicatedTenant'
import { grey } from '@mui/material/colors'

interface UserSettingIndicatedsProps {
  indicateds: TIndicatedTenantItem[]
  dataPagination: TDataTablePagination<TIndicatedTenantItem>
  columns: readonly IDataTableColumn<TIndicatedTenantItem>[]
}

const UserSettingIndicateds = ({
  indicateds,
  dataPagination,
  columns,
}: UserSettingIndicatedsProps) => {
  return (
    <Box
      width="100%"
      alignContent="center"
      alignItems="center"
      justifyContent="center"
      sx={{ pt: 2 }}
    >
      {indicateds.length <= 0 && (
        <Typography textAlign="center">
          Nenhum indicado cadastrado para esse usuário.
        </Typography>
      )}
      {indicateds.length > 0 && (
        <Paper
          sx={{
            width: '100%',
            mb: 2,
            backgroundColor: grey[50],
            boxShadow: '0',
            p: 1,
          }}
        >
          <DataTable
            columns={columns}
            data={dataPagination}
            page={0}
            rowsPerPage={0}
            hasPagination={false}
            onPageChange={() => {}}
            onRowsPerPageChange={() => {}}
          />
        </Paper>
      )}
    </Box>
  )
}

export default UserSettingIndicateds
