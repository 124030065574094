import { Box, Divider, List } from '@mui/material'
import DesktopLayout from './partials/desktop.layout'
import MobileLayout from './partials/mobile.layout'
import { useEffect } from 'react'
import React from 'react'
import { AuthContext } from '../core/context/AuthContext'
import { useCurrentTenant } from '../core/hooks/useCurrentTenant'
import TagFunctions from '../core/functions/TagFunctions'
import { useTenantSettingsByTenantId } from '../core/hooks/useTenantSettingsByTenantId'
import { useResponsive } from '../core/hooks/useResponsive'
import {
  MENU_CONFIG,
  MENU_MAIN,
  MENU_REPORT,
  MENU_SYSTEM,
} from '../core/constants/MenuConstants'
import ListItemMenu from '../components/ListItem/ListItemMenu'
import MenuFunctions from '../core/functions/MenuFunctions'
import CrispFunctions from '../core/functions/CrispFunctions'

export default function MainLayout() {
  const { user } = React.useContext(AuthContext)

  const [open, setOpen] = React.useState(false)
  const [selected, setSelected] = React.useState('')

  const { data } = useCurrentTenant()
  const { data: settings } = useTenantSettingsByTenantId({
    id: user?.TenantId ?? '',
  })

  const isXS = useResponsive('down', 'sm')
  const isOpenOrXS = open || isXS

  const menus = MenuFunctions.getPermitedAndValid(MENU_MAIN, user, settings)
  const reports = MenuFunctions.getPermitedAndValid(MENU_REPORT, user, settings)
  const configs = MenuFunctions.getPermitedAndValid(MENU_CONFIG, user, settings)
  const system = MenuFunctions.getPermitedAndValid(MENU_SYSTEM, user, settings)

  const drawer = (
    <div>
      {menus.length > 0 && (
        <List dense={true}>
          {menus.map((menu) => (
            <ListItemMenu
              key={menu.name}
              menu={menu}
              isOpenOrXS={isOpenOrXS}
              selected={selected}
              setSelected={setSelected}
            />
          ))}
        </List>
      )}
      {menus.length > 0 && <Divider sx={{ backgroundColor: '#DADCE7' }} />}
      {reports.length > 0 && (
        <List dense={true}>
          {reports.map((menu, index) => (
            <ListItemMenu
              key={menu.name}
              menu={menu}
              isOpenOrXS={isOpenOrXS}
              selected={selected}
              setSelected={setSelected}
            />
          ))}
        </List>
      )}
      {reports.length > 0 && <Divider sx={{ backgroundColor: '#DADCE7' }} />}
      {system.length > 0 && (
        <List dense={true}>
          {system.map((menu) => (
            <ListItemMenu
              key={menu.name}
              menu={menu}
              isOpenOrXS={isOpenOrXS}
              selected={selected}
              setSelected={setSelected}
            />
          ))}
        </List>
      )}
      {system.length > 0 && <Divider sx={{ backgroundColor: '#DADCE7' }} />}
      {configs.length > 0 && (
        <List dense={true}>
          {configs.map((menu) => (
            <ListItemMenu
              key={menu.name}
              menu={menu}
              isOpenOrXS={isOpenOrXS}
              selected={selected}
              setSelected={setSelected}
            />
          ))}
        </List>
      )}
    </div>
  )

  useEffect(() => {
    const tagDash = CrispFunctions.addTagDash()

    return () => {
      tagDash.map((tag) => TagFunctions.removeTags({ script: tag }))
    }
  }, [])

  return (
    <Box>
      {isXS && <MobileLayout drawer={drawer} />}
      {!isXS && (
        <DesktopLayout
          drawer={drawer}
          open={open}
          setOpen={setOpen}
          tenant={data}
          tenantSettings={settings}
        />
      )}
    </Box>
  )
}
