import { maskPercentage } from '../../masks/masks'

const format = (
  value?: number,
  fractional?: number,
  prefixMoney?: boolean,
  def?: number
): string => {
  if (value === undefined || value === null) {
    value = def
  }

  if (value === undefined || value === null) {
    return ''
  }

  var money = ''

  if (prefixMoney) {
    money = value.toLocaleString('pt-br', {
      style: 'currency',
      currency: 'BRL',
    })
    return money
  }

  money = value.toLocaleString('pt-br', { minimumFractionDigits: fractional })
  return money
}

const formatMoney = (value?: number, prefixMoney?: boolean, def?: number) => {
  return format(value, 2, prefixMoney, def)
}

const formatMoneyDefault = (value?: any) => {
  const number = toNumber(value)
  return formatMoney(number, true, 0)
}

const toNumber = (value: any) => {
  if (typeof value === 'string') {
    value = String(value).replace(',', '.')
    return Number(value)
  }
  if (value !== undefined && value !== null) {
    return Number(value)
  }
  return value
}

const toPercentage = (value?: any) => {
  value = toNumber(value || 0)
  const percentage = Number(value).toString().replace(',', '').replace('.', ',')
  return `${percentage}%`
}

const getRandomInt = (max: number) => {
  return Math.floor(Math.random() * max)
}

const maskPercentageToNumber = (value: string) => {
  return toNumber(maskPercentage(value).replaceAll('.', '').replace(',', '.'))
}

const NumberFunctions = {
  format,
  formatMoney,
  formatMoneyDefault,
  toNumber,
  toPercentage,
  getRandomInt,
  maskPercentageToNumber,
}

export default NumberFunctions
