import { TAuthUser, TUserType } from '../types/Auth'
import { TMenu } from '../types/Menu'
import { TTenantSettings } from '../types/Tenant'

const getPermitedAndValid = (
  menus: TMenu[],
  user: TAuthUser | null,
  settings: TTenantSettings | null
) => {
  const userType = user?.UserType ?? TUserType.Member
  return menus.filter(
    (m) =>
      m.permissions.includes(userType) &&
      (!m.isValidBySettings || m.isValidBySettings(settings))
  )
}

const MenuFunctions = {
  getPermitedAndValid,
}

export default MenuFunctions
