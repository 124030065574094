import api, { getResponseError } from '../../services/api'
import {
  ICalculateInstallment,
  IOrder,
  IOrderCharge,
  TCheckoutOrderReport,
} from '../types/Order'
import { IErrorResponse, IResponse } from '../types/ErrorResponse'
import { TSale } from '../types/Sale'
import { methodTypeCreditCard, methodTypeDebitCard } from '../constants'
import { numberOnly } from '../../masks/masks'
import { TCheckoutAbandonedPagination } from '../types/CheckoutAbandoned'
import {
  TCheckoutAccessPagination,
  TCheckoutOrderAnticipationPagination,
  TCheckoutOrderDetails,
  TCheckoutOrderPagination,
  TSubscriptionDetails,
} from '../types/CheckoutOrder'
import { ICheckoutOrderAnticipationsProp } from '../hooks/useCheckoutOrderAnticipations'

interface IPaginationProp {
  page: number
  rows: number
  search?: string
  type: string
  report: TCheckoutOrderReport
  startDate?: Date | null | undefined
  endDate?: Date | null | undefined
  sellerType?: number | null
  products?: string[] | null
  affiliateds?: string[] | null
  payments?: string[] | null
  status?: string[] | null
}

interface IAccessPaginationProp {
  page: number
  rows: number
  search?: string
  report: 'all' | 'order' | 'subscription'
  startDate?: Date | null | undefined
  endDate?: Date | null | undefined
  sellerType?: number | null
  products?: string[] | null
  affiliateds?: string[] | null
  payments?: string[] | null
  status?: string[] | null
}

interface IPaginationDaysProp {
  page: number
  rows: number
  search?: string
  lastDays: number
  report?: 'order' | 'subscription'
}

const create = async ({ data }: { data: TSale }): Promise<IResponse> => {
  try {
    const { data: response } = await api.post('/Order', setSendData(data))
    if (response) {
      return {
        success: true,
        code: 0,
        error: '',
        data: response,
      }
    }

    return {
      success: false,
      code: 1,
      error: 'Erro inesperado do servidor',
    }
  } catch (error: any) {
    const e = getResponseError(error)
    return {
      success: false,
      code: 2,
      error: e,
    }
  }
}

const setSendData = (data: TSale) => {
  const item: IOrder = {
    productPriceId: data.productPriceId,
    name: data.name,
    document: numberOnly(data.document),
    email: data.email,
    phone: numberOnly(data.phone),
    address: {
      city: data.address.city,
      complement: data.address.complement,
      neighborhood: data.address.neighborhood,
      number: data.address.number,
      reference: data.address.reference,
      state: data.address.state,
      street: data.address.street,
      zipCode: numberOnly(data.address.zipCode),
    },
    paymentData: {
      paymentMethod: data.payment.methodType,
    },
  }

  if (data.payment.cardValidate && data.payment.cardValidate !== null) {
    if (data.payment.cardValidate.includes('/')) {
      const cardValidate = data.payment.cardValidate.split('/')
      data.payment.cardExpiresMonth = Number(cardValidate[0])
      data.payment.cardExpiresYear = Number(cardValidate[1])
    }
  }

  if (item.paymentData.paymentMethod === methodTypeCreditCard) {
    item.paymentData.creditCardData = {
      number: numberOnly(data.payment.cardNumber),
      name: data.payment.cardName,
      cvv: data.payment.cardCCV,
      year: data.payment.cardExpiresYear,
      month: data.payment.cardExpiresMonth,
      installments: data.payment.installment,
    }
  }

  if (item.paymentData.paymentMethod === methodTypeDebitCard) {
    item.paymentData.debitCardData = {
      number: numberOnly(data.payment.cardNumber),
      name: data.payment.cardName,
      cvv: data.payment.cardCCV,
      year: data.payment.cardExpiresYear,
      month: data.payment.cardExpiresMonth,
    }
  }

  if (data?.products) {
    item.orderBumps = data.products.map((x) => ({
      productPriceId: x.productPriceId,
      description: x.productName,
      amount: x.amount,
      value: x.value,
    }))
  }

  return item
}

const verified = async ({
  chargeId,
}: {
  chargeId?: string
}): Promise<IOrderCharge | undefined> => {
  if (!chargeId) {
    return undefined
  }
  try {
    const { data: response } = await api.get(`/Order/charge/${chargeId}`)
    if (response) {
      return response
    }

    return undefined
  } catch (error: any) {
    const e = getResponseError(error)
    return undefined
  }
}

const calculeIsntallments = async ({
  priceId,
  newValue,
}: {
  priceId: string
  newValue: number
}): Promise<ICalculateInstallment | IErrorResponse> => {
  try {
    const { data: response } = await api.get(
      `/Order/price/calculate/${priceId}`,
      { params: { NewValue: newValue } }
    )
    if (response) {
      return response
    }

    return {
      code: 1,
      error: 'Erro inesperado do servidor',
    }
  } catch (error: any) {
    const e = getResponseError(error)
    return {
      code: 2,
      error: e,
    }
  }
}

const getOpen = async ({
  page,
  rows,
  search,
  lastDays,
  report,
}: IPaginationDaysProp): Promise<
  TCheckoutAbandonedPagination | IErrorResponse
> => {
  try {
    var path = `/Order/open?PageNumber=${page}&RowsPerPage=${rows}&LastDays=${lastDays}`

    if (report) {
      path += `&Report=${report}`
    }

    if (search && search !== '') {
      path += `&Search=${search}`
    }

    const { data } = await api.get<TCheckoutAbandonedPagination>(path)
    //const { data } = abandoneds;
    if (data) {
      return data
    }

    return {
      code: 1,
      error: 'Erro inesperado do servidor',
    }
  } catch (error) {
    const e = getResponseError(error)
    return {
      code: 2,
      error: e,
    }
  }
}

const getAll = async ({
  page,
  rows,
  search,
  type,
  report,
  startDate,
  endDate,
  affiliateds,
  payments,
  products,
  sellerType,
  status,
}: IPaginationProp): Promise<TCheckoutOrderPagination | IErrorResponse> => {
  try {
    var path = `/Order/all?PageNumber=${page}&RowsPerPage=${rows}&Type=${type}`

    if (report !== 'all') {
      path += `&Report=${report}`
    }

    if (search && search !== '') {
      path += `&Search=${search}`
    }

    if (startDate) {
      endDate ??= new Date()
      path += `&StartPeriod=${startDate.toISOString()}&EndPeriod=${endDate.toISOString()}`
    }

    if (
      affiliateds !== null &&
      affiliateds !== undefined &&
      affiliateds.length > 0
    ) {
      path += `&Affiliateds=${affiliateds.join(';')}`
    }

    if (payments !== null && payments !== undefined && payments.length > 0) {
      path += `&Payments=${payments.join(';')}`
    }

    if (products !== null && products !== undefined && products.length > 0) {
      path += `&Products=${products.join(';')}`
    }

    if (sellerType !== null && sellerType !== undefined) {
      path += `&SellerType=${sellerType}`
    }

    if (status !== null && status !== undefined && status.length > 0) {
      path += `&Status=${status.join(';')}`
    }

    const { data } = await api.get<TCheckoutOrderPagination>(path)
    //const { data } = checkoutOrders;
    if (data) {
      return data
    }

    return {
      code: 1,
      error: 'Erro inesperado do servidor',
    }
  } catch (error) {
    const e = getResponseError(error)
    return {
      code: 2,
      error: e,
    }
  }
}

const getApproved = async ({
  page,
  rows,
  search,
  type,
  report,
  startDate,
  endDate,
  affiliateds,
  payments,
  products,
  sellerType,
  status,
}: IPaginationProp): Promise<TCheckoutOrderPagination | IErrorResponse> => {
  try {
    var path = `/Order/approved?PageNumber=${page}&RowsPerPage=${rows}&Type=${type}`

    if (report) {
      path += `&Report=${report}`
    }

    if (search && search !== '') {
      path += `&Search=${search}`
    }

    if (startDate) {
      endDate ??= new Date()
      path += `&StartPeriod=${startDate.toISOString()}&EndPeriod=${endDate.toISOString()}`
    }

    if (
      affiliateds !== null &&
      affiliateds !== undefined &&
      affiliateds.length > 0
    ) {
      path += `&Affiliateds=${affiliateds.join(';')}`
    }

    if (payments !== null && payments !== undefined && payments.length > 0) {
      path += `&Payments=${payments.join(';')}`
    }

    if (products !== null && products !== undefined && products.length > 0) {
      path += `&Products=${products.join(';')}`
    }

    if (sellerType !== null && sellerType !== undefined) {
      path += `&SellerType=${sellerType}`
    }

    if (status !== null && status !== undefined && status.length > 0) {
      path += `&Status=${status.join(';')}`
    }

    const { data } = await api.get<TCheckoutOrderPagination>(path)
    if (data) {
      return data
    }

    return {
      code: 1,
      error: 'Erro inesperado do servidor',
    }
  } catch (error) {
    const e = getResponseError(error)
    return {
      code: 2,
      error: e,
    }
  }
}

const getCheckoutOrderDetails = async ({
  id,
  productPriceId,
}: {
  id: string
  productPriceId?: string
}): Promise<TCheckoutOrderDetails | IErrorResponse> => {
  try {
    var path = `/Order/${id}/details`

    if (productPriceId) {
      path += `?productPriceId=${productPriceId}`
    }

    const { data } = await api.get(path)
    if (data) {
      if (data.customFields) {
        data.customFields = JSON.parse(data.customFields)
      }
      return data as TCheckoutOrderDetails
    }

    return {
      code: 1,
      error: 'Erro inesperado do servidor',
    }
  } catch (error) {
    const e = getResponseError(error)
    return {
      code: 2,
      error: e,
    }
  }
}

const getSubscriptionDetails = async ({
  codeId,
}: {
  codeId: string
}): Promise<TSubscriptionDetails | IErrorResponse> => {
  try {
    var path = `/Subscription/${codeId}`

    const { data } = await api.get<TSubscriptionDetails>(path)
    if (data) {
      return data
    }

    return {
      code: 1,
      error: 'Erro inesperado do servidor',
    }
  } catch (error) {
    const e = getResponseError(error)
    return {
      code: 2,
      error: e,
    }
  }
}

const getCheckoutAccess = async ({
  page,
  rows,
  search,
  report,
  startDate,
  endDate,
  affiliateds,
  payments,
  products,
  sellerType,
  status,
}: IAccessPaginationProp): Promise<
  TCheckoutAccessPagination | IErrorResponse
> => {
  try {
    var path = `/Report/page/accesses?PageNumber=${page}&RowsPerPage=${rows}&Report=${report}`

    if (search && search !== '') {
      path += `&Search=${search}`
    }

    if (startDate) {
      endDate ??= new Date()
      path += `&StartPeriod=${startDate.toISOString()}&EndPeriod=${endDate.toISOString()}`
    }

    if (
      affiliateds !== null &&
      affiliateds !== undefined &&
      affiliateds.length > 0
    ) {
      path += `&Affiliateds=${affiliateds.join(';')}`
    }

    if (payments !== null && payments !== undefined && payments.length > 0) {
      path += `&Payments=${payments.join(';')}`
    }

    if (products !== null && products !== undefined && products.length > 0) {
      path += `&Products=${products.join(';')}`
    }

    if (sellerType !== null && sellerType !== undefined) {
      path += `&SellerType=${sellerType}`
    }

    if (status !== null && status !== undefined && status.length > 0) {
      path += `&Status=${status.join(';')}`
    }

    const { data } = await api.get<TCheckoutAccessPagination>(path)
    //const { data } = checkoutOrders;
    if (data) {
      return data
    }

    return {
      code: 1,
      error: 'Erro inesperado do servidor',
    }
  } catch (error) {
    const e = getResponseError(error)
    return {
      code: 2,
      error: e,
    }
  }
}

const getOrdersAnalitycs = async ({
  page,
  rows,
  search,
  report,
  startDate,
  endDate,
  affiliateds,
  payments,
  products,
  sellerType,
  status,
}: IAccessPaginationProp): Promise<
  TCheckoutOrderPagination | IErrorResponse
> => {
  try {
    var path = `/Report/order/admin/accesses?PageNumber=${page}&RowsPerPage=${rows}&Report=${report}`

    if (search && search !== '') {
      path += `&Search=${search}`
    }

    if (startDate) {
      endDate ??= new Date()
      path += `&StartPeriod=${startDate.toISOString()}&EndPeriod=${endDate.toISOString()}`
    }

    if (
      affiliateds !== null &&
      affiliateds !== undefined &&
      affiliateds.length > 0
    ) {
      path += `&Affiliateds=${affiliateds.join(';')}`
    }

    if (payments !== null && payments !== undefined && payments.length > 0) {
      path += `&Payments=${payments.join(';')}`
    }

    if (products !== null && products !== undefined && products.length > 0) {
      path += `&Products=${products.join(';')}`
    }

    if (sellerType !== null && sellerType !== undefined) {
      path += `&SellerType=${sellerType}`
    }

    if (status !== null && status !== undefined && status.length > 0) {
      path += `&Status=${status.join(';')}`
    }

    const { data } = await api.get<TCheckoutOrderPagination>(path)
    //const { data } = checkoutOrders;
    if (data) {
      return data
    }

    return {
      code: 1,
      error: 'Erro inesperado do servidor',
    }
  } catch (error) {
    const e = getResponseError(error)
    return {
      code: 2,
      error: e,
    }
  }
}

const getAnticipations = async ({
  page,
  rows,
  search,
  filters,
}: ICheckoutOrderAnticipationsProp): Promise<
  TCheckoutOrderAnticipationPagination | IErrorResponse
> => {
  let { startDate, endDate, status } = filters

  try {
    var path = `/Report/anticipation?PageNumber=${page}&RowsPerPage=${rows}`

    if (search && search !== '') {
      path += `&Search=${search}`
    }

    if (startDate) {
      endDate ??= new Date()
      path += `&StartPeriod=${startDate.toISOString()}&EndPeriod=${endDate.toISOString()}`
    }

    if (status !== null && status !== undefined && status.length > 0) {
      path += `&Status=${status.join(';')}`
    }

    const { data } = await api.get<TCheckoutOrderAnticipationPagination>(path)
    if (data) {
      return data
    }

    return {
      code: 1,
      error: 'Erro inesperado do servidor',
    }
  } catch (error) {
    const e = getResponseError(error)
    return {
      code: 2,
      error: e,
    }
  }
}

const getPaymentLink = async ({
  codeId,
}: {
  codeId: string
}): Promise<IResponse> => {
  try {
    const { data: response } = await api.get(`/Order/${codeId}/PaymentLink`)
    if (response) {
      return {
        success: true,
        data: response,
        code: 0,
        error: '',
      }
    }

    return {
      success: false,
      code: 1,
      error: 'Erro inesperado do servidor',
    }
  } catch (error) {
    const e = getResponseError(error)
    return {
      success: false,
      code: 2,
      error: e,
    }
  }
}

const updateTracking = async ({ data }: { data: any }): Promise<IResponse> => {
  try {
    const { data: response } = await api.put('/Order/tracking', data)
    if (response) {
      return {
        success: true,
        code: 0,
        error: '',
      }
    }

    return {
      success: false,
      code: 1,
      error: 'Erro inesperado do servidor',
    }
  } catch (error) {
    const e = getResponseError(error)
    return {
      success: false,
      code: 2,
      error: e,
    }
  }
}

const deleteCharge = async ({
  orderChargeId,
}: {
  orderChargeId: string
}): Promise<IResponse> => {
  try {
    const { data: response } = await api.delete(
      `/Order/charge/${orderChargeId}/unique`
    )
    if (response) {
      return {
        success: true,
        code: 0,
        error: '',
      }
    }

    return {
      success: false,
      code: 1,
      error: 'Erro inesperado do servidor',
    }
  } catch (error) {
    const e = getResponseError(error)
    return {
      success: false,
      code: 2,
      error: e,
    }
  }
}

const manualPayment = async ({
  checkoutOrderId,
}: {
  checkoutOrderId: string
}): Promise<IResponse> => {
  try {
    const { data: response } = await api.post(
      `/Order/${checkoutOrderId}/payment`
    )
    if (response) {
      return {
        success: true,
        code: 0,
        error: '',
      }
    }

    return {
      success: false,
      code: 1,
      error: 'Erro inesperado do servidor',
    }
  } catch (error) {
    const e = getResponseError(error)
    return {
      success: false,
      code: 2,
      error: e,
    }
  }
}

const subscriptionPaymentCancel = async ({
  checkoutOrderId,
}: {
  checkoutOrderId: string
}): Promise<IResponse> => {
  try {
    const { data: response } = await api.put(
      `Subscription/${checkoutOrderId}/cancel/payment`
    )
    if (response) {
      return {
        success: true,
        code: 0,
        error: '',
      }
    }

    return {
      success: false,
      code: 1,
      error: 'Erro inesperado do servidor',
    }
  } catch (error) {
    const e = getResponseError(error)
    return {
      success: false,
      code: 2,
      error: e,
    }
  }
}

const subscriptionManualCharge = async ({
  subscriptionCodeId,
}: {
  subscriptionCodeId: string
}): Promise<IResponse> => {
  try {
    const { data: response } = await api.post(
      `Subscription/manual/charge/${subscriptionCodeId}`
    )
    if (response) {
      return {
        success: true,
        code: 0,
        error: '',
      }
    }

    return {
      success: false,
      code: 1,
      error: 'Erro inesperado do servidor',
    }
  } catch (error) {
    const e = getResponseError(error)
    return {
      success: false,
      code: 2,
      error: e,
    }
  }
}

const unlockSale = async ({
  checkoutOrderId,
}: {
  checkoutOrderId: string
}): Promise<IResponse> => {
  try {
    await api.post(`/Order/${checkoutOrderId}/unlock`)
    return {
      success: true,
      code: 0,
      error: '',
    }
  } catch (error) {
    const e = getResponseError(error)
    return {
      success: false,
      code: 2,
      error: e,
    }
  }
}

const reachedOutForAbandonedOrder = async ({
  orderId,
}: {
  orderId: string
}): Promise<IResponse> => {
  try {
    const { status } = await api.put(`/Report/${orderId}/reached`)
    if (status >= 200 && status <= 299) {
      return {
        success: true,
        code: 0,
        error: '',
      }
    }

    return {
      success: false,
      code: 1,
      error: 'Erro inesperado do servidor',
    }
  } catch (error) {
    const e = getResponseError(error)
    return {
      success: false,
      code: 2,
      error: e,
    }
  }
}

const OrderController = {
  create,
  verified,
  calculeIsntallments,
  getOpen,
  getAll,
  getApproved,
  getCheckoutOrderDetails,
  getSubscriptionDetails,
  updateTracking,
  deleteCharge,
  getCheckoutAccess,
  getOrdersAnalitycs,
  manualPayment,
  getAnticipations,
  subscriptionPaymentCancel,
  subscriptionManualCharge,
  unlockSale,
  getPaymentLink,
  reachedOutForAbandonedOrder,
}

export default OrderController
