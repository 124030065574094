import {
  Button,
  Fade,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import BpCheckbox from '../../../../../components/Checkout/BpCheckbox'
import { TProductPriceInstallmentFees } from '../../../../../core/types/Product'
import {
  methodTypeBoleto,
  methodTypeCreditCard,
  methodTypePix,
} from '../../../../../core/constants'
import { ProductPriceDetailPaymentsProps } from './ProductPriceTypes'
import { useContext, useState } from 'react'
import { AuthContext } from '../../../../../core/context/AuthContext'
import NumberFunctions from '../../../../../core/functions/NumberFunctions'
import SwipeableDrawerRightDialog from '../../../../../components/Dialogs/SwipeableDrawerRightDialog'
import { useProductInstallments } from '../../../../../core/hooks/useProductInstallments'
import InfoTooltip from '../../../../../components/Tooltip/InfoTooltip'

const ProductPriceDetailPayments = ({
  methodTypes,
  price,
  errorPayments,
  options,
  setPrice,
  onChangeMethodTypes,
}: ProductPriceDetailPaymentsProps) => {
  const { isUserSysAdmin } = useContext(AuthContext)

  const [open, setOpen] = useState(false)

  const isInstallmentFeesProducer =
    price.installmentFeesType === TProductPriceInstallmentFees.Producer
  const isInstallmentFeesHybrid =
    price.installmentFeesType === TProductPriceInstallmentFees.Hybrid
  const isExistsAmount = isInstallmentFeesProducer || isInstallmentFeesHybrid

  const getMethodTypeCreditCard = () => {
    return methodTypes
      .filter((x) => x.selected)
      .find((x) => x.id === methodTypeCreditCard.toString())
  }

  const isMethodTypeCreditCard = () => {
    const creditCard = getMethodTypeCreditCard()
    if (creditCard) return true
    return false
  }

  const getMethodTypeCreditCardInstallment = () => {
    const creditCard = getMethodTypeCreditCard()
    if (creditCard) return creditCard.value
    return 1
  }

  const maxInstallmentForProducer = isInstallmentFeesProducer
    ? getMethodTypeCreditCardInstallment()
    : price.endInstallmentFees ?? getMethodTypeCreditCardInstallment()

  const { data } = useProductInstallments({
    maxInstallment: Math.max(...options),
    value: price.value ?? 0,
    maxInstallmentForProducer,
  })

  const isPaymentChecked = (methodType: number) => {
    return (
      methodTypes.find(
        (mt) => mt.selected && mt.id === methodType.toString()
      ) != undefined
    )
  }

  const handleChangePayment = (
    event: React.ChangeEvent<HTMLInputElement>,
    methodType: number
  ) => {
    const method = methodTypes.find((mt) => mt.id === methodType.toString())
    if (method) {
      method.selected = event.target.checked

      if (methodType === methodTypeCreditCard && method.selected) {
        method.value = Math.max(...options)
        price.endInstallmentFees = method.value
      }

      onChangeMethodTypes([...methodTypes], price)
    }
  }

  const handleChangeMaxInstallments = (e: SelectChangeEvent<number>) => {
    const creditCard = getMethodTypeCreditCard()
    if (creditCard) {
      const endInstallmentFees =
        price.endInstallmentFees ?? getMethodTypeCreditCardInstallment()
      if (endInstallmentFees === creditCard.value) {
        price.endInstallmentFees = Number(e.target.value)
      }
      creditCard.value = Number(e.target.value)
      if (endInstallmentFees >= creditCard.value) {
        price.endInstallmentFees = creditCard.value
      }
      const newItems = methodTypes.filter(
        (x) => x.id !== methodTypeCreditCard.toString()
      )
      onChangeMethodTypes([...newItems, { ...creditCard }], price)
    }
  }

  const getOptionDescription = (option: number) => {
    const endInstallmentFees =
      price.endInstallmentFees ?? getMethodTypeCreditCardInstallment()
    const isOptionProducer = option <= endInstallmentFees
    const isHybridAndOption = isInstallmentFeesHybrid && isOptionProducer

    return `${option}x ${isInstallmentFeesProducer || isHybridAndOption ? 'SEM juros para o cliente' : ''}`
  }

  return (
    <>
      <Fade
        in={methodTypes.length > 0}
        timeout={500}
        unmountOnExit
        mountOnEnter
      >
        <Stack direction="column" spacing={1}>
          <Typography
            color="#343948"
            fontSize="16px"
            lineHeight="20px"
            fontWeight={700}
          >
            Formas de Pagamento
          </Typography>
          {/*<ProductPricePayments
          selectds={methodTypes}
          setSelectds={handleChangeMethodTypes}
          onPayments={onPayments}
          disabled={isUserSysAdmin()}
        />*/}

          <Stack direction="column" spacing={1}>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1}>
              <FormControlLabel
                sx={{ width: { xs: '100%', sm: '50%' } }}
                disabled={isUserSysAdmin()}
                control={
                  <BpCheckbox
                    checked={isPaymentChecked(methodTypePix)}
                    onChange={(e) => handleChangePayment(e, methodTypePix)}
                  />
                }
                label={
                  <Stack direction="row" spacing={1} alignItems="center">
                    <Typography
                      lineHeight="18px"
                      fontSize={{ xs: '13px', sm: '14px' }}
                      variant="body2"
                      sx={{
                        color: '#5D5E61',
                      }}
                    >
                      PIX
                    </Typography>
                    <InfoTooltip title="Pagamento via PIX com validade de 1 dia." />
                  </Stack>
                }
              />
              <FormControlLabel
                sx={{ width: { xs: '100%', sm: '50%' } }}
                disabled={isUserSysAdmin()}
                control={
                  <BpCheckbox
                    checked={isPaymentChecked(methodTypeBoleto)}
                    onChange={(e) => handleChangePayment(e, methodTypeBoleto)}
                  />
                }
                label={
                  <Stack direction="row" spacing={1} alignItems="center">
                    <Typography
                      lineHeight="18px"
                      fontSize={{ xs: '13px', sm: '14px' }}
                      variant="body2"
                      sx={{
                        color: '#5D5E61',
                      }}
                    >
                      Boleto
                    </Typography>
                    <InfoTooltip title="Pagamento via boleto com validade de 3 dias." />
                  </Stack>
                }
              />
            </Stack>

            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1}>
              <FormControlLabel
                sx={{ width: { xs: '100%', sm: '50%' } }}
                disabled={isUserSysAdmin()}
                control={
                  <BpCheckbox
                    checked={isPaymentChecked(methodTypeCreditCard)}
                    onChange={(e) =>
                      handleChangePayment(e, methodTypeCreditCard)
                    }
                  />
                }
                label={
                  <Typography
                    lineHeight="18px"
                    fontSize={{ xs: '13px', sm: '14px' }}
                    variant="body2"
                    sx={{
                      color: '#5D5E61',
                    }}
                  >
                    Cartão de Crédito
                  </Typography>
                }
              />
              <Fade
                in={isMethodTypeCreditCard()}
                timeout={500}
                unmountOnExit
                mountOnEnter
              >
                <FormControl sx={{ width: { xs: '100%', sm: '50%' } }}>
                  <InputLabel id="select-label-fees">
                    Taxas do parcelamento
                  </InputLabel>
                  <Select
                    variant="outlined"
                    disabled={isUserSysAdmin()}
                    size="small"
                    labelId="select-label-fees"
                    label="Taxas do parcelamento"
                    value={price.installmentFeesType}
                    onChange={(e) => {
                      const { value } = e.target
                      const installmentFeesType =
                        value as TProductPriceInstallmentFees
                      const endInstallmentFees =
                        installmentFeesType ===
                        TProductPriceInstallmentFees.Producer
                          ? getMethodTypeCreditCardInstallment()
                          : undefined
                      setPrice({
                        ...price,
                        installmentFeesType,
                        endInstallmentFees,
                      })
                    }}
                  >
                    <MenuItem
                      value={Number(TProductPriceInstallmentFees.Customer)}
                    >
                      Repassar (seu cliente paga as taxas)
                    </MenuItem>
                    <MenuItem
                      value={Number(TProductPriceInstallmentFees.Producer)}
                    >
                      Absorver (você quem paga as taxas)
                    </MenuItem>
                    <MenuItem
                      value={Number(TProductPriceInstallmentFees.Hybrid)}
                    >
                      Híbrido (escolha quem paga as taxas)
                    </MenuItem>
                  </Select>
                </FormControl>
              </Fade>
            </Stack>
          </Stack>

          {errorPayments !== '' && (
            <Typography variant="overline" color={'red'}>
              {errorPayments}
            </Typography>
          )}

          <Fade
            in={isMethodTypeCreditCard()}
            timeout={500}
            unmountOnExit
            mountOnEnter
          >
            <Stack direction="column" spacing={2} sx={{ pt: 1 }}>
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1}>
                <FormControl fullWidth>
                  <InputLabel id="select-label">
                    Max. Parcelas Cartão de Crédito
                  </InputLabel>
                  <Select
                    variant="outlined"
                    disabled={isUserSysAdmin()}
                    size="small"
                    labelId="select-label"
                    label="Max. Parcelas Cartão de Crédito"
                    value={getMethodTypeCreditCardInstallment()}
                    onChange={handleChangeMaxInstallments}
                  >
                    {options?.map((option) => (
                      <MenuItem value={option}>
                        {getOptionDescription(option)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {isExistsAmount && (
                  <Button
                    size="small"
                    variant="outlined"
                    sx={{ textTransform: 'none', width: 180 }}
                    onClick={() => setOpen(true)}
                    endIcon={
                      <img src="/assets/icons/infos/info.svg" alt="info" />
                    }
                  >
                    Parcelas
                  </Button>
                )}
              </Stack>
              {price.installmentFeesType ===
                TProductPriceInstallmentFees.Hybrid && (
                <Stack direction="row" spacing={1}>
                  <FormControl fullWidth>
                    <InputLabel id="select-label-fees">
                      Assumir juros de parcelamento até
                    </InputLabel>
                    <Select
                      variant="outlined"
                      disabled={isUserSysAdmin()}
                      size="small"
                      labelId="select-label-fees"
                      label="Assumir juros de parcelamento até"
                      value={
                        price.endInstallmentFees ??
                        getMethodTypeCreditCardInstallment()
                      }
                      onChange={(e) => {
                        setPrice({
                          ...price,
                          endInstallmentFees:
                            e.target.value === ''
                              ? undefined
                              : NumberFunctions.toNumber(e.target.value),
                        })
                      }}
                    >
                      {options
                        ?.filter(
                          (option) =>
                            option <= getMethodTypeCreditCardInstallment()
                        )
                        .map((option) => (
                          <MenuItem value={option}>{option}x</MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Stack>
              )}
            </Stack>
          </Fade>
        </Stack>
      </Fade>

      <SwipeableDrawerRightDialog
        open={open}
        title="Valores do parcelamento"
        subtitle="Veja os valores de cada parcelamento"
        setOpen={setOpen}
        onClose={() => setOpen(false)}
      >
        <Stack sx={{ p: 0.5 }}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 370 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Parcela</TableCell>
                  <TableCell>Valor por parcela</TableCell>
                  <TableCell>Total</TableCell>
                  <TableCell>Você vai receber</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data
                  ?.filter(
                    (d) =>
                      d.installmentNumber <=
                      getMethodTypeCreditCardInstallment()
                  )
                  .map((installment, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        '&:last-child td, &:last-child th': { border: 0 },
                        backgroundColor: index % 2 === 0 ? 'grey.100' : 'white',
                      }}
                    >
                      <TableCell component="th" scope="row">
                        <Typography variant="subtitle2">
                          {installment.installmentNumber}x
                        </Typography>
                      </TableCell>
                      <TableCell>
                        {NumberFunctions.formatMoneyDefault(installment.value)}
                      </TableCell>
                      <TableCell>
                        {NumberFunctions.formatMoneyDefault(installment.total)}
                      </TableCell>
                      <TableCell>
                        {NumberFunctions.formatMoneyDefault(installment.amount)}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
      </SwipeableDrawerRightDialog>
    </>
  )
}

export default ProductPriceDetailPayments
