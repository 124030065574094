import { Box, Button, Chip, Stack, Typography } from '@mui/material'
import Convertions from '../../../core/functions/convertions'
import { Link } from 'react-router-dom'
import { useContext } from 'react'
import { AuthContext } from '../../../core/context/AuthContext'
import * as React from 'react'
import { TProduct, TProductStatus } from '../../../core/types/Product'
import AccordionDefault from '../../../components/Accordions/AccordionDefault'
import AccordionSummaryDefault from '../../../components/Accordions/AccordionSummaryDefault'
import AccordionDetailsDefault from '../../../components/Accordions/AccordionDetailsDefault'

const ProductCard = ({
  product,
  productImage,
}: {
  product: TProduct
  productImage: string
}) => {
  const { user } = useContext(AuthContext)
  const { name, id, status, typePayment, memberServiceId, tenantName } = product

  const truncateString = (str: string, num: number) => {
    if (str.length <= num) {
      return str
    }
    return str.slice(0, num) + '...'
  }

  const getStatusChipStyles = (status: TProductStatus) => {
    const statusStyles = {
      [TProductStatus.Active]: {
        backgroundColor: 'rgba(28, 189, 63, 0.16)',
        color: 'rgba(0, 157, 35, 1)',
      },
      [TProductStatus.Inactive]: {
        backgroundColor: 'rgba(189, 28, 28, 0.16)',
        color: 'rgba(212, 54, 44, 1)',
      },
    }

    return statusStyles[status]
  }

  return (
    <>
      <AccordionDefault>
        <AccordionSummaryDefault>
          <Box>
            <Stack
              direction="row"
              spacing={2}
              sx={{ width: '100%', justifyContent: 'space-between' }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <img
                  src={productImage}
                  alt={name}
                  style={{
                    width: 40,
                    height: 40,
                    marginRight: '8px',
                    borderRadius: 6.67,
                    objectFit: 'cover',
                    objectPosition: 'center',
                  }}
                />
                <Box>
                  <Typography
                    sx={{
                      color: 'rgba(99, 126, 239, 1)',
                      fontFamily: 'Montserrat',
                      fontSize: '12px',
                      lineHeight: '16px',
                      fontWeight: 500,
                    }}
                  >
                    {memberServiceId}
                  </Typography>
                  <Typography
                    sx={{
                      color: 'rgba(56, 57, 59, 1) ',
                      fontFamily: 'Montserrat',
                      fontSize: '14px',
                      lineHeight: '18px',
                      fontWeight: 500,
                    }}
                  >
                    {truncateString(name, 20)}
                  </Typography>
                </Box>
              </Box>

              <Chip
                label={Convertions.productStatusToString(status)}
                sx={getStatusChipStyles(status)}
              />
            </Stack>
          </Box>
        </AccordionSummaryDefault>

        <AccordionDetailsDefault>
          <Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <Typography
                variant="body2"
                color="textSecondary"
                sx={{
                  textAlign: 'left',
                  fontFamily: 'Montserrat',
                  fontSize: '12px',
                  lineHeight: '16px',
                  fontWeight: 500,
                }}
              >
                Tipo do Produto:
              </Typography>
              <Typography
                sx={{
                  color: 'rgba(99, 126, 239, 1)',
                  textAlign: 'right',
                  fontFamily: 'Montserrat',
                  fontSize: '12px',
                  lineHeight: '16px',
                  fontWeight: 400,
                }}
              >
                {Convertions.productTypePaymentToString(typePayment) ===
                  'Assinatura' && (
                  <img
                    src={'/assets/icons/arrow/roundArrow.svg'}
                    alt="icon-eyes"
                    style={{
                      width: '10px',
                      height: '10px',
                    }}
                  />
                )}
                {Convertions.productTypePaymentToString(typePayment)}
              </Typography>
            </Box>
            <Box
              sx={{
                mt: 0.5,
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <Typography
                variant="body2"
                color="textSecondary"
                sx={{
                  textAlign: 'left',
                  fontFamily: 'Montserrat',
                  fontSize: '12px',
                  lineHeight: '16px',
                  fontWeight: 500,
                }}
              >
                Produtor:
              </Typography>
              <Typography
                sx={{
                  textAlign: 'right',
                  fontFamily: 'Montserrat',
                  fontSize: '12px',
                  lineHeight: '16px',
                  fontWeight: 600,
                }}
              >
                {truncateString(tenantName || user?.unique_name || '', 20)}
              </Typography>
            </Box>
            <Stack sx={{ mt: 1 }}>
              <Button
                component={Link}
                size="small"
                to={`/product/${id}`}
                startIcon={
                  <img
                    src={'/assets/icons/eyes/eyeButtonWhite.svg'}
                    alt="icon-eyes"
                    style={{ width: '20px', height: '20px' }}
                  />
                }
                sx={{
                  backgroundColor: 'rgba(56, 57, 59, 1)',
                  color: 'white',
                  '&:hover': {
                    backgroundColor: 'rgba(56, 57, 59, 0.9)',
                  },
                  maxWidth: '120px',
                  maxHeight: '24px',

                  borderRadius: '4px',
                  padding: '2px 8px 2px 4px',
                  gap: '8px',
                }}
              >
                <Typography
                  sx={{
                    width: '61px',
                    height: '16px',
                    fontFamily: 'Montserrat',
                    fontWeight: 700,
                    fontSize: '12px',
                    lineHeight: '16px',
                    textAlign: 'center',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  Visualizar
                </Typography>
              </Button>
            </Stack>
          </Box>
        </AccordionDetailsDefault>
      </AccordionDefault>
    </>
  )
}

export default ProductCard
