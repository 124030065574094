import { TProductPriceCustomFieldSettings } from '../types/Product'

export const productPriceCustomFields: TProductPriceCustomFieldSettings = {
  isCustomFields: false,
  fields: [
    {
      identity: 'maritalStatus',
      name: 'Estado civil',
      isChecked: false,
    },
    {
      identity: 'nationality',
      name: 'Nacionalidade',
      isChecked: false,
    },
    {
      identity: 'profession',
      name: 'Profissão',
      isChecked: false,
    },
    {
      identity: 'nationalIdentity',
      name: 'RG',
      isChecked: false,
    },
  ],
}
