import { useEffect, useState } from 'react'
import { IErrorResponse } from '../types/ErrorResponse'
import {
  IProductInstallmentsProps,
  TProductInstallment,
} from '../types/Product'
import ProductController from '../controllers/ProductController'

export const useProductInstallments = ({
  maxInstallment,
  value,
  maxInstallmentForProducer,
}: IProductInstallmentsProps) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [data, setData] = useState<TProductInstallment[]>([])
  const [error, setError] = useState<string>('')

  const execute = async () => {
    if (maxInstallment <= 0 || value <= 0) return

    if (!loading) {
      try {
        setLoading(true)

        if (error !== '') {
          setError('')
        }
        if (data !== null) {
          setData([])
        }

        const response = await ProductController.calculeIsntallments({
          maxInstallment,
          value,
          maxInstallmentForProducer,
        })
        const responseError = response as IErrorResponse
        const responseData = response as TProductInstallment[]

        if (responseError.code) {
          setError(responseError.error)
        } else {
          setData(responseData)
        }
      } catch (e) {
        var message = 'Erro não identificado'
        if (typeof e === 'string') {
          message = e.toUpperCase()
        } else if (e instanceof Error) {
          const err = e as Error
          message = err.message
        }
        setError(message)
      } finally {
        setLoading(false)
      }
    }
  }

  useEffect(() => {
    execute()
  }, [maxInstallment, value, maxInstallmentForProducer])

  const clear = () => {
    setError('')
  }

  return {
    loading,
    data,
    error,
    execute,
    clear,
  }
}
