import {
  FormControl,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  Stack,
  useMediaQuery,
} from '@mui/material'
import NumberFunctions from '../../core/functions/NumberFunctions'

export interface ITablePaginationDefaultProp {
  count: number
  rowsPerPage: number
  page: number
  hasNewCustomHeader?: boolean
  onPageChange: (page: number) => void
  onRowsPerPageChange: (rows: number) => void
}

const TablePaginationDefault = ({
  count,
  rowsPerPage,
  page,
  hasNewCustomHeader,
  onPageChange,
  onRowsPerPageChange,
}: ITablePaginationDefaultProp) => {
  const isSmallScreen = useMediaQuery((theme: any) =>
    theme.breakpoints.down('md')
  )

  return (
    <Stack
      direction="row"
      spacing={1}
      alignItems="center"
      justifyContent={isSmallScreen ? 'center' : 'end'}
      sx={{ py: 2 }}
    >
      {!hasNewCustomHeader && (
        <FormControl size="small" sx={{ width: '170px' }}>
          <InputLabel id="pagination-label">Registros por página</InputLabel>
          <Select
            labelId="pagination-label"
            label="Registros por página"
            value={rowsPerPage}
            onChange={(event) =>
              onRowsPerPageChange(NumberFunctions.toNumber(event.target.value))
            }
          >
            {[10, 20, 50, 100].map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      <Pagination
        count={
          Math.trunc(count / rowsPerPage) + (count % rowsPerPage > 0 ? 1 : 0)
        }
        variant="outlined"
        color="primary"
        shape="rounded"
        page={page + 1}
        onChange={(event, page) => onPageChange(page - 1)}
      />
    </Stack>
  )
}

export default TablePaginationDefault
