import React from 'react'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import { NumericFormat } from 'react-number-format'
import { FilledInputProps, InputProps, OutlinedInputProps } from '@mui/material'

interface PriceInputProps {
  label: string
  value: number | undefined
  error?: boolean
  InputProps?:
    | Partial<OutlinedInputProps>
    | Partial<InputProps>
    | Partial<FilledInputProps>
    | undefined
  onChange: (value: number) => void
}

const PriceInput: React.FC<PriceInputProps> = ({
  label,
  value,
  error,
  InputProps,
  onChange,
}) => {
  return (
    <NumericFormat
      value={value === 0 ? '' : value ?? ''}
      thousandSeparator="."
      decimalSeparator=","
      decimalScale={2}
      fixedDecimalScale
      prefix="R$ "
      customInput={TextField}
      fullWidth
      InputProps={InputProps}
      onValueChange={(values) => {
        onChange(parseFloat(values.value || '0'))
      }}
      size="small"
      label={label}
      error={error}
    />
  )
}

export default PriceInput
