import { HtmlTooltip } from '../Tooltip/HtmlTooltip'

interface InfoTooltipProps {
  title: string | React.ReactNode
}

const InfoTooltip = ({ title }: InfoTooltipProps) => {
  return (
    <HtmlTooltip title={title}>
      <img src="/assets/icons/infos/info.svg" alt="info" />
    </HtmlTooltip>
  )
}

export default InfoTooltip
